import request from "@/utils/request";
import {
  activationType,
  cancelOrderType,
  createOrderType,
  D004_productID,
  deviceIDType,
  deviceNameType,
  openTrialType
} from "/#/api";


// 判断设备是否支持移动侦测云存储
export function isSupport(data: deviceIDType) {
  return request({
    url: '/D004/isSupport',
    method: 'post',
    data
  })
}

// 获取产品ID
export function productID(data: D004_productID) {
  return request({
    url: '/D004/productID',
    method: 'post',
    data
  })
}

// 获取产品ID
export function region(data: D004_productID) {
  return request({
    url: '/D004/region',
    method: 'post',
    data
  })
}


// 获取设备试用资格
export function tryoutStatus(data: deviceIDType) {
  return request({
    url: '/D004/tryoutStatus',
    // url: 'https://api.eye4.cn:444/timeout',
    method: 'post',
    data
  })
}


// 获取设备开通信息 基本信息
export function status(data: deviceIDType) {
  return request({
    url: '/D004/status',
    method: 'post',
    data
  })
}

// 生成订单接口
export function createOrder(data: createOrderType) {
  return request({
    url: '/createOrder',
    method: 'post',
    data
  })
}

// 取消订单接口
export function cancelOrder(data: cancelOrderType) {
  return request({
    url: '/cancelOrder',
    method: 'post',
    data
  })
}

// 获取设备开通信息 基本信息
export function lowPowerStatus(data: deviceIDType) {
  return request({
    url: '/D015/status',
    method: 'post',
    data
  })
}

// 获取设备名称
export function getDeviceName(data: deviceNameType) {
  return request({
    url: '/device/name',
    method: 'post',
    data
  })
}

// 修改激活状态
export function activation(data: activationType) {
  return request({
    url: '/D004/pause',
    method: 'post',
    data
  })
}

// 修改激活状态
export function openTrial(data: openTrialType) {
  return request({
    url: '/D004/tryout/open',
    method: 'post',
    data
  })
}