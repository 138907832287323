/*
*   国内固定套餐（常电设备）
*/

export const normalElectricityFP = [
    {
        "currencySymbol": "￥",
        "id": "5",
        "localizedTitle": "包月",
        "localizedDescription": "循环存储7天",
        "price": 16.9,
        "oldPrice": 24,
        "marker": "7day_1-months",
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "21",
        "localizedTitle": "包半年",
        "localizedDescription": "循环存储7天",
        "price": 93.9,
        "oldPrice": 140,
        "marker": "7day_6-months",
        "isRecommend": true,
        "showAverageDailyPrice": 0.5,
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "6",
        "localizedTitle": "包年",
        "localizedDescription": "循环存储7天",
        "price": 169.9,
        "oldPrice": 280,
        "marker": "7day_12-months",
        "isRecommend": true,
        "showAverageDailyPrice": 0.4,
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "16",
        "localizedTitle": "包月",
        "localizedDescription": "循环存储30天",
        "price": 39.9,
        "oldPrice": 50,
        "marker": "30day_1-months",
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "19",
        "localizedTitle": "包半年",
        "localizedDescription": "循环存储30天",
        "price": 219,
        "oldPrice": 300,
        "marker": "30day_6-months",
        "isRecommend": true,
        "showAverageDailyPrice": 1.20,
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "17",
        "localizedTitle": "包年",
        "localizedDescription": "循环存储30天",
        "price": 399,
        "oldPrice": 600,
        "marker": "30day_12-months",
        "isRecommend": true,
        "showAverageDailyPrice": 1.07,
        "preferentialLabel": true
    },
]

/*
*   国内固定套餐（低功耗设备）
*/

export const lowPowerFP = [
    {
        "currencySymbol": "￥",
        "id": "512",
        "localizedTitle": "包月",
        "localizedDescription": "循环存储30天",
        "price": 39.90,
        "oldPrice": 50,
        "marker": "30-1",
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "522",
        "localizedTitle": "包半年",
        "localizedDescription": "循环存储30天",
        "price": 166.90,
        "oldPrice": 300,
        "marker": "30-06-20221205",
        "isRecommend": true,
        "showAverageDailyPrice": 0.92,
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "521",
        "localizedTitle": "包年",
        "localizedDescription": "循环存储30天",
        "price": 310.90,
        "oldPrice": 600,
        "marker": "30-12-20221205",
        "isRecommend": true,
        "showAverageDailyPrice": 0.89,
        "preferentialLabel": true
    },
    {
        "currencySymbol": "￥",
        "id": "515",
        "localizedTitle": "包月",
        "localizedDescription": "循环存储15天",
        "price": 20.90,
        "marker": "15-1",
    },
    {
        "currencySymbol": "￥",
        "id": "516",
        "localizedTitle": "包半年",
        "localizedDescription": "循环存储15天",
        "price": 87.90,
        "oldPrice": 150,
        "isRecommend": true,
        "marker": "15-6",
        "showAverageDailyPrice": 0.61,
    },
    {
        "currencySymbol": "￥",
        "id": "517",
        "localizedTitle": "包年",
        "localizedDescription": "循环存储15天",
        "price": 162.90,
        "oldPrice": 300,
        "isRecommend": true,
        "marker": "15-12",
        "showAverageDailyPrice": 0.55,
    },
    {
        "currencySymbol": "￥",
        "id": "518",
        "localizedTitle": "包月",
        "localizedDescription": "循环存储3天",
        "price": 10.90,
        "marker": "3-1",
    },
    {
        "currencySymbol": "￥",
        "id": "519",
        "localizedTitle": "包半年",
        "localizedDescription": "循环存储3天",
        "price": 45.90,
        "oldPrice": 90,
        "isRecommend": true,
        "marker": "3-6",
        "showAverageDailyPrice": 0.27,
    },
    {
        "currencySymbol": "￥",
        "id": "520",
        "localizedTitle": "包年",
        "localizedDescription": "循环存储3天",
        "price": 84.90,
        "oldPrice": 180,
        "isRecommend": true,
        "marker": "3-12",
        "showAverageDailyPrice": 0.24,
    },
]

/*
*   低功耗套餐类别-id映射表关系
*   备注：由于低功耗需要展示多个不同类别的套餐（三天、半个月、一个月）所以需要进行分类
*/

export const comboType_map_Id = {
    "30": [
        "513",
        "512",
        "514",
        "522",
        "521",
    ],
    "15": [
        "515",
        "516",
        "517"
    ],
    "3": [
        "518",
        "519",
        "520",
    ]
}
