export default {
    '确认关闭':'종료 확인',
    '取消': '취소',
    "请稍等": "잠시만요",
    "试用中": "시용 중",
    "动态云录像": "동적 클라우드 녹화",
    "全天云录像": "24시간 클라우드 녹화",
    "连续包月": "월간 연속 구독",
    "首推": "첫 옵션",
    "新用户推荐": "신규 사용자를 위한 추천",
    "循环存储7天": "7일 동안 보관 주기",
    "循环存储30天": "30일 동안 보관 주기",
    "循环存储15天": "15일 동안 보관 주기",
    "循环存储3天": "3일 동안 보관 주기",
    "超80%人选择": "80% 이상 사람들의 선택",
    "连续半年": "반기 연속 구독",
    "连续包年": "연간 연속 구독",
    "包年": "연간 구독",
    "包半年": "반기 구독",
    "包月": "월간 구독",
    "新用户首选": "신규 사용자를 위한 첫 옵션",
    "尝鲜": "얼리 어답터",
    "家用省电套餐": "가정용 절전 세트",
    "超长周期套餐": "초장 주기 세트",
    "看店必备套餐": "가계 모니터링 필수 세트",
    "到期后自动续费，可随时取消": "만료 후 자동 갱신, 수시로 취소 가능",
    "自动续费协议": "자동 갱신 협의",
    "消息/视频无上限": "무제한 메시지/동영상",
    "云存储购买协议": "클라우드 스토리지 구매 협의",
    "动态云录像.png": "DTYLX_kr.png",
    "imageHeight": "320px",
    "已优惠": "할인",
    "AI智能服务": "AI 스마트 서비스",
    "未开通": "비활성화",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "'AI 스마트 서비스'를 구매한 후, 초장기 클라우드 스토리지, 모니터링 구역 사용자 정의, 알람 소리 개성화를 모두 즐길 수 있습니다! 또한 후속 출시 후 독점 제공되는 AI 스마트 인식 기능(패키지, 애완 동물, 차량 인식)도 있습니다.\n",
    "30天超长云存储": "30일 초장기 클라우드 스토리지",
    "报警和推送声个性化": "알람 및 푸시 사운드 개성화",
    "报警区域自定义": "알람 구역 사용자 정의",
    "人形追踪": "휴머노이드 추적",
    "摄像头会实时跟踪人的行动轨迹": "카메라는 사람들의 움직임을 실시간으로 추적합니다",
    "人形框定": "휴머노이드 프레임",
    "在实时视频里面会自动框出并跟踪所出现的人": "실시간 동영상에서 등장인물은 자동으로 프레임이 설정되고 추적됩니다",
    "AI智能服务功能介绍": "AI 스마트 서비스 기능 소개",
    "免费服务 VS Al智能服务": "무료 서비스 VS AI 스마트 서비스",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "최대 30일의 초장기 스토리지 확장(6 패키지 옵션)으로 금융급의 암호화된 클라우드 스토리지 서비스를 만듭니다. 멋진 순간을 절대 놓치지 않도록 손끝에서 시간을 되돌립니다.",
    "循环存储": "주기적  스토리지",
    "无": "없음",
    "30天": "30 일",
    "15天": "15 일",
    "3天": "3 일",
    "报警推送": "알람 푸시",
    "100条": 100,
    "无上限！": "무제한!",
    "视频时长": "동영상 지속 시간",
    "最长60秒": "최대 60초",
    "报警声个性化": "알람 소리 개성화",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "카메라 알람/방송 사운드, 휴대폰 푸시 벨소리를 사용자 정의할 수 있습니다. 풍부한 현지 음성이 준비되어 있으며 음성 및 텍스트를 사용하여 원하는 음성을 녹음할 수도 있습니다.",
    "监测区域自定义": "모니터링 구역 사용자 정의",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "불필요한 알람을 줄이기 위해 관심 있는 구역만 캡처하도록 하나 이상의 모니터링 구역을 설정할 수 있습니다. 알람이 트리거되면 알람이 어디에 있는지 정확히 알 수 있습니다.",
    "AI智能通知(待开放)": "AI 스마트 알림(오픈 예정)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "AI 인공 지능을 사용하여 고급 알고리즘 기술을 식별하여 화면의 주요 내용인 애완 동물, 차량, 패키지를 식별할 수 있습니다. 그리고 행동을 분석하여 보다 정확하고 지능적인 정보를 푸시합니다.",
    "已了解，去购买": "알겠습니다, 구매하러 가세요",
    "已开通": "이미 개통됐습니다",
    "10秒/每条": "10초/각",
    "3天\n(100条/天，10秒/条) ": "3일(100개 레코드/일, 10초/바)",
    "7/30天\n (消息/视频无上限)": "7/30일 (메시지/동영상 무제한)",
    "30天\n (消息/视频无上限)": "30일 (메시지/동영상 무제한)",
    "距离服务到期": "서비스 종료 시까지",
    "永久": "영구적인",
    "体验": "체험",
    "监测区域": "모니터링 구역",
    "报警推送100条/天": "알람 푸시 100/일",
    "视频时长10秒": "동영상 길장 10초",
    "7天云存储": "7일 클라우드 스토리지",
    "30天云存储": "30일 클라우드 스토리지",
    "1个月": "1 개월",
    "6个月": "6 개월",
    "12个月": "12 개월",
    "循环存储7天（使用时间6个月）": "7일 사이클 스토리지(6개월 사용 기간 )",
    "查看详情": "세부 정보 보기",
    "安全": "안전",
    "便捷": "편리",
    "贴心": "배려심",
    "云存储特权": "클라우드 스토리지 권한",
    "云端保护": "클라우드 보호",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "동영상은 클라우드에 저장되며 깨지거나 도용될 수 없으며 데이터 보안을 보호합니다.",
    "隐私加密": "개인 정보 암호화",
    "金融级数据加密方案，保护隐私更\n安全": "개인 정보를 더 안전하게 보호하는 금융급 데이터 암호화 방식",
    "存储灵活": "유연한 스토리지",
    "同时保存报警录像和视频，不错过任\n    何动态": "알람 녹화와 동영상을 동시에 저장하고 어떤 움직임도 놓치지 않습니다\n",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "세금 및 환율 문제로 인해 사용자의 계정이 로그인한 국가에 따라 유료 가격이 약간 변동될 수 있습니다.",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "스마트 서비스의 기능은 카메라 관리자 권한에서만 적용되며, 공유 사용자는 장치의 관리자 권한을 따라 사용하게 됩니다.",
    "付款：确认购买并付款后记入iTunes 账户": "결제: 구매 및 결제 확인 후 iTunes 계정에 적립",
    " 去支付": "결제",
    "7天    ": "7 일",
    "60天": "60일",
    "有效日期至": "유효한 날짜",
    "可升级": '업그레이드 가능',
    "已到期": '만료됨',
    "云视频录像": "클라우드 비디오 녹화 스위치",
    "关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长": "종료 후에는 동영상이 클라우드에 저장되지 않으며 현재 클라우드 스토리지 서비스가 연장되지 않습니다.",
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "실시간 동영상 화면이 동적으로 변경되면 장치가 자동으로 녹화하여 클라우드 스토리지에 업로드합니다. 오디오 및 동영상 데이터는 금융급 암호화 기술을 거쳐 데이터 보안을 보장합니다.",
    "去支付": "지불하다",
    "天": "일",
    "首选": "우선 순위",
    "超过80%人选择": "80% 이상 선택",
    "存储周期": "스토리지 주기",
    "订单": "주문",
    "去续费": "연장료",
    "云存储录像邀您试用": "클라우드 스토리지 비디오를 사용해 보십시오.",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "당신은 한 달 동안의 무료 시험 사용 시간을 가질 것입니다. 비디오 저장 구름은 잃어버리는 것을 두려워하지 않습니다. 비디오는 7일 동안 보존하고 재생이 간단하고 편리합니다.",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "일평균",
    "元": "RMB",
    "省": "Save",
    "推荐": "추천",
    "请等待": "기다려 주세요",
    "激活": "활성화",
    "支持": "지원",
    "不支持": "지원되지 않음",
    "年卡": "연간 구독",
    "半年卡": "반기 구독",
    "月卡": "월간 구독",
    "暂不支持，敬请期待": "아직 지원되지 않으니 계속 지켜봐 주세요.",
    "卡片暂不支持充值": "현재 카드는 재충전할 수 없습니다.",
    "HI~有什么需要查询的吗？": "안녕하세요~ 도움이 필요하신가요?",
    "更多热门问题为你解答": "자주 묻는 질문",
    "热门分类": "인기 카테고리",
    "添加设备": "장치 추가",
    "监控异常": "감시 이상 징후 발견",
    "操作视频": "작동 영상",
    "更多其他": "기타 더보기",
    "常见问题": "자주 발생하는 문제점",
    "在线客服": "온라인 고객 서비스",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}