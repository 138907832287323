/**
 * 工具类
 */

//获取HOST
export function getHost() {
    return window.location.host
}

//获取URL
export function getURL() {
    const protocol = window.location.protocol
    const host = window.location.host
    const port = window.location.port
    let URL
    if (protocol === 'https:') {
        URL = protocol + '//' + host
    } else {
        if (host.indexOf(port) !== -1) {
            URL = protocol + '//' + host
        } else {
            URL = protocol + '//' + host + ':' + port
        }
    }
    return URL
}

//获取RGB三色系
export function getRgb(color) {
    let arr = [];
    let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    if (color && reg.test(color)) {
        //处理六位的颜色值
        for (let i = 1; i < 7; i += 2) {
            arr.push(parseInt("0x" + color.slice(i, i + 2)));
        }
    }
    return arr;
}

//获取皮肤颜色
export function getSkin(rgb) {
    let color;
    try {
        color = '';
        if (rgb) {
            let arr = JSON.parse(rgb);
            for (let index in arr) {
                let number = arr[index];
                if (number < 16) {
                    color = color + '0' + (number).toString(16);
                } else {
                    color = color + (number).toString(16);
                }

            }
        } else {
            color = 'FEBF0E';
        }
    } catch (e) {
        color = 'FEBF0E';
    }
    return color.toUpperCase();
}

//获取应用ID
export function getApplyId(oemId) {
    let appId = "";
    switch (oemId) {
        case "AKCXKJ":
            appId = "11fdff62-7707-4e95-af34-90746d0eb622";
            break;
        case "VSTARCAM":
            appId = "80e4c7fc-3ef9-4e98-ba5e-e9dfd706ae18";
            break;
        case "OEM":
            appId = "0efaeae6-6772-4f5f-900e-492202df4d7b";
            break;
        case "SZYZX":
            appId = "d7cb5a74-814d-49af-9f3b-a2bd44344617";
            break;
        case "FLFDZ":
            appId = "9d8598e5-8abc-46fa-bdb7-0b189820363f";
            break;
        case "VEEPAI":
            appId = "0775a326-1b96-4afe-bc5f-dfaab33f2b34";
            break;
    }
    return appId;
}

//设置URL参数
export function setUrlParam(route, params) {
    let keys = Object.keys(params);
    let URL = getURL();
    URL = URL + route;
    for (let i = 0; i < keys.length; i++) {
        let symbol = '&';
        let name = keys[i];
        let value = params[name];
        if (i === 0) symbol = '?';
        URL = URL + symbol + name + '=' + value;
    }
    return URL;
}

//获取URL参数
export function getUrlParam(name) {
    let reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search;
    r = r.substr(1).match(reg);
    if (r !== null) return decodeURIComponent(r[2]);
    return null;
}

//获取URL所有参数
export function getUrlAllParam() {
    let search = decodeURIComponent(window.location.search);
    search = search.substr(1);
    let params = {};
    try {
        let arr = search.split('&');
        for (let i = 0; i < arr.length; i++) {
            let q = arr[i].split('=');
            if (q.length === 2) {
                let key = q[0];
                params[key] = q[1];
            }
        }
    } catch (e) {
        console.log('获取url参数异常', e);
    }

    return params;
}

//设置本地参数
export function setLocalParam(key, json) {
    let str = '{}';
    try {
        str = JSON.stringify(json);
    } catch (e) {
        console.warn('设置本地参数异常，数据不是json');
    }
    localStorage.setItem(key, str);
}

//查询本地参数
export function getLocalParam(key) {
    let json
    let str = localStorage.getItem(key)
    if (!str) str = '{}'
    try {
        json = JSON.parse(str)
    } catch (e) {
        console.warn('查询本地参数异常，数据不是json')
        json = {}
    }
    return json ? json : {}
}

//删除本地参数
export function removeLocalParam(key) {
    localStorage.removeItem(key);
}

//清理本地所有参数
export function clearLocalAll() {
    localStorage.clear();
}

//获取套餐单位
export function getComboUnit(mode) {
    let mark;
    switch (mode) {
        case 'MONTH':
            mark = '月';
            break;
        case 'QUARTER':
            mark = '季';
            break;
        default:
            mark = '年';
    }
    return mark;
}

//产品分类
export function productCategory(list) {
    let product = []
    let all = {}
    let combo = {}
    let len = list.length
    if (len === 0) return product
    for (let i = 0; i < len; i++) {
        let item = list[i]
        let group = item['group']
        let price = item['price']
        let mark = group + '_' + String(price)
        let arr = all[group]
        if (!arr) arr = []
        arr.push(price)
        if (arr.length >= 3) {
            arr = arr.sort((a, b) => {
                return a - b
            })
        }
        all[group] = arr
        combo[mark] = item
    }
    console.warn("==================")
    console.warn(all,combo)
    product = productSort(all, combo)
    return product
}

//产品排序
export function productSort(all, combo) {
    let product = {}
    let groups = Object.keys(all)
    for (let i = 0; i < groups.length; i++) {
        let group = groups[i]
        let item = all[group]
        let newArr = []
        for (let j = 0; j < item.length; j++) {
            let price = item[j]
            let key = group + '_' + String(price)
            newArr.push(combo[key])
        }
        product[group] = newArr
    }
    return product
}

//页面必要参数
export function defaultParams() {
    return {
        //H5支付？0 禁用；1 启用；其他值 禁用
        H5pay: '0',
        //流量告警等级 0 1G；1 500M；其他值 默认1G
        alarmLevel: '0',
        //iccId
        iccId: '',
        //系统语言，默认zh
        language: 'zh-JT',
        //经销商ID，默认OEM
        oemId: 'OEM',
        //openid 用户微信公众号授权token
        openid: 'null',
        //卡类型；移动 CMCC；电信 CTCC；联通 CUCC
        operator: '',
        //卡商平台
        platformId: '',
        //rgb颜色值，默认'[254,191,14]'
        rgb: '[254,191,14]',
        //支付场景 APP、H5；默认APP
        scenes: 'APP',
        //sim号；非iccId
        sim: '',
        //网站肤色,默认'#FEBF0E'
        skin: 'FEBF0E',
        //用户ID，默认system
        userId: 'system',
    }
}

//映射缓存参数
export function mapCacheParameters(required, callback) {
    //读取缓存业务参数
    const cache = getLocalParam('business')
    if (cache) {
        const keys = Object.keys(cache)
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            required[key] = cache[key]
        }
    }
    if (typeof callback === 'function') callback()
}

//映射URL参数
export function mapURLParameters(required, opt, callback) {
    if (opt) {
        const keys = Object.keys(opt)
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            required[key] = opt[key]
        }
    }
    if (typeof callback === 'function') callback()
}

//映射渲染参数
export function mapRenderParameters(rendered, opt, callback) {
    if (opt) {
        const keys = Object.keys(opt)
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            rendered[key] = opt[key]
        }
    }
    if (typeof callback === 'function') callback()
}

//获取包看套餐总时长
export function unlimitedComboTotal(group, id) {
    const product = getLocalParam('product')
    const list = product[group]
    let total = '-'
    console.warn("list",list)
    if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
            const item = list[i]
            const pid = item['id']
            const mode = item['mode']
            const unit = item['unit']
            if (String(pid) === String(id)) {
                if (mode === 'MONTH') {
                    total = Number(unit) * 30
                } else if (mode === 'YEAR') {
                    total = Number(unit) * 360
                }
                return String(total)
            }
        }
    }
    return total
}

// 低功耗 对ios拿到的套餐列表进行排序：月卡--半年卡--年卡
export function sortByMode(list) {
    let month = []
    let halfYear = []
    let year = []
    for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let mode = item['localizedDescription']
        if (mode === '循环存储3天') {
            if(item.productID === 'D015_1_3_20221020'){
                item.localizedTitle = '包月'
            }else if(item.productID === 'D015_6_3_20221020'){
                item.localizedTitle = '包半年'
                item.oldPrice = 90
                item.showAverageDailyPrice = 0.25
                item.isRecommend = true
            }else if(item.productID === 'D015_12_3_20221020'){
                item.localizedTitle = '包年'
                item.oldPrice = 180
                item.showAverageDailyPrice = 0.24
                item.isRecommend = true
            }
            month.push(item)
        } else if (mode === '循环存储15天') {
            if(item.productID === 'D015_1_15_20221020'){
                item.localizedTitle = '包月'
            }else if(item.productID === 'D015_6_15_20221020'){
                item.localizedTitle = '包半年'
                item.oldPrice = 150
                item.showAverageDailyPrice = 0.54
                item.isRecommend = true
            }else if(item.productID === 'D015_12_15_20221020'){
                item.localizedTitle = '包年'
                item.oldPrice = 300
                item.showAverageDailyPrice = 0.46
                item.isRecommend = true
            }
            halfYear.push(item)
        } else if (mode === '循环存储30天') {
            if(item.productID === 'D015_1_20220107'){
                item.localizedTitle = '包月'
                item.oldPrice = 50
                item.preferentialLabel = true
            }else if(item.productID === 'D015_6_20220107'){
                item.localizedTitle = '包半年'
                item.oldPrice = 300
                item.showAverageDailyPrice = 0.92
                item.preferentialLabel = true
                item.isRecommend = true
            }else if(item.productID === 'D015_12_20220107'){
                item.localizedTitle = '包年'
                item.oldPrice = 600
                item.showAverageDailyPrice = 0.89
                item.preferentialLabel = true
                item.isRecommend = true
            }
            year.push(item)
        }
    }
    month.sort((a,b)=>{return a.price - b.price})
    halfYear.sort((a,b)=>{return a.price - b.price})
    year.sort((a,b)=>{return a.price - b.price})
    const result = month.concat(halfYear).concat(year)
    if(result.length === 0) return list
    return result
}

// ios长电排序
export function sortAsNormal(list){
    let week = []
    let month = []
    for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let mode = item['localizedDescription']
        if (mode === '循环存储7天') {
            if(item.productID === '7_qiniu_hd_1_20220107'){
                item.localizedTitle = '包月'
                item.oldPrice = 24
                item.preferentialLabel = true
            }else if(item.productID === '7_qiniu_hd_6_20220107'){
                item.localizedTitle = '包半年'
                item.oldPrice = 140
                item.preferentialLabel = true
                item.isRecommend = true
                item.showAverageDailyPrice = 0.51
            }else if(item.productID === '7_qiniu_hd_12_20220107'){
                item.localizedTitle = '包年'
                item.oldPrice = 280
                item.preferentialLabel = true
                item.isRecommend = true
                item.showAverageDailyPrice = 0.45
            }
            week.push(item)
        } else if (mode === '循环存储30天') {
            if(item.productID === '30_qiniu_hd_1_20220107'){
                item.localizedTitle = '包月'
                item.oldPrice = 50
                item.preferentialLabel = true
            }else if(item.productID === '30_qiniu_hd_6_20220107'){
                item.localizedTitle = '包半年'
                item.oldPrice = 300
                item.preferentialLabel = true
                item.isRecommend = true
                item.showAverageDailyPrice = 1.19
            }else if(item.productID === '30_qiniu_hd_12_20220107'){
                item.localizedTitle = '包年'
                item.oldPrice = 600
                item.preferentialLabel = true
                item.isRecommend = true
                item.showAverageDailyPrice = 1.06
            }
            month.push(item)
        }
    }
    week.sort((a,b)=>{return a.price - b.price})
    month.sort((a,b)=>{return a.price - b.price})
    const result = week.concat(month)
    if(result.length === 0) return list
    return result
}

export default {
    getURL,
    getRgb,
    getSkin,
    getApplyId,
    setUrlParam,
    getUrlParam,
    getUrlAllParam,
    getComboUnit,
    setLocalParam,
    getLocalParam,
    removeLocalParam,
    clearLocalAll,
    productCategory,
    productSort,
    defaultParams,
    mapCacheParameters,
    mapURLParameters,
    mapRenderParameters,
    unlimitedComboTotal,
    sortByMode,
    sortAsNormal
}

