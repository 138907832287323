import { defineStore } from 'pinia'
import {orderListModuleType} from '/#/store'
import router from '@/router'

export const useOrderListStore = defineStore('orderListStore', {
    state: (): orderListModuleType => ({
        list: [
            {
                title: "24小时全天云录像",
                subtitle: "循环存储7天",
                status: "未支付",
                deviceId: "VPxxxxxxxxxxxx",
                orderId: "13213213246512621",
                payment: 99,
                createTime: "2019-04-11 12:45:30"
            }
        ],
        selectedOrderId: '',
    }),
    getters: {
    },
    actions: {
        initData() {
            const route = router.currentRoute.value.query
            console.log("xx",route?.pageType) // 云存储 4G流量
            // TODO 跳转订单详情时需要带上参数 再更具query判断获取哪个接口数据,
        },
        setSelectedOrderId(orderId: string){
            this.selectedOrderId = orderId
        }
    },
    persist:{
        enabled:true,
        strategies:[
            {
                storage:localStorage,paths:['selectedOrderId']
            }
        ]
    }
})