<template>
  <div class="home">
    <div>
      <van-form @submit="doNow">
        <van-cell-group inset>
          <van-field
              v-model="iccId"
              label="ICCID/VUID:"
              style="background: #F8F6F9;margin-top: 20px;"
              :rules="[
                  { required: true, message: '请输入ICCID/VUID'},
                  { pattern: /^[0-9a-zA-Z]+$/, message: '输入含特殊字符，请检查您的输入'},
                  { validator:checkLen, message: '请检查输入ICCID/VUID长度，长度必须在10-20个字符区间'},
              ]"
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit"
                      :style="{'color':'white','background-color':'#'+required.skin,'border-color':'#'+required.skin}">
            确定
          </van-button>
        </div>
      </van-form>
    </div>
    <img src="../assets/tutorial_JT.png" style="width: 100%" alt="读取图片失败">
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router"
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {reactive, onMounted, toRefs} from "vue";
import {cardUrl, searchDeviceCard, showCard} from "@/api"
import {Toast} from 'vant'
import {getSkin} from "@/utils/tool";

export default {
  name: "universal",
  setup() {
    //路由对象
    const router = useRouter()

    const route = useRoute()

    const fourGFlowStore = useFourGFlowStore()

    //必要参数
    let {defaultParams: required} = fourGFlowStore

    //渲染参数
    let {renderedParams: rendered} = fourGFlowStore

    //渲染参数
    let state = reactive({
      //访问页面方式,0 点击按钮 1 自动显示；默认是点击按钮显示
      isAuto: rendered.auto === '1',
      iccId: ''
    })

    //组件已安装
    onMounted(async () => {

      console.log('Universal-onMounted')
      //初始化参数
      init(() => {
        const scenes = required.scenes
        //检测环境
        if (scenes === 'APP') {
          console.warn('APP环境')
          //加载APP事件
          reloadEvent()
        } else {
          console.warn('H5环境')
        }
      })
    })

    //初始化参数
    const init = (callback) => {
      //查询URL参数
      const opt = router.currentRoute.value.query
      console.warn('页面URL传参', opt)
      //映射缓存参数
      required.mapCacheParameters()
      //映射URL参数
      if (opt) {
        required.mapURLParameters(opt)
        console.warn('页面参数映射完成,网站当前肤色', required.skin)
        //映射渲染参数
        required.mapRenderParameters(opt)
        console.warn('渲染参数映射完成')
        let {rgb} = route.query
        if (rgb) {
          rgb = decodeURIComponent(rgb)
          required.skin = getSkin(rgb)
        }
        //清空iccId
        required.iccId = ''
      }
      if (typeof callback === 'function') callback()
    }

    //APP事件
    const reloadEvent = () => {
      const skin = required.skin
      fourGFlowStore.webViewBridge.setNavColor(skin)
      //自动跳转情况时，读取APP的接口获取设备ID
      fourGFlowStore.webViewBridge.getDeviceId((did) => {
      })
    }

    //业务逻辑
    const doNow = async () => {
      let iccId = state.iccId
      const reg = /[A-Z][0-9]{6,}[A-Z]{4,}/
      if (reg.test(iccId)) {
        //判定长度为设备ID，通过设备ID查询卡号
        console.warn('输入字符为设备ID', reg.test(iccId))
        const data = await searchDeviceCard(iccId)
        data["iccId"] ? await showCardInfo(data["iccId"]) : Toast('设备未绑定卡号')
      } else await showCardInfo(iccId) //执行卡号查询流程
    }

    //查询卡号信息
    const showCardInfo = async (iccId) => {
      const data = await cardUrl(iccId, "12218045")
      window.location.replace(data)
      // let data = await showCard(iccId)
      // console.warn(data, "==============")
      // // 为了兼容卡号是移动或者电信（有存在21位的卡号也有存在19位的卡号，所以app解析出来可能会在19位上多几位）
      // if (!data && iccId.length !== 19) data = await showCard(iccId.substring(0, 19))
      // if (data) {
      //   const {iccid} = data
      //   required.iccId = iccid
      //   rendered.isNormal = true
      //   await renewHome(iccid)
      // }
    }

    //检测输入长度 10-20个字符
    const checkLen = (value) => value.length >= 10 && value.length <= 20;

    //更新首页参数
    const renewHome = (iccId) => router.push({path: '/index', query: {iccId: iccId}})

    return {
      ...toRefs(state),
      //参数
      required,
      rendered,
      //方法
      doNow,
      checkLen,
      //更新首页参数
      renewHome,
    }
  }
}
</script>

<style scoped>
.btnPayment {
  margin-top: 20px;
  margin-left: 10%;
  width: 80%;
  height: 42px;
  line-height: 42px;
  border-radius: 15px;
  background: #ffbe0f;
  color: white;
  text-align: center;
}

/deep/ .van-nav-bar__title {
  color: white;
}

/deep/ .van-nav-bar__text {
  color: white;
}

/deep/ .van-icon-arrow-left::before {
  color: white;
}
</style>