<template>
  <div class="page">
    <div class="msgShowBox">
      <div class="messageShowBox">
        <h2>{{ FAQTransferStore.title.name }}</h2>
        <div>
          <div v-for="item in FAQTransferStore.correspondenceData" class="correspondenceItem">
          <span @click="FAQTransferStore.showMark(item.remark)" style="margin-right: 10px">
            <el-icon v-if="item.remark" style="vertical-align: middle;margin-right: 3px" size="15">
              <ChatDotSquare/>
            </el-icon>
            {{ item.topic }}
          </span>
            <el-tag effect="dark" v-for="option in item.options"
                    @click="FAQTransferStore.showMark(option.remark)">
              {{ option.name }}
            </el-tag>
          </div>
          <el-divider>
            <span style="color:#b4b4b4">{{ isAllowDrop ? '评论记录' : '已加载所有记录' }}</span>
          </el-divider>
        </div>
        <!--      <el-tree :data="tree" :props="defaultProps" default-expand-all/>-->
      </div>
<!--      <van-pull-refresh v-model="loadingMoreDiscussion" @refresh="onRefresh"-->
<!--                        ref="replyRef" class="replyHistoryBox" :disabled="!isAllowDrop">-->
      <div>
        <div class="replyHistoryItem" v-for="item in FAQTransferStore.discussionList">
          <div class="askQuestion">
            <el-alert
                style="width:fit-content;"
                type="info"
                :description="item.comment"
                :closable="false"
            />
            <el-avatar style="margin-left: 10px;min-width: 40px;">我</el-avatar>
          </div>
          <div class="replyQuestion" v-for="replyItem in item.reply">
            <el-avatar :icon="Service" class="serviceAvatar"/>
            <el-alert
                type="success"
                :description="replyItem.comment"
                :closable="false"
            />
          </div>
        </div>
        <el-empty :image-size="180" description="暂无评论" v-if="!FAQTransferStore.discussionList.length"/>
        <el-divider>
          <span style="color:#b4b4b4">已经到底</span>
        </el-divider>
      </div>
<!--      </van-pull-refresh>-->
    </div>
    <div class="replyBox" v-if="FAQTransferStore.questionStatus !== '3'">
      <div>
        <p style="text-indent: 4px">新提问：</p>
        <el-input
            v-model.trim="FAQTransferStore.answerContent"
            placeholder="输入提问内容"
            :rows="5"
            type="textarea"
        />
      </div>
      <div style="display:flex;flex-direction: row;justify-content: space-between">
        <el-button type="warning" @click="FAQTransferStore.onResolved" style="width:35%;" size="large">
          已解决
        </el-button>
        <el-button type="primary" @click="FAQTransferStore.onSubmit" style="width:35%;" size="large">
          提 交
        </el-button>
      </div>
    </div>
    <div v-else style="height: calc(30vh - 40px)">
      <el-result
          icon="success"
          title="已结案"
          sub-title="请翻看查询历史记录"
      />
    </div>
    <el-dialog title="备注" center style="min-height: 40vh;border-radius: 15px;"
               width="80%" v-model="FAQTransferStore.isShowMark">
      <div v-html="FAQTransferStore.markContent"></div>
    </el-dialog>
  </div>
</template>

<script>
import {reactive, toRefs, onMounted, nextTick} from 'vue'
import {useRoute} from "vue-router";
import {problemDiscussion} from "@/api/FAQ";
import {ElLoading} from 'element-plus'
import {ChatDotSquare, Service, User, UserFilled} from '@element-plus/icons-vue'
import {useFAQTransferStore} from "@/store/modules/FAQ";

export default {
  name: "informationTransfer",
  components: {ChatDotSquare, User},
  setup() {
    const FAQTransferStore = useFAQTransferStore()
    const state = reactive({
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      tree: [],
      loadingMoreDiscussion: false,
      isAllowDrop: true,

      replyRef: null
    })
    const route = useRoute()
    const {id} = route.query
    FAQTransferStore.$reset()
    FAQTransferStore.questionId = id

    //635b59b05064bb43c36164ab
    // 树的处理
    const processTree = () => {
      const questionList_key = Object.keys(state.detailsAnswer)
      for (const key of questionList_key) {
        const questionItem = state.questionList.find(question =>
            question._id === key && !question.belongId
        )
        if (questionItem) {
          const children = []
          state.detailsAnswer[key].forEach(item => {
            const {name: label, mark} = questionItem.options.find(option => option.mark === item)
            children.push({label, children: findTreeChildren(mark)})
          })
          state.tree.push({label: questionItem.topic, children})
        }
      }
    }

    const findTreeChildren = (mark) => {
      const question = state.questionList.find(item => item.optionsMark === mark)
      if (!question) return []
      const {_id, options, topic} = question
      const answerOptions = state.detailsAnswer[_id]
      const children = []
      answerOptions.forEach(item => {
        const {name, mark: optionMark} = options.find(option => option.mark === item)
        children.push({label: name, children: findTreeChildren(optionMark)})
      })
      return [{label: topic, children}]
    }

    const changeScrollTop = (oldScrollTop) => {
      nextTick(() => {
        const top = state.replyRef.$el.scrollHeight - oldScrollTop
        state.replyRef.$el.scrollTo({top})
      })
    }

    const isMobile = () => {
      let userAgentInfo = navigator.userAgent;
      let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      let getArr = Agents.filter(i => userAgentInfo.includes(i));
      return !!getArr.length;
    }

    const initEvent = () => {
      nextTick(() => {
        state.replyRef.$el.scrollTo({top: state.replyRef.$el.scrollHeight})
        console.warn("list[0]:", FAQTransferStore.discussionList[0])
        if (state.replyRef.$el.onscroll || isMobile()) return
        state.replyRef.$el.onscroll = async () => {
          const scrollTop = state.replyRef.$el.scrollTop
          if (scrollTop === 0) {
            const {_id: offsetId, feedbackId} = FAQTransferStore.discussionList[0]
            const data = {feedbackId, offsetId}
            const {data: list} = await problemDiscussion(data)
            console.warn("新评论数据：", list)
            if (!list.length) {
              state.isAllowDrop = false
              state.replyRef.$el.onscroll = null
            } else {
              FAQTransferStore.discussionList.unshift(...list.reverse())
              changeScrollTop(state.replyRef.$el.scrollHeight)
            }
          }
        }
      })
    }

    const onRefresh = async () => {
      console.warn("加载啦!!!", state.replyRef, state.replyRef.$el.scrollHeight)
      const {_id: offsetId, feedbackId} = FAQTransferStore.discussionList[0]
      const data = {feedbackId, offsetId}
      const {data: list} = await problemDiscussion(data)
      console.warn("新评论数据：", list)
      if (!list.length) {
        state.isAllowDrop = false
      } else {
        FAQTransferStore.discussionList.unshift(...list.reverse())
        changeScrollTop(state.replyRef.$el.scrollHeight)
      }
      state.loadingMoreDiscussion = false
    }

    onMounted(async () => {
      const loading = ElLoading.service({
        lock: true,
        text: '加载中',
        background: 'rgba(0, 0, 0, 0.9)',
      })
      await FAQTransferStore.fetchTreeDate()
      FAQTransferStore.initSocket()
      // if (FAQTransferStore.discussionList.length) initEvent()
      loading.close()
    })

    return {
      ...toRefs(state),
      FAQTransferStore,
      onRefresh,
      UserFilled,
      Service
    }
  }
}
</script>

<style lang="scss">
.el-alert__title {
  display: flex;
  line-height: normal;
  align-items: center;
}

.el-alert .el-alert__description {
  font-size: 16px;
  text-align: initial;
}

.page {
  padding: 20px;
  //height: calc(100vh - 40px);
  /*background: linear-gradient(to left top, #f6fafd 10%, #f5f9ff);*/
}

.msgShowBox {
  //display: grid;
  //grid-template-rows: 1fr 1fr;
  //grid-gap: 5px;
  //grid-auto-rows: 50px;
  //height: 60vh;
}

.messageShowBox {
  //height: calc(30vh - 40px);
}

.replyHistoryBox {
  //height: calc(40vh);
  overflow-y: scroll;
  padding-right: 10px;
}

.correspondenceItem {
  color: #606266;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px
}

.replyBox {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.replyHistoryItem {
  margin-bottom: 20px
}

.askQuestion {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  text-align: end;
  align-items: center;
}

.replyQuestion {
  margin-bottom: 10px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.serviceAvatar {
  color: #67c23a;
  margin-right: 10px;
  min-width: 40px;
  background: #f0f9eb
}
</style>