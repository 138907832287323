export default {
    '确认关闭':'Xác nhận đóng cửa',
    "请稍等": "Xin chờ một chút.",
    "试用中": " Đang dùng thử",
    "动态云录像": "Trạng thái ghi hình đám mây",
    "全天云录像": "24 giờ ghi hình đám mây",
    "连续包月": "Gói tháng liên tục",
    "首推": "Đề xuất đầu tiên",
    "新用户推荐": "Đề xuất cho người dùng mới",
    "循环存储7天": "Chu kỳ lưu trữ 7 ngày",
    "循环存储30天": "Chu kỳ lưu trữ 30 ngày",
    "循环存储15天": "Chu kỳ lưu trữ 15 ngày",
    "循环存储3天": "Chu kỳ lưu trữ 3 ngày",
    "超80%人选择": "Hơn 80% người chọn",
    "连续半年": "Gói 6 tháng liên tục",
    "连续包年": "Gói năm liên tục",
    "包年": "Gói cả năm",
    "包半年": "Gói 6 tháng",
    "包月": "Gói một tháng",
    "新用户首选": "Lựa chọn đầu tiên cho người dùng mới",
    "尝鲜": "Thử sản phẩm mới",
    "家用省电套餐": "Gói tiết kiệm điện tại nhà",
    "超长周期套餐": "Gói chu kỳ siêu dài",
    "看店必备套餐": "Mua gói thiết yếu",
    "到期后自动续费，可随时取消": "Tự động gia hạn sau khi hết hạn, có thể hủy bất kỳ lúc nào",
    "自动续费协议": "Thỏa thuận tự động gia hạn",
    "消息/视频无上限": "Không giới hạn tin nhắn/ video",
    "云存储购买协议": "Thỏa thuận mua bộ nhớ đám mây",
    "已优惠": "Đã giảm giá",
    "天": "ngày",
    "有效日期至": "Hợp lệ cho",
    "已到期": 'Name',
    "可升级": 'Upgradeable',
    "首选": "là lựa chọn đầu tiên",
    "超过80%人选择": "Hơn 80 Name",
    "存储周期": "Chu kỳ lưu trữ",
    "云存储录像邀您试用": "Mây là nơi cất giữ video.",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "Bạn sẽ có một thời gian thử nghiệm miễn phí trong một tháng. Đoạn video sẽ không bị mất khi được cất giữ trong đám mây. Đoạn video sẽ được giữ trong bảy ngày, và chơi lại rất đơn giản và tiện lợi.",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "trung bình mỗi ngày",
    "元": "RMB",
    "省": "Save",
    "推荐": "Recommend",
    "请等待": "Xin chờ",
    "订单": "Order",
    "连续包月开通说明": "instructions For Continuous Monthly Subscription",
    "到期前一天为您自动续费": "Auto-renew for you the day before expiration",
    "到期前一天，可随时取消自动续费服务": "The automatic renewal service can be cancelled at any time on the day before the expiration date",
    "取消自动续费服务路径": "Cancel the automatic renewal service path",
    "AI智能服务 - 订单 - 续费订单": "AI Smart Service - Order - Renewal Order",
    "取消": "Cancel",
    "确定开通": "CONFIRM TO OPEN",
    "去续费": "TO RENEW",
    "续费管理": "management",
    "设备ID": "Device ID",
    "订单编号": "Order ID",
    "支付金额": "Payment",
    "24小时全天云录像": "24 hours all-day cloud video",
    "未支付": "Unpaid",
    "已支付": "Paid",
    "动态云录像.png": "DTYLX_en.png",
    "imageHeight": "350px",
    "续费管理.png": "XFGL_en.jpg",
    "AI智能服务": "AI Smart Service",
    "未开通": "Nonactivated",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "After purchasing the \"AI Smart Service\", you can enjoy the super long Cloud Storage, monitoring area customization, and personalization of alarm sounds. All of them will be exclusively available! There are also AI smart recognition functions (identifying bags, pets, and vehicles) that will be exclusively available after the subsequent launch. ",
    "30天超长云存储": "30 days long Cloud Storage",
    "报警和推送声个性化": "Alarm and push sound personalization",
    "报警区域自定义": "Alarm area customization",
    "人形追踪": "Humanoid tracking",
    "摄像头会实时跟踪人的行动轨迹": "Cameras track people's movements in real time",
    "人形框定": "Humanoid framing",
    "在实时视频里面会自动框出并跟踪所出现的人": "In the real-time video, the person who appears will be automatically framed and tracked",
    "AI智能服务功能介绍": "AI smart service function introduction",
    "免费服务 VS Al智能服务": "Free Service VS Al Smart Service",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "Extend up to 30 days of ultra-long storage (6 types of packages are optional), creating a financial-grade encrypted Cloud Storage service. Rewind time at your fingertips, so that you never miss any wonderful moments.",
    "循环存储": "Cyclic storage",
    "无": "None",
    "30天": "30 days",
    "15天": "15 days",
    "3天": "3 days",
    "报警推送": "Alarm push",
    "100条": 100,
    "无上限！": "Unlimited!",
    "视频时长": "Video duration",
    "最长60秒": "Up to 60 seconds",
    "报警声个性化": "Alarm sound personalization",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "Camera alarm/broadcast sound, mobile phone push ringtone, can be customized. Rich local voices are prepared, you can also use voice and text to record any voice you want.",
    "监测区域自定义": "Monitoring area customization",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "One or more monitoring areas can be set to capture only the areas you care about to reduce unnecessary alarms; when an alarm is triggered, you will know exactly where the alarm is.",
    "AI智能通知(待开放)": "AI smart notification (to be opened)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "Using AI artificial intelligence to identify advanced algorithm technology, it can identify the main content of the screen: pets, vehicles, packages. And analyze its action behavior to push more accurate and intelligent information.",
    "已了解，去购买": "Understood, go buy",
    "已开通": "Already opened",
    "10秒/每条": "10 seconds/each",
    "3天\n(100条/天，10秒/条) ": "3 days (100 records/day, 10 seconds/bar) ",
    "7/30天\n (消息/视频无上限)": "7/30 days  (Unlimited message/video)",
    "30天\n (消息/视频无上限)": " 30 days\n (Unlimited message/video)",
    "距离服务到期": "Until service expires",
    "永久": "Permanent",
    "体验": "Experience",
    "监测区域": "Monitoring area",
    "报警推送100条/天": "Alarm push 100/day",
    "视频时长10秒": "Video length 10 seconds",
    "7天云存储": "7 days Cloud Storage",
    "30天云存储": "30 days Cloud Storage",
    "1个月": "1 month",
    "6个月": "6 months",
    "12个月": "12 months",
    "循环存储7天（使用时间6个月）": "Cycle storage for 7 days (use time for 6 months)",
    "查看详情": "See details",
    "安全": "Safety",
    "便捷": "Convenient",
    "贴心": "Considerate",
    "云存储特权": "Cloud Storage Privileges",
    "云端保护": "Cloud Protection",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "Videos are stored in the cloud, cannot be broken, stolen, protect Data Security",
    "隐私加密": "Privacy encryption",
    "金融级数据加密方案，保护隐私更\n安全": "Financial-grade data encryption scheme to protect privacy, safer",
    "存储灵活": "Flexible storage",
    "同时保存报警录像和视频，不错过任\n    何动态": "Save alarm recording and video at the same time, do not miss any movement",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "Due to tax and exchange rate reasons, the paid price will fluctuate slightly according to the country where your account is logged in",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "The function of the smart service only takes effect in the camera administrator privilege, and the shared user will follow the device administrator privilege to use it",
    "付款：确认购买并付款后记入iTunes 账户": "Payment: Credit to iTunes Account after confirmation of purchase and payment",
    "去支付": "To pay",
    "7天": "7 days",
    "60天": "60 days",
    "云视频录像": "Đám mây video video",
    "关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长": "Khi đóng cửa, đám mây sẽ không lưu video của bạn và dịch vụ lưu trữ đám mây hiện thời sẽ không kéo dài",
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "When the real-time video screen changes dynamically, the device will automatically record and upload it to Cloud Storage. Audio and video data undergo financial-grade encryption technology to ensure data security.",
    "激活": "Kích hoạt",
    "支持": "Hỗ trợ",
    "不支持": "Không ủng hộ",
    "年卡": "Gói cả năm",
    "半年卡": "Gói 6 tháng",
    "月卡": "Gói một tháng",
    "暂不支持，敬请期待": "Không ủng hộ, mong chờ",
    "卡片暂不支持充值": "Thẻ không hỗ trợ nạp tiền",
    "HI~有什么需要查询的吗？": "Hi~có cần giúp gì không?",
    "更多热门问题为你解答": "Thêm nhiều câu hỏi nóng cho bạn",
    "热门分类": "Phân loại phổ biến",
    "添加设备": "Thêm thiết bị",
    "监控异常": "Giám sát ngoại lệ",
    "操作视频": "Video hoạt động",
    "更多其他": "Khác",
    "常见问题": "Câu hỏi thường gặp",
    "在线客服": "Hỗ trợ trực tuyến",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}