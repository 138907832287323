<template>
  <div>
    <h3 style="text-align: center">{{ data && (data.nameInfo[language] || data.nameInfo['zh']) }}</h3>

    <el-divider/>

    <div style="padding: 0 20px;"
         v-html="data && data.remarkInfo && (data.remarkInfo[language] || data.remarkInfo['zh'])"></div>

    <div class="bottom" v-if="language === 'zh'">
      <span style="font-size: 14px;color:#9d9d9d">
        {{ translateTitle('问题没有得到解决？') }}
      </span>
      <el-button @click="enterFeedback(data && data._id)" type="primary"
                 style="margin-top: 10px;width: 65%;height: 50px;">
        {{ translateTitle('点击反馈') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {useRoute} from "vue-router";
import {useFAQStore} from "@/store/modules/FAQ";
import {ElMessage} from "element-plus";
import {nextTick, onMounted, watchEffect} from "vue"
import {translateTitle} from "../../utils/i18n";

export default {
  name: "detailsIntroduction",
  methods: {translateTitle},
  setup() {
    const route = useRoute()
    const FAQStore = useFAQStore()
    const {typeName, id} = route.query
    if (!typeName || !id) router.push({path: '/FAQ', replace: true})
    const list = FAQStore.titleObj[typeName] || []
    const data = list.find(item => item._id === id)
    console.warn("descriptionObject", list, data)
    if (!data) router.push({path: '/FAQ', replace: true})

    const language = FAQStore.from.actionInfo.language

    const enterFeedback = async (id) => {
      await FAQStore.getNextProblemList(id) ?
          await router.push({path: '/FAQ/informationCollection'}) :
          ElMessage({
            dangerouslyUseHTMLString: true,
            message: '<p style="line-height:20px">抱歉！问题<strong>未完善</strong>，请选择其他问题</p>',
            type: 'warning',
            center: true,
            duration: 5000,
          })
    }

    onMounted(()=>{
        nextTick(()=>{
          const video = document.getElementsByTagName('video')[0]
          if(!video) return
          video.setAttribute("controlslist","nodownload")
          console.warn("warn:",video)
        })
    })
    return {
      enterFeedback,
      typeName,
      language,
      data,
    }
  }
}
</script>

<style>
video, img{
  width: 100%;
  //width: 100% !important;
}
.bottom {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  /*position: absolute;*/
  /*bottom: 0;*/
}
</style>