export default {
    '确认关闭':'Potwierdź zamknięcie',
    "请稍等": "Um momento, por favor.",
    "试用中": "Okres próbny",
    "动态云录像": "Nagrywanie w chmurze po wykryciu",
    "全天云录像": "Nagrywanie 24H w chmurze",
    "连续包月": "Odnwaialana miesięczna sbskrypcaja",
    "首推": "Pierwsza rekomendacja",
    "新用户推荐": "Rekomendacja dla nowego użytkownikaa",
    "循环存储7天": "Pętla 7 dniowa",
    "循环存储30天": "Petla 30 dniowa",
    "循环存储15天": "Petla 15 dniowa",
    "循环存储3天": "Petla 3 dniowa",
    "超80%人选择": "Ponad 80% wybiera to",
    "连续半年": "Odnawialna półroczna subskrypacja",
    "连续包年": "Odnawialna roczna subskrypcja",
    "包年": "Roczna subskrypacja",
    "包半年": "Półroczna subskrypacja",
    "包月": "Miesięczna subskrypacja",
    "新用户首选": "Pierwszy wybór nowego użytkowanika",
    "尝鲜": "Początkujący użytkownicy",
    "家用省电套餐": "Pakiet domowego oszczędzania energii",
    "超长周期套餐": "Pakiet długiego przechowywania",
    "看店必备套餐": "Kup pakiet",
    "到期后自动续费，可随时取消": "Automatyczne odnowienie po wygaśnięciu, można je anulować w dowolnym momencie",
    "自动续费协议": "Umowa o automatyczne odnowienie",
    "消息/视频无上限": "Nieograniczone wiadomości/filmy",
    "云存储购买协议": "Umowa do zakupu chmury",
    "动态云录像.png": "DTYLX_pl.png",
    "imageHeight": "380px",
    "已优惠": "Rabat",
    "天": "dni",
    "30天": "30 dniowa",
    "15天": "15 dniowa",
    "3天": "3 dniowa",
    "有效日期至": "Ważne do",
    "距离服务到期": "Czas świadczenia usługi",
    "已到期": 'Wygaśnięty',
    "可升级": 'Usługa rozszerzona',
    "首选": "być pierwszym wyborem",
    "超过80%人选择": "Więcej niż 80% wybierz",
    "存储周期": "Okres archiwizacji",
    "订单": "zamówienie",
    "去续费": "Aby odnowić",
    "云存储录像邀您试用": "Video z pamięci masowej w chmurze zaprasza do wypróbowania",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "Po aktywacji będziesz miał miesięczny okres przechowywania w chmurze (pętla 7dni). Wideo będzie zapisywane w chmurze po wykryciu alarmu.",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Okres próbny 30 dni (pętla 7dni)",
    '云视频录像':'Nagrywanie wideo w chmurze',
    '关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长':'Po zamknięciu filmy nie będą zapisywane w chmurze, a bieżąca usługa przechowywania w chmurze nie zostanie przedłużona',
    "去支付": "Zapłać",
    "任意看日均": "średnia na dzień",
    "元": "RMB",
    "省": "Save",
    "推荐": "polecam",
    "请等待": "Proszę poczekać",
    "永久": "stałe",
    "体验": "doświadczenie",
    "激活": "Aktywuj",
    "支持": "Wsparcie",
    "不支持": "Nie obsługiwane",
    "已激活": "Aktywowane",
    "设备列表为空": "Lista urządzeń jest pusta",
    "确定激活": "Potwierdź aktywację",
    "激活失败,请联系商家": "Aktywacja nie powiodła się, skontaktuj się ze sprzedawcą",
    "激活中...": "Przetwarzanie...",
    "激活码不存在": "Kod aktywacyjny nie istnieje",
    "系统异常": "Problem systemu",
    "已使用": "zużyty",
    "未使用": "niezużyty",
    "取消": "Anuluj",
    "确认": "Potwierdź",
    "年卡": "Roczna subskrypacja",
    "半年卡": "Półroczna subskrypacja",
    "月卡": "Miesięczna subskrypacja",
    "暂不支持，敬请期待": "Nie jest jeszcze obsługiwany, czekaj na niego",
    "卡片暂不支持充值": "W chwili obecnej nie ma możliwości doładowania kart.",
    "HI~有什么需要查询的吗？": "Cześć! Jak mogę Ci pomóc?",
    "更多热门问题为你解答": "Odpowiedzi na najpopularniejsze pytania",
    "热门分类": "Popularne kategorie",
    "添加设备": "Dodaj urządzenie",
    "监控异常": "Monitorowanie zdarzeń niestandard.",
    "操作视频": "Operacja wideo",
    "更多其他": "Więcej innych",
    "常见问题": "typowe problemy",
    "在线客服": "Internetowa obsługa klienta",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}