import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: HomeView
    // },
    // {
    //   path: '/',
    //   name: 'index',
    //   component: () => import('../views/云存储套餐/home.vue')
    // },
    {
        path: '/',
        name: 'Root',
        redirect: '/index'
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('../views/error.vue')
    },
    {
        path: '/index',
        name: 'index',
        meta: {title: "4G流量商城"},
        component: () => import('../views/4G流量购买/index.vue')
    },
    {
        path: '/universal',
        name: 'universal',
        meta: {title: "查询页"},
        component: () => import('../views/universal.vue')
    },
    {
        path: '/pay',
        name: 'payment',
        meta: {title: "4G流量商城"},
        component: () => import('../views/4G流量购买/components/Pay.vue')
    },
    {
        path: '/combo',
        name: 'Combo',
        component: () => import('../views/4G流量购买/components/Combo.vue')
    },
    {
        path: '/purchaseAgreement',
        name: 'PurchaseAgreement',
        meta: {title: "购买协议"},
        component: () => import('../views/4G流量购买/components/purchaseAgreement.vue')
    },
    {
        path: '/autoRenewalAgreement',
        name: 'autoRenewalAgreement',
        meta: {title: "自动续费协议"},
        component: () => import('../views/4G流量购买/components/autoRenewalAgreement.vue')
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('../views/4G流量购买/components/orderDetail.vue')
    },
    {
        path: '/Issues',
        name: 'Issues',
        component: () => import('../views/4G流量购买/components/Issue.vue')
    },
    {
        path: '/succeed',
        name: 'succeed',
        meta: {title: "4G流量商城"},
        component: () => import('../views/4G流量购买/components/succeed.vue')
    },
    {
        path: '/intelligentDetection',
        name: 'intelligentDetection',
        component: () => import('../views/4G流量购买/components/intelligentDetection.vue')
    },
    {
        path: '/YCC_purchaseAgreement',
        name: 'YCC_purchaseAgreement',
        component: () => import('../views/云存储套餐/components/purchaseAgreement.vue')
    },
    {
        path: '/cloudStorage',
        name: 'cloudStorage',
        meta: {title: "云存储商城"},
        component: () => import('../views/云存储套餐/index.vue')
    },
    {
        path: '/cloudStorageLowPowerDevice',
        name: 'cloudStorageLowPowerDevice',
        meta: {title: "云存储商城"},
        component: () => import('../views/云存储低功耗设备/index.vue')
    },
    {
        path: '/RenewalManagement',
        name: 'RenewalManagement',
        component: () => import('../views/云存储套餐/RenewalManagement.vue')
    },
    {
        path: '/orderPage',
        name: 'orderPage',
        component: () => import('../views/4G流量购买/orderPage.vue')
    },
    {
        path: '/advantage',
        name: 'advantage',
        component: () => import('../views/云存储套餐/advantage.vue')
    },
    {
        path: '/coupons',
        name: 'Coupons',
        component: () => import('../views/券/index.vue')
    },
    {
        path: '/coupons/activate',
        name: 'couponsActivate',
        component: () => import('../views/券/activate.vue')
    },
    {
        path: '/FAQ',
        name: 'FAQ',
        meta: {title: "常见问题"},
        component: () => import('../views/FAQ/home.vue')
    },
    {
        path: '/FAQ/titleList',
        name: 'titleList',
        component: () => import('../views/FAQ/titleListPage.vue')
    },
    {
        path: '/FAQ/chooseReply',
        name: 'chooseReply',
        component: () => import('../views/FAQ/chooseReply.vue')
    },
    {
        path: '/FAQ/informationCollection',
        name: 'informationCollection',
        component: () => import('../views/FAQ/informationCollection.vue')
    },
    {
        path: '/FAQ/informationTransfer',
        name: 'informationTransfer',
        component: () => import('../views/FAQ/informationTransfer.vue')
    },
    {
        path: '/FAQ/description',
        name: 'detailsIntroduction',
        component: () => import('../views/FAQ/detailsIntroduction.vue')
    },
    {
        path: '/Battery/:did?/:power?',
        name: 'Battery',
        component: () => import('../views/Battery/index.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.afterEach(async (to: { path: string }, from: any, next: any) => {
    const {language} = router.currentRoute.value.query
    if (typeof language === "string") localStorage.setItem("language", language)
})

router.beforeEach(async (to: any, from: any, next: any) => {
    // console.log("beforeEach:", to)
    if(to.meta.title) document.title = to.meta.title
    next()
})

export default router
