import {
  activation,
  cancelOrder,
  createOrder,
  getDeviceName,
  lowPowerStatus,
  openTrial,
  productID,
  status
} from "@/api/D004";
import {defineStore} from "pinia";
import {ElLoading} from 'element-plus'
import {payment_h5} from "@/api/payment";
import {CloudStorageModuleType} from "/#/store";
import {WebViewBridge} from "@/utils/bridge.js";
const {message: languageList} = require('@/i18n/index.js')
import {translateTitle} from "@/utils/i18n.js"
import {lowPowerFP,normalElectricityFP} from "@/utils/fixedPackage.js"
import {sortAsNormal, sortByMode} from "@/utils/tool.js";

const webViewBridge = new WebViewBridge()
let loading: any = null
let antiShake = false

export const useCloudStorageStore = defineStore('cloudStorageStore', {
  state: (): CloudStorageModuleType => ({
    EquipmentType: '低功耗', //设备类型( 低功耗、常电)
    status: false,// 是否开通
    deviceName: "---", // 设备名称
    key: '-_',
    expirationTime: '-',
    did: '-',
    updateTime: '-',
    deadline: '-',
    activation: '0',
    selectedCombo: {
      currencySymbol: "",
      price: 0,
      productID: '',
      id: ''
    },
    selectedIndex: -1,
    ComboList: [],
    ComboType: "",
    bridgeInfo: {
      userId: '',
      authkey: '',
      language: '',
      OEMID: '',
      NavColor: '',
    },
    area: 'cn',
    getCountryCode: "CN",
    lowPowerCycleTime: null,
    webViewBridge: null,
  }),
  getters: {
    // 获取低功耗循环周期展示的内容
    getLowPowerCycleTime(state) {
      if (state.status) {
        return state.lowPowerCycleTime
      } else {
        // return "1"
        console.log('--------', state.bridgeInfo.language)
        return (state.getCountryCode === 'CN' && state.bridgeInfo.language.toUpperCase() === 'ZH') ? "1" : "3"
      }
    },
    showComboTypeButton(state){
      // 判断是否是低功耗模式，如果是低功耗则显示套餐类别选项
      return state.ComboType === 'lowPowerFixedPlan' || state.ComboType === 'lowPowerIapPlan';
    }
  },
  actions: {

    async getBridgeInfo() {
      this.webViewBridge.registerWindowEvent((data: any) => {
        console.log("WindowLoading:", data)
      })
      // 设置状态栏颜色
      // const skin = required.skin
      // webViewBridge.setNavColor(skin)

      this.webViewBridge.callHandler('getAuthkey', "", (data: any) => {
        // 获取authkey
        console.warn("getAuthkey", data)
        this.bridgeInfo.authkey = data
      });

      // 获取国内外状态，国内套餐有三类，国外套餐只有两类
      this.webViewBridge.getCountryCode().then((data: string) => {
        this.getCountryCode = data
        console.warn("getCountryCode", data)
      }).catch((data: any) => {
        this.getCountryCode = data
        console.warn("getCountryCode超时默认", data)
      })

      this.webViewBridge.callHandler('getLanguage', "", (responseData: string) => {
        //查询系统语言
        console.warn("app返回语言:", responseData)
        this.bridgeInfo.language = responseData.split('-')[0]
        console.warn("处理后语言缩写:", this.bridgeInfo.language)
        let list = Object.keys(languageList)
        if (list.indexOf(this.bridgeInfo.language) !== -1) {
          localStorage.setItem("language", this.bridgeInfo.language)
        }
        else {
          localStorage.setItem("language", "en")
          console.warn("不存在的语言")
        }
      })

      this.webViewBridge.callHandler('getFlowPageColor', "", (data: string) => {
        //获取导航栏颜色
        this.bridgeInfo.NavColor = data.split("0xff")[1].slice(0, -2)
        console.log("获取颜色", this.bridgeInfo.NavColor)
      });

      return new Promise((resolve, reject) => {
        this.webViewBridge.callHandler('getUserid', "", (responseData: any) => {
          //查询用户ID
          this.bridgeInfo.userId = responseData
          this.webViewBridge.callHandler('getOEMID', "", (data: string) => {
            //获取客户端编号
            this.bridgeInfo.OEMID = data
            console.warn("app必要数据获取完毕", this.bridgeInfo)
            resolve(true)
          });
        })
      })

    },
    async initBridge() {
      this.webViewBridge = webViewBridge
      let bridgeInfo = await this.webViewBridge.init().catch(() => false)
      console.warn("bridgeInfo:", bridgeInfo)
      if (bridgeInfo) {
        console.warn("初始化成功")
        return await this.getBridgeInfo()
      } else {
        // 失败重新调用一次
        let bridgeInfo = await this.webViewBridge.init().catch(() => false)
        console.warn("bridgeInfo:", bridgeInfo)
        if (bridgeInfo) {
          console.warn("初始化成功")
          return await this.getBridgeInfo()
        } else {
          console.warn("PC端 bridgeInfo初始化失败")
          return false
        }
        // console.warn("PC端 bridgeInfo初始化失败")
        // return false
      }
    },
    // 获取常电云存储设备信息
    async getDeviceInfo(did: string) {
      const data = await status({did})
      this.deviceName = await getDeviceName({userid: this.bridgeInfo.userId, did: did})
      this.status = data.isOpen
      console.warn("status", this.status)
      if (this.status) {
        let {key, expirationTime, uid, activation} = data.info
        this.key = key
        this.expirationTime = expirationTime
        this.did = uid
        this.activation = activation
        let deadline = (new Date(expirationTime).getTime() - new Date().getTime()) / 1000
        this.deadline = Math.floor(deadline / (24 * 60 * 60)).toFixed(0)
        console.log(this.deadline, "deadline");
      } else {
        this.did = did
        // this.deadline = "0"
        // this.key = "-_"
        // alert("设备未开通")
      }
    },
    // 获取低功耗云存储设备信息
    async getLowPowerStatus(did: string) {
      console.warn("this.bridgeInfo.userId", this.bridgeInfo)
      this.deviceName = await getDeviceName({userid: this.bridgeInfo.userId, did})
      const data = await lowPowerStatus({did})
      this.status = data.isOpen
      if (this.status) {
        let {did: deviceId, expirationTime, cycle} = data.info
        this.did = deviceId
        this.expirationTime = expirationTime
        this.lowPowerCycleTime = cycle
        let deadline = (new Date(expirationTime).getTime() - new Date().getTime()) / 1000
        this.deadline = Math.floor(deadline / (24 * 60 * 60)).toFixed(0)
        console.log(this.deadline, "getLowPowerStatus");
      } else {
        this.did = did
        // alert("设备未开通")
      }
    },
    // 切换常电云存储激活状态
    async changeActivation() {
      const {did, bridgeInfo} = this
      const {userId: userid, authkey} = bridgeInfo
      const action = this.activation === '1' ? "0" : "1"
      const data = {
        did, userid, authkey, action
      }
      console.log("执行了")
      await activation(data)
      await this.webViewBridge.set_update_push_user(did)
      this.activation = action
    },
    // 开启试用体验
    async openTrial() {
      const {did, area, bridgeInfo} = this
      const {userId: userid, authkey} = bridgeInfo
      const data = {did, area, userid, authkey}
      await openTrial(data)
      await this.getDeviceInfo(this.did)
    },
    // 获取常电固定套餐
    initFixedPlan() {
      this.ComboType = 'fixedPlan'
      this.ComboList = normalElectricityFP
    },
    // 获取低功耗固定套餐
    lowPowerFixedPlan() {
      this.ComboType = 'lowPowerFixedPlan'
      this.ComboList = lowPowerFP
    },
    // 获取常电内购套餐
    async initIapPlan(area: string, isIOS: boolean) {
      this.ComboType = 'iapPlan'
      let arg = {
        region: area,
        type: isIOS ? 'ios' : 'android',
        oemid: this.bridgeInfo.OEMID,
      }
      let productList = await productID(arg)
      // 用内购，内购套餐需要再根据bridge获取
      let res = await this.webViewBridge.queryProductInfo(area, Object.keys(productList)).catch(() => false)
      if (!res) return
      console.warn("订单列表", res)

      const t = JSON.parse(res);
      t.forEach((item: any) => {
        item.id = productList[item.productID]
      })

      this.ComboList = sortAsNormal(t)
      console.warn("this.ComboList", this.ComboList)
    },
    // 获取低功耗内购套餐
    async lowPowerIapPlan(isIOS: boolean) {
      this.ComboType = 'lowPowerIapPlan'
      const productList = isIOS ?
        [
          'D015_1_20220107',
          'D015_6_20220107',
          'D015_12_20220107',
          'D015_1_15_20221020',
          'D015_6_15_20221020',
          'D015_12_15_20221020',
          'D015_1_3_20221020',
          'D015_6_3_20221020',
          'D015_12_3_20221020',
        ] :
        [
          'd015_1_20220221',
          'd015_6_20220221',
          'd015_12_20220221',
          'd015_1_15_20221020',
          'd015_6_15_20221020',
          'd015_12_15_20221020',
          'd015_1_3_20221020',
          'd015_6_3_20221020',
          'd015_12_3_20221020',
        ]
      const productMapId: any = {
        'D015_1_20220107': '512',
        'D015_1_20220221': '512',
        'D015_6_20220107': '514',
        'D015_6_20220221': '514',
        'D015_12_20220107': '513',
        'D015_12_20220221': '513',
        'D015_1_15_20221020': '515',
        'D015_6_15_20221020': '516',
        'D015_12_15_20221020': '517',
        'D015_1_3_20221020': '518',
        'D015_6_3_20221020': '519',
        'D015_12_3_20221020': '520',
      }
      let res = await this.webViewBridge.queryProductInfo("default", productList).catch(() => false)
      if (!res) return
      console.warn("订单列表", res, productList)

      const t = JSON.parse(res);
      t.forEach((item: any) => {
        item.id = productMapId[item.productID.toUpperCase()]
      })

      this.ComboList = sortByMode(t)
      console.warn("套餐列表", this.ComboList)
    },
    // 根据机型判断使用什么类型套餐
    async getLowPowerPlans() {
      let support_wechat: boolean
      let support_alipay: boolean
      let support_H5: boolean
      let support_google: boolean
      let isIOS = this.webViewBridge.isIOS()
      // 如果是ios设备则不能执行google包判断
      support_google = !isIOS ? await this.webViewBridge.isSupportGoogle().catch(() => false) : false
      console.warn("是否支持google", support_google)
      if (!isIOS && !support_google) {
        this.webViewBridge.callHandler('wechat', "", (responseData: string) => {
          console.log('wechat', responseData)
          if (responseData === '1') support_wechat = true;
          this.webViewBridge.callHandler('alibaba', "", (responseData: string) => {
            console.log('alibaba', responseData)
            if (responseData === '1') support_alipay = true;
            this.webViewBridge.callHandler('isSupportH5Pay', "", (responseData: string) => {
              console.log('isSupportH5Pay', responseData)
              if (responseData === '1') support_H5 = true;
              if ((support_wechat || support_alipay) && support_H5) {
                console.log("固定套餐2")
                this.lowPowerFixedPlan()
              } else {
                console.log("iap 套餐2")
                this.lowPowerIapPlan(isIOS);
              }
            });
          });
        });
      } else {
        await this.lowPowerIapPlan(isIOS);
        console.log("iap 套餐2")
      }
    },
    // 提交生成订单
    async onSubmit() {
      if (antiShake) return
      antiShake = true
      console.warn("提交啦", this.selectedCombo)
      loading = ElLoading.service({
        lock: true,
        text: translateTitle('请稍等'),
        background: 'rgba(0, 0, 0, 0.7)',
      })
      console.warn("开启loading")

      const {bridgeInfo, did, selectedCombo} = this
      let data = {
        userid: bridgeInfo.userId,
        authkey: bridgeInfo.authkey,
        target: did,
        productID: selectedCombo.id,
      }

      let {id: orderId} = await createOrder(data)

      if (this.ComboType === 'fixedPlan' || this.ComboType === 'lowPowerFixedPlan') await this.normalPay(orderId)
      else if (this.ComboType === 'iapPlan' || this.ComboType === 'lowPowerIapPlan') await this.iapPay(orderId)
      antiShake = false
    },
    // 支付宝微信等支付方法付款
    async normalPay(orderID: string) {
      const {bridgeInfo, did, selectedCombo} = this
      const arg = {
        uid: bridgeInfo.userId,  //用户ID = userid
        did, //设备ID
        orderID,
        cType: "Android",
        business: this.ComboType === 'fixedPlan' ? "D004" : "D015",//业务编号   D004  D015
        oemid: bridgeInfo.OEMID,
        language: bridgeInfo.language,
        skin: bridgeInfo.NavColor,
        marker: selectedCombo.marker,  //D015 例如 30-6  30-1
      }
      console.warn("各参数", arg)
      const {url} = await payment_h5(arg)
      loading.close()
      this.webViewBridge.newPage(url)
    },
    // 内购支付方法付款
    iapPay(orderID: string) {
      let data = {
        orderID,
        productID: this.selectedCombo.productID
      }
      this.webViewBridge.callHandler('pay', data, (responseData: any) => {
        loading.close()
        console.warn("关闭loading")
        // 设置计时器关闭并通知网络异常
        let {code, orderID}: { code: string, orderID: string } = JSON.parse(responseData);
        switch (code) {
          case '401': //苹果交易失败
          case '403': //服务器验证失败
          case '404': //没查到产品key
                      // TODO 处理
            console.warn("交易失败，验证失败，没查到产品")
            break;
          case '402': //用户取消交易
            console.warn("取消交易")
            const {userId: userid, authkey} = this.bridgeInfo
            let data = {
              userid, //用户ID
              authkey, //用 户的authkey
              orderID, //想要取消的订单ID
              channelId: this.webViewBridge.isIOS() ? '1' : '3', //支付渠道, 例如如果是苹果内购 则填 1
            }
            cancelOrder(data)
            break;
          case '200':  //交易成功
            // getD004Info(did);
            console.warn("交易成功，更新数据", this.ComboType)
            if (this.ComboType === 'lowPowerIapPlan' || this.ComboType === 'lowPowerFixedPlan') {
              this.getLowPowerStatus(this.did)
              if(this.ComboType === 'lowPowerIapPlan'){
                // 刷新套餐
                this.lowPowerIapPlan(this.webViewBridge.isIOS())
                // this.initIapPlan(this.area, this.webViewBridge.isIOS())
              }else{
                this.initIapPlan(this.area, this.webViewBridge.isIOS())
              }
            } else if (this.ComboType === 'iapPlan' || this.ComboType === 'fixedPlan') {
              this.getDeviceInfo(this.did)
              if(this.ComboType === 'iapPlan'){
                // 刷新套餐
                this.lowPowerIapPlan(this.webViewBridge.isIOS())
                // this.initIapPlan(this.area, this.webViewBridge.isIOS())
              }else{
                this.initIapPlan(this.area, this.webViewBridge.isIOS())
              }
            }
            this.selectedCombo = {
              currencySymbol: "¥",
              price: 0,
              productID: '',
              id: ''
            }
            this.selectedIndex = -1
            this.webViewBridge.set_update_push_user(this.did);
            break;
          default:
            alert(responseData)
        }
      })
    }
  },
  persist: {
    enabled: true,
    strategies: [{
      storage: localStorage,
      paths: ["did"]
    }]
  }
})