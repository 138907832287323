/**
 * 应用调试规则管理
 */
import {getHost} from '@/utils/tool'

//本地API地址
const debugUrl = 'http://localhost:8081'
//调试API地址
const devUrl = 'https://test-wld.cam366.com'
//线上API地址
// const proUrl = 'https://payment-4G.eye4.cn'
// const proUrl = 'https://h5_test.eye4.cn:80'
const proUrl = 'https://payment-4G.eye4.cn'
//当前环境
const isDev = process.env.NODE_ENV === "development"
//调试模式,服务上线必须改为false！！！
const deBug = false
// 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
const contentType = 'application/json;charset=UTF-8'
// 最长请求时间
const requestTimeout= 20000

const baseURL = getBaseURL()

//设置网址
export function getBaseURL() {
    console.log('host',getHost())
    // const host = isDev ? getHost() === 'localhost:8082' ? debugUrl : devUrl : deBug ? devUrl : proUrl
    const host = proUrl
    const URL = host + "/api"
    console.warn('API启用', URL)
    // if (isDev) new VConsole()
    console.log('host',getHost())
    return URL
}

export {
    baseURL,contentType,requestTimeout
}