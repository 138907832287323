export default {
    '确认关闭':'Подтвердите выключение',
    '取消': 'отмен',
    "请稍等": " Подождите минутку. ",
    "试用中": "В процессе испытательного использования",
    "动态云录像": "Динамическая видеозапись в облаке",
    "全天云录像": "Видеозапись в облаке в течение всего дня",
    "连续包月": "Подряд месячный пакет",
    "首推": "Впервые пуш",
    "新用户推荐": "Рекомендация для новых пользователей",
    "循环存储7天": "Циклическое хранение в течение 7 дней",
    "循环存储30天": "Циклическое хранение в течение 30 дней",
    "循环存储15天": "Циклическое хранение в течение 15 дней",
    "循环存储3天": "Циклическое хранение в течение 3 дней",
    "超80%人选择": "Более 80% людей выбирают",
    "连续半年": "Подряд полгода",
    "连续包年": "Подряд годовой покет",
    "包年": "Годовой пакет",
    "包半年": "Полугодный пакет",
    "包月": "Месячный пакет",
    "新用户首选": "Первый выбор для новых пользователей",
    "尝鲜": "Опробовать новинку",
    "家用省电套餐": "Энергосберегающие пакеты для использования в доме",
    "超长周期套餐": "Пакет сверхдлительного цикла",
    "看店必备套餐": "Необходимый пакет смотреть магазин",
    "到期后自动续费，可随时取消": "Автоматическое продление оплаты после истечения срока действия, может быть отменено в любое время",
    "自动续费协议": "Соглашение об автоматическом продлении оплаты услуг",
    "消息/视频无上限": "Нет верхнего предела для сообщений/видео",
    "云存储购买协议": "Соглашение о покупке облачного хранения",
    "动态云录像.png": "DTYLX_rus.png",
    "imageHeight": "360px",
    "已优惠": "Льгота уже была дана",
    "AI智能服务": "Интеллектуальный сервис AI",
    "未开通": "Неактивированный",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "Приобретая \"Интеллектуальный сервис AI\", вы можете пользоваться сверхдлинным облачным хранилищем, индивидуально настраиваемыми зонами мониторинга и персонализацией звуков тревоги - и всё это будет активно исключительно для вас! Есть также функции интеллектуальной идентификации AI (идентификация сумок, транспортных средств и домашних животных), которые будут доступны после запуска услуги.",
    "30天超长云存储": "Сверхдлинное облачное хранилище на 30 дней",
    "报警和推送声个性化": "Персонализация звука тревоги и нажатия кнопки",
    "报警区域自定义": "Настройка зоны сигнализации",
    "人形追踪": "Отслеживание человека",
    "摄像头会实时跟踪人的行动轨迹": "Камера отслеживает движения человека в режиме реального времени",
    "人形框定": "Обрамление человека",
    "在实时视频里面会自动框出并跟踪所出现的人": "Человек, появляющийся на видео в реальном времени, автоматически обрамляется и отслеживается",
    "AI智能服务功能介绍": "Описание функции интеллектуального сервиса AI",
    "免费服务 VS Al智能服务": "Бесплатное обслуживание VS Интеллектуальный сервис AI",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "Продлите срок сверхдлинного хранения до 30 дней (6 пакетов на выбор) для зашифрованного облачного хранилища финансового уровня. Поверните время вспять, чтобы Вы больше не упустили ни одного мгновения.",
    "循环存储": "Циклическое хранилище",
    "无": "Нет",
    "30天": "30 Дней",
    "15天": "15 Дней",
    "3天": "3 Дней",
    "报警推送": "Нажатие кнопки тревоги",
    "100条": 100,
    "无上限！": "Безлимитный!",
    "视频时长": "Длительность видео",
    "最长60秒": "Максимум 60 секунд",
    "报警声个性化": "Персонализация звука тревоги",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "Звуки тревоги/трансляции камеры и мелодия звонка мобильного телефона могут быть индивидуально настроены. Подготовлено множество местных голосов, и Вы также можете записать любой звук, используя голос и текст",
    "监测区域自定义": "Настройка зоны мониторинга",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "Можно настроить одну или несколько зон мониторинга, чтобы захватывать только те области, которые вам важны, тем самым уменьшая количество ненужных оповещений; При срабатывании сигнала тревоги, Вы будете точно знать, где он находится",
    "AI智能通知(待开放)": "Интеллектуальные уведомления AI (необходимо открыть)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "Используя технологию передового алгоритма распознавания искусственным интеллектом AI, он может распознавать основное содержимое экрана: домашних животных, транспортные средства, посылки. И анализировать их действия и поведение для получения более точной и интеллектуальной информации.",
    "已了解，去购买": "Понятно, переходим к покупке",
    "已开通": "Открыто",
    "10秒/每条": "10 секунд/каждая запись",
    "3天\n(100条/天，10秒/条) ": "3 дня (100 записей/день, 10 секунд/запись)",
    "7/30天\n (消息/视频无上限)": "7/30 дней (Нет ограничения на количество сообщений/видео)",
    "30天\n (消息/视频无上限)": "30 дней (Нет ограничения на количество сообщений/видео)",
    "距离服务到期": "Время до истечения действия услуги",
    "永久": "Навсегда",
    "体验": "Опыт",
    "监测区域": "Зона мониторинга",
    "报警推送100条/天": "100 нажатий кнопки тревоги/день",
    "视频时长10秒": "Длительность видео 10 секунд",
    "7天云存储": "7-дневное облачное хранилище",
    "30天云存储": "30-дневное облачное хранилище",
    "1个月": "1 месяц",
    "6个月": "6 месяцев",
    "12个月": "12 месяцев",
    "循环存储7天（使用时间6个月）": "Циклическое хранение в течение 7 дней (6 месяцев использования)",
    "查看详情": "Узнать подробности",
    "安全": "Безопасность",
    "便捷": "Удобство",
    "贴心": "Внимательность",
    "云存储特权": "Привилегии облачного хранилища",
    "云端保护": "Облачная защита",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "Облачное хранение видео, не возможно взломать, не возможно украсть, обеспечение защиты безопасности данных",
    "隐私加密": "Шифрование конфиденциальности",
    "金融级数据加密方案，保护隐私更\n安全": "Решения шифрования данных финансового уровня для обеспечения защиты конфиденциальности и безопасности",
    "存储灵活": "Гибкость хранения",
    "同时保存报警录像和视频，不错过任\n    何动态": "Сохраняйте как запись сигнала тревоги, так и видео, чтобы не пропустить ни одного движения",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "В связи с налогами, обменными курсами и т.д., уплаченная цена будет незначительно колебаться в зависимости от страны, в которой вы вошли в аккаунт",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "Функция интеллектуального обслуживания будет доступна только при наличии прав администратора камеры, и пользователь, которому она предоставляется, будет обладать правами администратора устройства для её использования",
    "付款：确认购买并付款后记入iTunes 账户": "Оплата: Зачисление средств на аккаунт iTunes после подтверждения покупки и оплаты",
    " 去支付": "Перейти к оплате",
    "7天    ": "7 дней",
    "60天": "60 дней",
    "有效日期至": "Дзейнічае да",
    "已到期": ' срок годности ',
    "可升级": 'Upgradeable',
    '云视频录像':'Облачная видеозапись',
    '关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长':'После закрытия ваши видео не будут сохранены в облаке, а текущая услуга облачного хранения не будет продлена',
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "При динамическом изменении видеозаписи в реальном времени, устройство автоматически записывает и загружает её в облачное хранилище. Аудио- и видеоданные шифруются с помощью технологии шифрования финансового уровня для обеспечения безопасности данных.",
    "去支付": "платить ",
    "天": "дней",
    "首选": "предпочтительный ",
    "超过80%人选择": "выбор более 80% ",
    "存储周期": "период запоминания",
    "订单": "заказ ",
    "去续费": "плата за продление ",
    "云存储录像邀您试用": "облака хранения видео приглашать вас на тест",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "у вас будет месяц бесплатный испытательный долго, видео - сохранить облака не боятся потерять, видео сохранить семь дней, легко",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "среднесуточный ",
    "元": "RMB",
    "省": "Save",
    "推荐": "рекомендовать ",
    "请等待": "Ждите",
    "激活": "Активация",
    "支持": "Поддержка",
    "不支持": "Не поддерживается",
    "年卡": "Годовой пакет",
    "半年卡": "Полугодный пакет",
    "月卡": "Месячный пакет",
    "暂不支持，敬请期待": "Пока не поддерживается, оставайтесь в курсе",
    "卡片暂不支持充值": "В настоящее время карты не пополняются",
    "HI~有什么需要查询的吗？": "Привет~ Могу ли я как-то помочь с запросом?",
    "更多热门问题为你解答": "Ответы на самые популярные вопросы",
    "热门分类": "Горячие категории",
    "添加设备": "Добавить устройство",
    "监控异常": "Мониторинг аномалий",
    "操作视频": "Видео о работе",
    "更多其他": "Больше",
    "常见问题": "Часто задаваемые вопросы",
    "在线客服": "Онлайн-обслуживание клиентов",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}