// import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import ElementPlus from 'element-plus'
import axios from 'axios'
import VConsole from 'vconsole'
import dataV from '@jiaminghi/data-view'
const isDev = process.env.NODE_ENV === "development"
if(window.location.host.includes("h5_test.eye4.cn") || isDev) new VConsole()
console.warn("v3.0.7")
// new VConsole()

// @ts-ignore
import { baseURL } from './config'
import 'vant/lib/index.css';
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/el-loading.css';

axios.defaults.baseURL = baseURL

require("./registerServiceWorker");

createApp(App).use(store).use(router).use(Vant).use(ElementPlus).use(dataV).mount('#app')
