<template>
  <div class="warning">
    {{text}}
  </div>
</template>

<script>
import {nextTick,ref} from "vue";

export default {
  name: "advantage",
  setup(){
    // setTimeout(()=>{
    // },3000)
    const text = ref('')
    nextTick(()=>{
      console.warn("接入nexttick")
      if( process.env.NODE_ENV === "development" ){
        console.warn("开发模式")
        text.value="请使用线上版本查看"
      }else{
        console.warn("生成模式",window.location.host + "/introduction")
        window.location.href = "/introduction"
      }
    })
    return{
      text
    }
  }
}
</script>

<style scoped>
.warning{

  font-size: 22px;
  text-align: center;
  color: #ababab;
  margin-top: 40vh;
  letter-spacing: 3px;
  font-weight: bold;
}
</style>