import en from './en'
import zh from './zh'
import rus from "@/i18n/rus";
import de from "@/i18n/de";
import Ita from "@/i18n/Ita";
import VN from "@/i18n/VN";
import es from "@/i18n/es";
import Jap from "@/i18n/Jap";
import th from "@/i18n/th";
import Poland from "@/i18n/Poland";
import fr from "@/i18n/fr";
import Korean from "@/i18n/Korean";
import PT from "@/i18n/PT";
import el from "@/i18n/el";
import tr from "@/i18n/tr";
import fa from "@/i18n/fa";

export const message = {
    en: {
        ...en,
    },
    zh: {
        ...zh,
    },
    ru: {
        ...rus,
    },
    de: {
        ...de,
    },
    it: {
        ...Ita,
    },
    vi: {
        ...VN,
    },
    es: {
        ...es,
    },
    ja: {
        ...Jap,
    },
    th: {
        ...th,
    },
    pl: {
        ...Poland,
    },
    fr: {
        ...fr,
    },
    ko: {
        ...Korean,
    },
    pt: {
        ...PT,
    },
    el: {
        ...el,
    },
    tr: {
        ...tr,
    },
    fa: {
        ...fa,
    },
}

export function getLanguage(){
    let language = localStorage.getItem('language') || 'zh';
    return message[language] || message['en']
}