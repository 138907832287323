export default {
    '确认关闭':'Ausschalten bestätigen',
    '取消': 'korrekt',
    "请稍等": "Bitte warten",
    "试用中": "auf Probe",
    "动态云录像": "dynamische Cloud-Aufnahme",
    "全天云录像": "alltägliche Cloud-Aufnahme",
    "连续包月": "Dauermonatspaket",
    "首推": "Erste Empfehlung",
    "新用户推荐": "Empfehlung für neue Kunden",
    "循环存储7天": "7-tägige Zyklusspeicherung",
    "循环存储30天": "30-tägige Zyklusspeicherung",
    "循环存储15天": "15-tägige Zyklusspeicherung",
    "循环存储3天": "3-tägige Zyklusspeicherung",
    "超80%人选择": "Wahl von über 80% Menschen",
    "连续半年": "halbjähriges Dauerpaket",
    "连续包年": "Dauerjahrespaket",
    "包年": "Jahrespaket",
    "包半年": "Halbjahrespaket",
    "包月": "Monatspaket",
    "新用户首选": "erste Wahl für neue Kunden",
    "尝鲜": "neuer Versuch",
    "家用省电套餐": "Energiesparendes Haushaltspaket",
    "超长周期套餐": "extra langes Dauerpaket",
    "看店必备套餐": "notwendiges Paket zur Ladenüberwachung",
    "到期后自动续费，可随时取消": "automatische Tarifverlängerung und jederzeit abzusagen",
    "自动续费协议": "automatisch zu verlängernde Vereinbarung",
    "消息/视频无上限": "Nachrichten/Videoaufnahme unbebrenzt",
    "云存储购买协议": "Kaufvereinbarung zur Cloud-Speicherung",
    "动态云录像.png": "DTYLX_de.png",
    "imageHeight": "360px",
    "已优惠": "vergünstigt",
    "AI智能服务": "AI Intelligente Service",
    "未开通": "Nicht aktiviert",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "Nach dem Kauf von \"AI intelligentem Service\" werden extrem lange Onlinespeicherung, Personalisierung von Überwachungszone sowie indualisierter Alarmton aktualisiert. Auch die AI intelligente Erfassung(einschl. Paket-, Haustier- und Autoerkennung) wird später zur Verfügung gestellt.",
    "30天超长云存储": "30-tägige extrem lange Onlinespeicherung",
    "报警和推送声个性化": "Personalisierung von Alarm- und Absendungston",
    "报警区域自定义": "kundenspezifisches Alarmzone",
    "人形追踪": "humanoide Verfolgung",
    "摄像头会实时跟踪人的行动轨迹": "Die Kameras würden die Menschenbewegung in Echtzeit verfolgen.",
    "人形框定": "humanoide Einrahmung",
    "在实时视频里面会自动框出并跟踪所出现的人": "In Echtzeitvideos wird die erscheinene Person eingerahmt und verfolgt.",
    "AI智能服务功能介绍": "Funktionserklärung von AI intelligenten Services",
    "免费服务 VS Al智能服务": "kostenlose Service VS AI intelligente Service",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "erweiterte bis zu 30-tägige extrem lange Onlinespeicherung(mit 6 Optionen zur Wahl), Aufbau einen Onlinespeicherungsservices mit Verschlüsslung auf finanziellem Niveau, damit die Zeit an Ihrer Fingerspitze zurückläuft und Sie keine ausgezeichnete Augenblicke verpassen.",
    "循环存储": "Zyklische Speicherung",
    "无": "Nichts",
    "30天": "30 Tage",
    "15天": "15 Tage",
    "3天": "3 Tage",
    "报警推送": "Alarmübermittlung",
    "100条": 100,
    "无上限！": "Unbegrenzt!",
    "视频时长": "Videodauer",
    "最长60秒": "bis zu 60 Sekunden",
    "报警声个性化": "personalisierter Alarmton",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "Die Kameraalarm- bzw. -nachrichtenton sowie Handy- lassen sich kundenspezifisch einstellen. Reichliche lokale Stimmen stehen Ihnen zur Wahl, Sie können auch selbst Text- und Tonaufnahme machen.",
    "监测区域自定义": "Personalisierung von Überwachungszone",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "Sie können ein oder mehrere Überwachungszone einstellen, um nur bestimmte Zonen zu überwachen und unnötige Alarmierung zu vermeiden. Bei der Alarmierung wissen Sie dann genau wo es sich genau befindet. ",
    "AI智能通知(待开放)": "AI intelligente Mitteilung(Eröffnung folgt)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "Mit den entwickelten Algorithmus-Techniken zur intelligenten Erfassung werden die Bildinhalte erfasst, Haustier, Autos und Pakete. Auch ihre Bewegung wird analysiert, um noch genauere und intelligentere Nachrichten zu übermitteln.",
    "已了解，去购买": "Verstanden, weiter zum Kauf",
    "已开通": "Schon verfügbar",
    "10秒/每条": "10 Sekunden/Stück",
    "3天\n(100条/天，10秒/条) ": "3 Tage (100 Nachrichten/Tag, 10 Sekunden/Nachricht) ",
    "7/30天\n (消息/视频无上限)": "7/30 Tage  (unbegrenzte Nachrichten/Video)",
    "30天\n (消息/视频无上限)": "30 Tage  (unbegrenzte Nachrichten/Video)",
    "距离服务到期": "bis zum Serviceablaufsdatum",
    "永久": "für immer",
    "体验": "Erleben",
    "监测区域": "Überwachungszone",
    "报警推送100条/天": "Alarmierungsnachrichten 100 pro Tag",
    "视频时长10秒": "Videodauer 10 Sekunden",
    "7天云存储": "7-tägige Onlinespeicherung",
    "30天云存储": "30-tägige Onlinespeicherung",
    "1个月": "1 Monat",
    "6个月": "6 Monate",
    "12个月": "12 Monate",
    "循环存储7天（使用时间6个月）": "Zyklische Speicherung für 7 Tage(Benutzung 6 Monate)",
    "查看详情": "Zum Detail",
    "安全": "Sicherheit",
    "便捷": "Praktisch",
    "贴心": "Rücksichtsvoll",
    "云存储特权": "Onlinespeicherungsprivileg",
    "云端保护": "Onlineschtz",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "Die Videoonlinespeicherung wird weder zerstört noch gestolen, Datenschutz gilt.",
    "隐私加密": "Verschlüsselt",
    "金融级数据加密方案，保护隐私更\n安全": "Verschlüsslungskonzept auf finanziellem Niveau, noch sicherer Datenschutz",
    "存储灵活": "flexible Speicherung",
    "同时保存报警录像和视频，不错过任\n    何动态": "Alarm- und Videoaufnahmen zugleich gespeichert, nichts wird verpasst.",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "Aus Gründen von Steuer und Wechselkurs kann sich die Tariffe je nach dem Ihr Konto zugehörigen Land variieren.",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "Die intelligenten Funktionen gelten nur im Rahmen von Befugnissen des Kameraverwalters, dies gilt auch für die Benutzer, an denen die Funktionen verteilt werden.",
    "付款：确认购买并付款后记入iTunes 账户": "Zahlung: Nach der Bestätigung und Zukauf wird dies dem iTunes-Konto zugeschrieben.",
    " 去支付": "zum Bezahlen",
    "7天    ": "7 Tage",
    "60天": "60 Tage",
    "有效日期至": "Gültig bis",
    "可升级": 'Upgradeable',
    "已到期": 'Abgelaufen',
    "云视频录像": "Cloud-Videoaufnahme",
    "关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长": "Nach dem Schließen werden Ihre Videos nicht in der Cloud gespeichert und der aktuelle Cloud-Speicherdienst wird nicht verlängert.",
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "Bei Änderung der Videobilder in Echtzeit wird die Videoaufnahme automatisch auf Onlinespeicherung hochgeladen, die dann auf finanziellem Niveau verschlüsselt werden, um Datenschutz zu sichern",
    "天": "Tag",
    "首选": "die erste Wahl sein",
    "超过80%人选择": "Mehr als 80% wählen",
    "存储周期": "Speicherzyklus",
    "订单": "Bestellung",
    "去续费": "Zu erneuern",
    "云存储录像邀您试用": "Cloud Storage Video lädt Sie ein, es auszuprobieren",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "Sie haben einen kostenlosen Testzeitraum von einem Monat. Das Video geht nicht verloren, wenn es in der Cloud gespeichert wird. Das Video wird sieben Tage lang aufbewahrt und die Wiedergabe ist einfach und bequem",
    "温馨提示": "Erinnerung",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "Nach dem Kauf wird der Service sofort wirksam. Wenn ein Objekt oder eine Person erkannt wird, um sich zu bewegen, wird das Video aufgezeichnet und vom Anfang bis zum Ende der Bewegung in die Cloud zur Speicherung hochgeladen.",
    "知道了": "Hab's",
    "免费试用(录像保存7天)": "Kostenlose Testversion (Video gespeichert für sieben Tage)",
    "任意看日均": "Durchschnitt pro Tag",
    "元": "RMB",
    "省": "Save",
    "推荐": "empfehlen",
    "请等待": "Bitte warten Sie",
    "激活": "Aktivierung",
    "支持": "Unterstützung",
    "不支持": "Nicht unterstützt",
    "年卡": "Jahrespaket",
    "半年卡": "Halbjahrespaket",
    "月卡": "Monatspaket",
    "暂不支持，敬请期待": "Noch nicht unterstützt, bleiben Sie dran",
    "卡片暂不支持充值": "Die Karten sind derzeit nicht wiederaufladbar ",
    "HI~有什么需要查询的吗？": "Hallo~Gibt es etwas, bei dem ich helfen kann?",
    "更多热门问题为你解答": "Weitere häufig gestellte Fragen für Sie beantwortet ",
    "热门分类": "Gemeinsame Klassifikationen",
    "添加设备": "Gerät hinzufügen",
    "监控异常": " Überwachung von Anomalien",
    "操作视频": "Betriebsvideo",
    "更多其他": "Weitere",
    "常见问题": "häufiges Problem",
    "在线客服": "Online-Kundenservice",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}