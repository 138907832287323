<template>
  <div class="select-box">
    <div v-if="rendered.isUnlimited">
      <div class="p-i-title">
        <span class="title-left-box"></span>
        <span style="font-size:16px;font-weight:bold;color:#5E4F41">
                {{ translateTitle('包看套餐') }}
              </span>
        <span class="title-right-box"></span>
      </div>
      <div>
        <div v-for="(item,index) in filterUnlimitedList" :key="index"
             @click="selectCard(index,item.id,item)" class="select-item"
             :class="{ 'select-item-active' : choosePlanStore.currentSelectIndex === index}"
        >
          <div v-if="item.group === 'automatic_renewal'" style="text-align: left">
                <span class="select-item-tip" style="background:#FF8D59">
                  {{ translateTitle("连续季度") }}
                </span>
          </div>
          <div v-else-if="comboCycle(item['unit'], item['mode']) === 720" style="height:20px;position:relative">
            <div class="recommend-tip"
                 :style="{backgroundImage: 'url(' + require('@/assets/recommend.png')}">
            </div>
            <div class="coupon" v-if="item.oldPrice" :style="{backgroundImage: 'url(' + require('@/assets/优惠券.png')}">
              {{ translateTitle('省') }}{{ item.oldPrice - item.price }}{{ translateTitle('元') }}
            </div>
          </div>
          <div v-else style="height:20px;position:relative">
            <div class="coupon" v-if="item.oldPrice" :style="{backgroundImage: 'url(' + require('@/assets/优惠券.png')}">
              {{ translateTitle('省') }}{{ item.oldPrice - item.price }}{{ translateTitle('元') }}
            </div>
          </div>

          <div v-if="item.group === 'automatic_renewal'" class="select-item-container">
            <div class="select-item-container-left">
                  <span style="font-size:20px;font-weight: bold;margin-bottom: 10px">
                    {{ translateTitle(comboCycle(item['unit'], item['mode']) + '天包看') }}
                  </span>
              <div style="font-size:14px;color:#bdbdbd">
                {{ translateTitle(comboCycle(item['unit'], item['mode'])) }}天后自动续费
                <van-tag v-if="choosePlanStore.isAutoContract" class="isAutoContract-tag" color="#FFF6EA"
                         text-color="#FE9158">
                  {{ translateTitle("已签约") }}
                </van-tag>
              </div>
            </div>
            <div class="select-item-container-right">
              <span style="font-size:20px;color:#FF8D59">¥{{ translateTitle(item['price']) }}元</span>
              <span v-if="item.oldPrice" class="oldPrice">¥{{ translateTitle(item.oldPrice) }}元</span>
            </div>
          </div>

          <div v-else class="select-item-container">
            <div class="select-item-container-left">
                  <span style="font-size:20px;font-weight: bold;margin-bottom: 10px">
                      {{ translateTitle(comboCycle(item['unit'], item['mode']) + '天包看') }}
                  </span>
              <span style="font-size:14px;color:#bdbdbd">
                    {{ translateTitle(comboCycle(item['unit'], item['mode'])) }}天后到期
                  </span>
            </div>
            <div class="select-item-container-right">
              <span style="font-size:20px;color:#FF8D59">¥{{ translateTitle(item['price']) }}元</span>
              <span v-if="item.oldPrice" class="oldPrice">¥{{ translateTitle(item.oldPrice) }}元</span>
              <span class="select-item-container-right-tip">
                  {{ translateTitle("任意看日均") }}
                {{ (item.price / (comboCycle(item['unit'], item['mode']))).toFixed(2) }}
                {{ translateTitle("元") }}
                  </span>
            </div>
          </div>
        </div>
        <div style="display:flex;flex-direction: row;color: #BEBEBE;margin-bottom: 15px"
             :style="{justifyContent: rendered.haveAutoRenewal ? 'space-between' : 'flex-end'}">
<!--          <div v-if="rendered.haveAutoRenewal">-->
<!--                <span style="margin-right: 15px;" @click="goAutoRenewalProtocol">-->
<!--                  自动续费协议-->
<!--                  <van-icon name="arrow"/>-->
<!--                </span>-->
<!--            <van-icon name="warning-o" style="vertical-align: middle;" size="20"-->
<!--                      @click="choosePlanStore.monthlyDescriptionShow = true"/>-->
<!--          </div>-->

          <div @click="goProtocol">
            购买协议
            <van-icon name="arrow"/>
          </div>
        </div>
        <div style="color: #BEBEBE;text-align:left">
          包看套餐从办理当日起至套餐周期结束前不限流量使用,支持续费、升级套餐，续费、升级套餐立即生效，并提供流量。
        </div>
      </div>
    </div>
    <div v-else>
      <div class="p-i-title">
        <span class="title-left-box"></span>
        <span style="font-size:16px;font-weight:bold;color:#5E4F41">
                {{ translateTitle('流量套餐') }}
              </span>
        <span class="title-right-box"></span>
      </div>
      <div>
        <div v-for="(item,index) in rendered.mixList" :key="index"
             @click="selectCard(index,item.id,item)" class="select-item"
             :class="{ 'select-item-active' : choosePlanStore.currentSelectIndex === index}">
          <div v-if="item.isHaveTip" style="text-align: left">
            <div class="select-item-tip" :style="{background: index ? '#4ABED7' : '#FF8D59'}">
              {{ translateTitle(item.isHaveTip) }}
            </div>
          </div>
          <div class="select-item-container">
            <div class="select-item-container-left">
                  <span style="font-size:20px;font-weight: bold;margin-bottom: 10px">
                    {{ translateTitle(item['flow']) }}G /
                    {{ translateTitle(item.mode === 'YEAR' ? '年' : '月') }}
                  </span>
              <span style="font-size:14px;color:#bdbdbd">
                    {{ translateTitle(item.mode === 'YEAR' ? '360' : '30') }}天后清零
                  </span>
            </div>
            <div class="select-item-container-right">
              <span style="font-size:20px;color:#FF8D59">¥{{ translateTitle(item['price']) }}元</span>
              <span v-if="item.oldPrice" class="oldPrice">¥{{ translateTitle(item.oldPrice) }}元</span>
              <van-tag v-if="item.introduce" round color="#4ABED7" class="select-item-right-tag">
                {{ translateTitle(item.introduce) }}
              </van-tag>
            </div>
          </div>
        </div>

        <div style="display:flex;flex-direction: row;color: #BEBEBE;margin-bottom: 15px"
             :style="{justifyContent: rendered.haveAutoRenewal ? 'space-between' : 'flex-end'}">
<!--          <div v-if="rendered.haveAutoRenewal" style="display:flex;flex-direction: row;align-items: center">-->
<!--                <span style="margin-right: 15px;" @click="goAutoRenewalProtocol">-->
<!--                  自动续费协议-->
<!--                  <van-icon name="arrow"/>-->
<!--                </span>-->
<!--            <van-icon name="warning-o" size="20"-->
<!--                      @click="choosePlanStore.monthlyDescriptionShow = true"/>-->
<!--          </div>-->

          <div @click="goProtocol">
            购买协议
            <van-icon name="arrow"/>
          </div>
        </div>

        <div style="color: #BEBEBE;text-align:left">
          流量包从办理当日起至结算日后到期,支持重复办理，重复办理套餐立即生效，并提供流量。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {translateTitle} from "@/utils/i18n";
import {onMounted, onUnmounted, reactive, toRefs, computed, watchEffect} from "vue";
import {useChoosePlanStore} from "@/store/modules/ChoosePlan";
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {getLocalParam, setUrlParam} from '@/utils/tool'
import {WebViewBridge} from '@/utils/bridge'
import router from "@/router";

export default {
  name: "selectCards",
  setup() {
    const choosePlanStore = useChoosePlanStore()
    const fourGFlowStore = useFourGFlowStore()
    const {webViewBridge} = fourGFlowStore
    //必要参数渲染参数
    let {defaultParams: required, renderedParams: rendered} = fourGFlowStore

    const state = reactive({
      priceFilterList: [],
      spreadMappingTable: {
        2131: 10,
        2133: 10,
        2107: 39,
        2134: 107,
        2137: 283
      },
      spreadMap: {
        "季度": 10,
        "90": 10,
        "180": 39,
        "360": 107,
        "720": 283
      }
    })

    //套餐周期
    const comboCycle = (unit, mode) => {
      let total
      if (mode === 'MONTH') {
        total = Number(unit) * 30
      } else if (mode === 'YEAR') {
        total = Number(unit) * 360
      }
      return total
    }

    //跳转购买协议页面
    const goProtocol = () => {
      if (required.scenes === 'APP') {
        const url = setUrlParam('/purchaseAgreement', '')
        webViewBridge.newPage(url)
      } else router.push({path: '/purchaseAgreement'})
    }

    //跳转自动续费协议页面
    const goAutoRenewalProtocol = () => {
      if (required.scenes === 'APP') {
        const url = setUrlParam('/autoRenewalAgreement', '')
        webViewBridge.newPage(url)
      } else router.push({path: '/autoRenewalAgreement'})
    }

    // 选中套餐
    const selectCard = (index, productId, combo) => {
      choosePlanStore.currentPrice = combo.price;
      if (combo.group === 'automatic_renewal') {
        choosePlanStore.discountedPrice = 10
      } else {
        choosePlanStore.discountedPrice = 0
      }
      choosePlanStore.discountedPrice = combo.oldPrice ? combo.oldPrice - combo.price : 0
      choosePlanStore.currentSelectIndex = index
      choosePlanStore.productId = productId
      choosePlanStore.selectedCombo = combo
    }

    const filterUnlimitedList = computed(() =>
        rendered.unlimitedList.filter(item =>
            (!state.priceFilterList.includes(item.price) || item.group === 'automatic_renewal') && item.status === '1'
        )
        // rendered.unlimitedList.filter(item => item.status === '1')
    )

    const getOldPrice = (item) => {
      const {remark,price} = item
      for (const [key, value] of Object.entries(state.spreadMap)) {
        if (remark.includes(key)) return price + value
      }
      return false
    }

    watchEffect(() => {
      state.priceFilterList.length = 0
      rendered.unlimitedList.forEach((item) => {
        item.oldPrice = required.dealer === 'SZYZKJ' ? getOldPrice(item) : null
        if (item.group === 'automatic_renewal') state.priceFilterList.push(item.price)
      })
      console.warn("需要过滤的价格:", state.priceFilterList, rendered.unlimitedList)
    })

    onUnmounted(() => choosePlanStore.$reset())

    return {
      ...toRefs(state),
      translateTitle,
      choosePlanStore,
      rendered,
      required,
      comboCycle,
      selectCard,
      goAutoRenewalProtocol,
      goProtocol,
      filterUnlimitedList
    }
  }
}
</script>

<style scoped>
/deep/ .van-tab__text {
  width: 100%;
}

.select-box {
  padding: 20px 20px 10px 20px;
  background: #ffffff;
}

.select-item {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  margin: 10px 0;
  transition: .8s;
}

.select-item:last-of-type {
  margin-bottom: 0;
}

.select-item-tip {
  color: #fff;
  border-radius: 5px 0;
  font-size: 12px;
  width: fit-content;
  padding: 5px 15px;
  display: inline-block
}

.recommend-tip {
  top: -5px;
  height: 60px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: -16px;
}

/*.recommend-tip{*/
/*  background: #FF2828;*/
/*  color: white;*/
/*  width: fit-content;*/
/*  font-size: 13px;*/
/*  padding: 2px 15px;*/
/*  border-radius: 0 5px 5px 0;*/
/*  margin-top: 10px;*/
/*  position:absolute;*/
/*  left: -4px;*/
/*}*/

/*.recommend-tip:after{*/
/*  content: "";*/
/*  width: 4px;*/
/*  height: 4px;*/
/*  background: linear-gradient(45deg,#FF2828,#FF2828 50%, transparent 50%,transparent 100%);*/
/*  position: absolute;*/
/*  bottom: -2px;*/
/*  left: 1px;*/
/*  transform: rotate(45deg);*/
/*  z-index:0;*/
/*}*/

.select-item-active {
  background-color: #DBF2F7;
  border: 1px solid #4ABED7
}

.select-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 20px);
  padding: 10px;
  height: 70px;
  position: relative;
}

.select-item-container-left, .select-item-container-right {
  /*height: calc(100% - 20px);*/
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.select-item-container-right {
  text-align: center;
  justify-content: center;
  width: 43%;
  margin-top: 10px
}

.select-item-container-right-tip {
  font-size: 9px;
  border-radius: 15px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 8px;
  color: #fff;
  background: #4ABED7;
  transform: scale(0.8);
  letter-spacing: 1px;
}

.coupon {
  width: 100px;
  height: 50px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  font-size: 16px;
  color: white;
  transform: scale(0.8) translateX(16px);
  line-height: 30px;
  top: 5px;
  letter-spacing: 1px;
  text-align: center;
}

.isAutoContract-tag {
  padding: 4px;
  margin-left: 4px;
}

.select-item-right-tag {
  padding: 5px 13px 4px 13px;
  font-size: 12px;
  transform: scale(0.9);
  letter-spacing: 1px;
  color: #f9f9f9;
  position: relative;
  width: fit-content;
  align-self: flex-end;
}

.select-item-right-tag:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #FF2828;
  position: absolute;
  bottom: -3px;
  transform: rotate(45deg);
}

.oldPrice {
  text-decoration: line-through;
  font-size: 14px;
  color: #bdbdbd
}

.p-i-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background: #ffffff00;
}

.title-left-box, .title-right-box {
  margin: 0 10px;
  display: inline-block;
  width: 15%;
  height: 3px;
  background: linear-gradient(to right, rgb(255, 255, 255), #B7914C)
}

.title-right-box {
  background: linear-gradient(to right, #B7914C, rgb(255, 255, 255))
}
</style>