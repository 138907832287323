import axios from 'axios'

export const baseURL = 'https://faq-api.eye4.cn/'


/**
 * axios请求拦截器配置
 * @param config
 * @returns {any}
 */
const requestConf = (config: any) => {
    return config
}

/**
 * @description axios初始化
 */
const instance = axios.create({
    baseURL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(requestConf, (error) => {
    return Promise.reject(error)
})

/**
 * axios响应拦截器
 * @param config 请求配置
 * @param data response数据
 * @param status HTTP status
 * @param statusText HTTP status text
 * @returns {Promise<*|*>}
 */
const handleData = async ({data, status, statusText}: any) => {
    //需要登录
    if (status === 200) return data

    // 异常处理
    return Promise.reject(data)
}

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
    response => handleData(response),
    error => {
        const {response} = error
        if (response === undefined) {
            return Promise.reject({})
        } else {
            return handleData(response)
        }
    }
)

export default instance