<template>
  <div>
    <el-skeleton style="width: 100vw" animated>
      <template #template>
        <el-skeleton-item variant="image" style="width: 90vw;margin: 0 auto;border-radius: 15px; height: 30vh"/>
        <el-skeleton-item variant="p" style="width: 50vw;margin:20px auto 0 auto"/>
        <div style="padding: 10px;">
          <div class="list" v-for="i in 3">
            <div class="list-left">
              <el-skeleton-item variant="text" style="width: 50%;height:30px"/>
              <el-skeleton-item variant="text" style="width: 60%"/>
            </div>
            <div class="list-right">
              <el-skeleton-item variant="text" style="width: 30%;height: 30px"/>
              <el-skeleton-item variant="text" style="width: 20%;"/>
              <el-skeleton-item variant="text" style="width: 35%"/>
            </div>
          </div>
<!--          <el-skeleton-item variant="div" style="width: 30%">-->
<!--            -->
<!--          </el-skeleton-item>-->
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  name: "skeleton"
}
</script>

<style scoped>
.list{
  height: 15vh;
  border-radius: 15px;
  border: 2px solid #eeeeee;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
}
.list-left,.list-right{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center
}
</style>