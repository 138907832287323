export default {
    '确认关闭':'確認してオフにする',
    '取消': '取り消します',
    "请稍等": "少々お待ちください",
    "试用中": "試用中",
    "动态云录像": "ダイナミッククラウド録画",
    "全天云录像": "24時間クラウド録画",
    "连续包月": "連続月ごとに購買",
    "首推": "ファースト推奨",
    "新用户推荐": "新規ユーザーへの推奨",
    "循环存储7天": "7日のサイクル保存",
    "循环存储30天": "30日のサイクル保存",
    "循环存储15天": "15日のサイクル保存",
    "循环存储3天": "3日のサイクル保存",
    "超80%人选择": "80%以上人々のお選択",
    "连续半年": "連続半年購買",
    "连续包年": "連続年ごとに購買",
    "包年": "一年間購買",
    "包半年": "半年間購買",
    "包月": "一ヶ月間購買",
    "新用户首选": "新規ユーザーのファースト選択",
    "尝鲜": "素早く応用",
    "家用省电套餐": "家庭用省エネコース",
    "超长周期套餐": "超長サイクルコース",
    "看店必备套餐": "ショップ必須コース",
    "到期后自动续费，可随时取消": "期限切れになると自動的に費用を支払い、タイムリーにキャンセルできます",
    "自动续费协议": "オート支払いの協定",
    "消息/视频无上限": "メッセージ・動画に制限無し",
    "云存储购买协议": "クラウド保存協定",
    "动态云录像.png": "DTYLX_Jap.png",
    "imageHeight": "350px",
    "已优惠": "ディスカウント済み",
    "AI智能服务": "AIインテリジェントサービス",
    "未开通": "未開通",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "AIインテリジェントサービスを購入した後，長期間のクラウドストレージ、監視エアーのカスタマイズ、アラーム声のパーソナライズ、全部開通して専用提供する。またAIインテリジェント識別功能があります（バッグ、ペット、車両を識別）オンラインした後も含みて専用サービスです。",
    "30天超长云存储": "30日間の長期間クラウドストレージ",
    "报警和推送声个性化": "アラームと推薦声のパーソナライズ",
    "报警区域自定义": "アラームエアーのカスタマイズ",
    "人形追踪": "ヒューマノイド　トラッキング",
    "摄像头会实时跟踪人的行动轨迹": "カメラはリアルタイムに人の動きを追跡できます。",
    "人形框定": "ヒューマノイドフレーミング",
    "在实时视频里面会自动框出并跟踪所出现的人": "リアルタイムのビデオ中で自動的に追跡した人を現した明確できます。",
    "AI智能服务功能介绍": "AIインテリジェントサービス功能の紹介",
    "免费服务 VS Al智能服务": "無料サービス　VS　AIインテリジェントサービス",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "最高30日間の長期間クラウドストレージに延期する（6種サービスを選びできる），ファイナンシャルグレードな暗号化されたのクラウドストレージサービスを作ります。時間は指先に逆流するように、あなたがなんの素晴らしい瞬間を見逃しないです。",
    "循环存储": "サイクル　ストラージ",
    "无": "無し",
    "30天": "30日間",
    "15天": "15日間",
    "3天": "3日間",
    "报警推送": "アラム推薦",
    "100条": "100条",
    "无上限！": "上限なし",
    "视频时长": "ビデオ時間",
    "最长60秒": "最長６０秒",
    "报警声个性化": "アラーム声パーソナライズ",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "カメラアラーム/アナウンサー声、携帯電話の推薦着信メロディーを全部パーソナライズに設置できます。豊富なローカル着メロも準備しています。あなたも語音と文字を使てあなたがほしい声を録音できます。",
    "监测区域自定义": "監視エアーのカスタマイズ",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "一つ或いは多くの監視エアーを設置できて、あなたが気になるのエアーを捕まるだけ、不必要なアラームを減少できます；アラームがトリガーされる時、あなたが具体的なアラーム位置を知ってできます。",
    "AI智能通知(待开放)": "AIインテリジェントサービス通知（開放が待っている）",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "AI人工インテリジェント識別高級計算技術を使て、画面の重要内容を識別できる：ペット、車両、バッグ。この中の行動を分析してもっと精密、インテリジェントな情報を推薦できます。",
    "已了解，去购买": "了解済，購入へ行きます。",
    "已开通": "開通済",
    "10秒/每条": "10秒/毎条",
    "3天\n(100条/天，10秒/条) ": "３日\n（100条/日、１０秒/条）",
    "7/30天\n (消息/视频无上限)": "7/30日\n（情報/ビデオ上限なし）",
    "30天\n (消息/视频无上限)": "30日\n（情報/ビデオ上限なし）",
    "距离服务到期": "サービスの有効期限が切れるまで",
    "永久": "永遠",
    "体验": "体験",
    "监测区域": "監視エアー",
    "报警推送100条/天": "アラーム推進100条/日",
    "视频时长10秒": "ビデオ時間10秒",
    "7天云存储": "七日間のクラウドストレージ",
    "30天云存储": "30日間のクラウドストレージ",
    "1个月": "一か月",
    "6个月": "六か月",
    "12个月": "12か月",
    "循环存储7天（使用时间6个月）": "サイクル　ストラージ　七日間（使用時間6ヶ月）",
    "查看详情": "詳しい情報を見てできます",
    "安全": "安全",
    "便捷": "便利",
    "贴心": "気に入る",
    "云存储特权": "クラウドストレージ　特許",
    "云端保护": "クラウド上に保護",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "クラウド上にビデオストレージ、壊れていない、盗まれていない、\nデータを保護されているセキュリティです",
    "隐私加密": "プライバシー暗号化",
    "金融级数据加密方案，保护隐私更\n安全": "ファイナンシャルグレードなデータ暗号化方法、プライバシーを保護するためのはもっと安全です。",
    "存储灵活": "柔軟なストレージ",
    "同时保存报警录像和视频，不错过任\n    何动态": "アラームの記録とビデオを同時に保存し、タスクを見逃さない",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "税金と為替レート等原因なので，お支払い価格が\nアカウントのログイン国に基づいてわずかに変動があります。",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "インテリジェントサービスの機能はカメラ管理者権限でのみ有効になり、共有ユーザーはデバイスの管理者権限に従って使用します",
    "付款：确认购买并付款后记入iTunes 账户": "支払う：確認して購入して支払うした後iTunes アカウントに記入",
    " 去支付": "しはらうへ行きます",
    "7天    ": "七日間",
    "60天": "60日",
    "有效日期至": "有效日期至",
    "可升级": 'Upgradeable',
    "已到期": '期限切れ',
    '云视频录像':'クラウドビデオ録画',
    '关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长':'終了後、ビデオはクラウドに保存されず、現在のクラウドストレージサービスは延長されません。',
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "リアルタイムのビデオ画面が動的に変化すると、デバイスはそれを自動的に記録してクラウドストレージにアップロードします。 オーディオおよびビデオデータは、データのセキュリティを確保するためにファイナンシャルグレードの暗号化技術を採用しています。",
    "去支付": "支払いに行く",
    "天": "日",
    "首选": "優先",
    "超过80%人选择": "80%以上の人が選択",
    "存储周期": "ストレージサイクル",
    "订单": "オーダー",
    "去续费": "チャージバック",
    "云存储录像邀您试用": "クラウドストレージビデオをお試しください",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "1ヶ月間の無料試用期間があり、録画クラウドは紛失を恐れず、ビデオは7日間保存し、再生は簡単で便利です",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "日平均",
    "元": "RMB",
    "省": "Save",
    "推荐": "お待ちください",
    "激活": "アクティブ化",
    "支持": "サポート",
    "不支持": "サポートされていません",
    "年卡": "一年間購買",
    "半年卡": "半年間購買",
    "月卡": "一ヶ月間購買",
    "暂不支持，敬请期待": "未対応です、ご期待ください",
    "卡片暂不支持充值": "現時点では、カードのリロードはできません",
    "HI~有什么需要查询的吗？": "は~い何か調べたいことはありますか? ",
    "更多热门问题为你解答": "もっと話題の質問にお答えします",
    "热门分类": "人気カテゴリーです",
    "添加设备": "装置を追加します",
    "监控异常": "異常を監視します",
    "操作视频": " ビデオを操作します",
    "更多其他": "もっと他です",
    "常见问题": "よくある質問です",
    "在线客服": "オンラインカスタマーサービス",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}