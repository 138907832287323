import request from '@/api/request';

export function cardUrl(iccid, userid) {
    return request({
        url: 'https://order-api.eye4.cn/card/url/get',
        method: 'post',
        data: {
            iccid, userid, op: 1
        }
    })
}

/**
 * 查询卡信息
 * @param {String} iccId 卡号
 */
export function showCard(iccId) {
    return request({
        url: '/card/show',
        // url: 'https://api-dashboard.cam366.com/ad/inventoryToken',
        method: 'post',
        data:{
            iccId
        }
    })
}

/**
 * 查询设备绑定卡号
 * @param {String} did 设备ID
 */
export function searchCard(did) {
    return request({
        url: '/card/search',
        method: 'post',
        data:{
            did
        }
    })
}

/**
 * 查询设备绑定卡号
 * @param {String} did 设备ID
 */
export function searchDeviceCard(did) {
    return request({
        url: '/card/device/bind',
        method: 'post',
        data:{
            did
        }
    })
}

/**
 * 卡号绑定经销商
 * @param {String} iccId 卡号
 */
export function bindDealer(iccId) {
    return request({
        url: '/card/bind',
        method: 'post',
        data:{
            iccId
        }
    })
}

/**
 * 查询在用套餐
 * @param {String} iccId 卡号
 */
export function comboUsed(iccId) {
    return request({
        url: '/combo/used',
        method: 'post',
        data:{
            iccId
        }
    })
}
/**
 * 调试日志保存
 * @param {String} uid 用户ID
 * @param {String} iccId 卡号
 * @param {Object} content 打印具体内容
 */
export function storage(uid, iccId, content) {
    return request({
        url: '/debug/storage',
        method: 'post',
        data:{
            uid, iccId, content
        }
    })
}

/**
 * 查询经销商产品列表
 * @param {String} oemId 经销商ID
 * @param {String} platformId 平台ID
 * @param {String} iccId 卡号
 * @param {String} operator 卡类型 电信 CTCC；移动 CMCC；联通 CUCC
 */
export function productList(oemId, platformId, iccId, operator) {
    return request({
        url: '/product/list',
        method: 'post',
        data:{
            oemId, platformId, iccId, operator
        }
    })
}

/**
 * 刷新卡信息
 * @param {String} platformId 平台ID
 * @param {String} iccId 卡号
 */
export function refreshCard(platformId, iccId ) {
    return request({
        url: '/card/refresh',
        method: 'post',
        data:{platformId, iccId}
    })
}

/**
 * 创建订单
 * @param {String} oemId 经销商ID
 * @param {String} userId 用户ID
 * @param {String} iccId 卡号
 * @param {String} productId 产品ID
 */
export function createOrder(oemId, userId, iccId, productId) {
    return request({
        url: '/order/create',
        method: 'post',
        data:{oemId, userId, iccId, productId}
    })
}

/**
 * 订单列表
 * @param {String} oemId 经销商ID
 * @param {String} iccId 卡号
 */
export function orderList(oemId, iccId) {
    return request({
        url: '/order/list',
        method: 'post',
        data:{ oemId, iccId}
    })
}

/**
 * 查询订单
 * @param {String} orderId 订单ID
 * @param {String} language 地区语言,目前只支持zh-JT
 */
export function showOrder(orderId, language) {
    return request({
        url: '/order/show',
        method: 'post',
        data:{ orderId, language}
    })
}

/**
 * eyeCloud系统支付下单
 * @param {String} oemId 经销商ID
 * @param {String} scenes 支付场景；微信内或H5
 * @param {String} channel 渠道；微信或支付宝
 * @param {String} openid 用户微信授权token
 * @param {String} orderId 订单ID
 * @param {String} price 交易金额
 * @param {String} title 套餐名称
 * @param {String} return_url 支付成功跳转地址
 */
export function paymentEyeCloudCreate(oemId, scenes, channel, openid, orderId, price, title, return_url) {
    return request({
        url: '/payment/eyeCloud/create/' + oemId + '/' + scenes + '/' + channel,
        method: 'post',
        data:{openid, orderId, price, title, return_url}
    })
}

/**
 * eyeCloud系统支付签名
 * @param {String} oemId 经销商ID
 * @param {String} prepay_id 支付ID
 */
export function paymentEyeCloudSign(oemId, prepay_id) {
    return request({
        url: '/payment/eyeCloud/sign/' + oemId,
        method: 'post',
        data:{prepay_id}
    })
}

/**
 * beeCloud支付签名
 * @param {String} oemId 经销商ID
 * @param {String} orderId 订单ID
 * @param {String} title 套餐名称
 * @param {Number} amount 交易金额（分）
 */
export function paymentBeeCloudSign(oemId, orderId, title, amount) {
    return request({
        url: '/payment/beeCloud/sign',
        method: 'post',
        data:{oemId, orderId, title, amount,}
    })
}

/**
 * 获取签约数据
 * @param {String} customerId 商户ID，必选；
 * @param {String} userId 用户ID，必选；
 * @param {String} iccId 设备卡号，必选；
 * @param {String} productId 产品ID，必选；
 * @param {String} price 产品价格，单位：元，必选；
 * @param {String} title 产品名称，必选；
 * @param {String} period 产品周期，必选；
 * @param {String} return_url 签约成功后跳转地址，必选；
 */
export function agreement({customerId, userId, iccId, productId, price, title, period, return_url}) {
    return request({
        url: '/agreement/sign',
        method: 'post',
        data:{
            customerId,
            userId,
            iccId,
            productId,
            price,
            title,
            period,
            "period_type": "DAY",
            return_url,
        }
    })
}
/**
 * 获取签约数据
 * @param {String} iccId 卡id，必选；
 */
export function showSigned(iccId) {
    return request({
        url: '/agreement/showSigned',
        method: 'post',
        data:{iccId}
    })
}

/**
 * 获取签约数据
 * @param {String} iccId 卡id，必选；
 * @param {String} productId 产品Id，必选；
 */
export function showPurchased(iccId,productId) {
    return request({
        url: '/order/automatic',
        method: 'post',
        data:{iccId,productId}
    })
}

/**
 * 反馈充值问题
 * @param {String} userId 用户ID
 * @param {String} iccId 卡号
 * @param {String} orderId 订单ID
 * @param {String} description 问题描述
 */
export function addIssue(userId, iccId, orderId, description) {
    return request({
        url: '/issue/add',
        method: 'post',
        data:{userId, iccId, orderId, description}
    })
}
