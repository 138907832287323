import {defineStore} from 'pinia'
import {defaultParamsType, FourGFlowModuleType, PersistStrategy, renderedParamsType} from '/#/store'
import {WebViewBridge} from "@/utils/bridge.js";
import {bindDealer, comboUsed, productList, showCard, storage,} from "@/api/index.js"
import {
    setLocalParam,
    getComboUnit,
    unlimitedComboTotal,
    productCategory,
    getLocalParam,
} from '@/utils/tool.js'
import moment from 'dayjs'
import {useChoosePlanStore} from "@/store/modules/ChoosePlan";

const webViewBridge = new WebViewBridge()

export const useFourGFlowStore = defineStore('FourGFlowStore', {
    state: (): FourGFlowModuleType => ({
        currentCardMsg: {
            cardId: '8208208820',
            ICCID: "898984568515671",
            settlementDate: "2021-11-27",
            status: "正常使用",
            currentComboPrice: 99,
            currentComboDuration: "半年",
            residualFlow: "2.98",
            totalFlow: "3.00",
            appName: ''
        },
        defaultParams: useDefaultParamsStore(),
        renderedParams: useRenderedParamsStore(),
        webViewBridge: null,
    }),
    getters: {},
    actions: {
        async initBridge() {
            this.webViewBridge = webViewBridge
            return this.webViewBridge.init().then(() => {
                console.warn("bridgeJs init success")
                this.webViewBridge.callHandler('getFlowPageColor', "", (data: string) => {
                    //获取导航栏颜色
                    this.defaultParams.skin = data.split("0xff")[1].slice(0, -2)
                    console.log("获取颜色", this.defaultParams.skin)
                });
                this.webViewBridge.registerWindowEvent(() => {
                    const skin = this.defaultParams.skin
                    console.warn("设置默认颜色是", skin)
                    //设置导航栏颜色
                    this.webViewBridge.setNavColor(skin)
                })

                this.webViewBridge.callHandler('getOEMID', "", (data: string) => {
                    //获取客户端编号
                    if(data === 'OKAM') this.currentCardMsg.appName = "小鹰看看（O-KAM）"
                    else if(data === 'AKCXKJ') this.currentCardMsg.appName = "FOWL "
                });

                //通知APP当前是首页
                this.webViewBridge.setNavRightBut()
                //设备信号强度
                this.webViewBridge.deviceSignalStrength(this.defaultParams.iccId, (signalStrength: string) => {
                    console.warn('首页获取到信号值--->', signalStrength)
                    this.renderedParams.signalStrength = signalStrength
                })
                this.webViewBridge.callHandler('getLanguage', "", (responseData: string) => {
                    //查询系统语言
                    this.defaultParams.language = responseData.split('-')[0]
                    console.warn("App啥语言呀:", responseData, this.defaultParams.language)
                    localStorage.setItem("language", this.defaultParams.language)
                })

                return true
            }).catch(() => {
                console.warn("PC端")
                this.defaultParams.scenes = "H5"
            })
        },
    },
    persist: {
        enabled: true,
        strategies: [{
            storage: localStorage,
            paths: ["webViewBridge"]
        }]
    }
})

export const useDefaultParamsStore = defineStore('defaultParamsStore', {
    state: (): defaultParamsType => ({
        H5pay: '0',
        alarmLevel: '0',
        iccId: '',
        language: 'zh-JT',
        oemId: 'OEM',
        openid: 'null',
        operator: '',
        platformId: '',
        rgb: '[254,191,14]',
        scenes: 'APP',
        sim: '',
        skin: '52BFF5',
        userId: 'system',
        dealer: ''
    }),
    actions: {
        //映射缓存参数
        mapCacheParameters() {
            //读取缓存业务参数
            const cache = this.getLocalParam('business')
            if (cache) {
                const keys = Object.keys(cache)
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i]
                    // @ts-ignore
                    this[key] = cache[key]
                    console.log("key", key)
                }
            }
        },
        //查询本地参数
        getLocalParam(key: string) {
            let json
            let str = localStorage.getItem(key)
            if (!str) str = '{}'
            try {
                json = JSON.parse(str)
            } catch (e) {
                console.warn('查询本地参数异常，数据不是json')
                json = {}
            }
            return json ? json : {}
        },
        //映射URL参数
        mapURLParameters(opt: {}) {
            const keys = Object.keys(opt)
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i]
                // @ts-ignore
                this[key] = opt[key]
            }
        },
        //映射渲染参数
        mapRenderParameters(opt: {}) {
            const keys = Object.keys(opt)
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i]
                // @ts-ignore
                this[key] = opt[key]
            }
        },
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage
            }
        ]
    }
})

export const useRenderedParamsStore = defineStore('renderedParamsStore', {
    state: (): renderedParamsType => ({
        isNav: true,  //导航栏控制,默认显示
        isNormal: true,  //正常显示
        isUnlimited: true,  //包看套餐控制
        isUnlimitedUnit: false,  //包看计数器
        loading: true,  //刷新加载动画
        isExpired: false,  //套餐是否过期
        description: '暂不支持，敬请期待',  //文本描述
        isActive: 'unlimited_month',  //tab切换控制
        signalStrength: '5',  //设备卡号信号强度，信号值 0-1-2-3-4-5 强度递增，依次对应图片
        auto: '1',  //手动输入控制
        home: {iccId: ''},  //首页参数
        info: {},  //卡号信息
        product: {},  //产品列表
        operatorNickname: '-',  //运营商别称
        status: '正常使用',  //卡号状态
        statusColor: '#FFFFFF',  //卡号状态颜色
        expired: '-',  //套餐有效期
        used: '-',  //当前套餐
        useId: '1999',  //当前使用套餐产品ID
        total: '0.0',  //总量
        surplus: '0.0',  //余额
        surplusColor: '#000000',  //剩余颜色值
        surplusUnit: 'G',  //剩余单位
        unlimitedList: [],  //包看套餐列表
        yearList: [],  //年套餐列表
        monthList: [],  //月套餐列表
        mixList: [],  //月、年套餐混合列表
        autoSignList: [], // 自动续费套餐列表
        haveAutoRenewal: false,
        showDetection: false, // 智能检测 功能(I001 才有)
    }),
    getters: {},
    actions: {
        setIsNav(isShow: boolean) {
            this.isNav = isShow
        },
        //查询卡信息
        async showCardInfo() {
            let {defaultParams: required} = useFourGFlowStore()
            const iccId = required.iccId
            //查询设备信息(注销卡-提示：运营商已注销卡号，已不能充值)
            let data = await showCard(iccId)
            if (!data || (data.code === 404 && iccId.length === 19)) this.unSupport('卡片暂不支持充值'/* + iccId*/)
            // 为了兼容卡号是移动或者电信（有存在21位的卡号也有存在19位的卡号，所以app解析出来可能会在19位上多几位）
            else if (data.code === 404 && iccId.length !== 19) {
                required.iccId = iccId.substring(0, 19)
                await this.showCardInfo()
            } else {
                await this.reloadCard(data)
            }
        },
        //加载卡数据
        async reloadCard(info: any) {
            let {defaultParams: required} = useFourGFlowStore()
            //缓存卡信息
            this.info = info || {}
            const {platformId, operator, dealer, packageName, cState} = info
            required.platformId = platformId
            required.operator = operator
            required.dealer = dealer
            console.log("卡信息：", info);

            //校验卡状态,注销卡拒绝充值
            if (cState === '运营商注销' || cState === '维护中' || cState === '注销期' || cState === '已销户') {
                return this.unSupport('运营商强已制注销卡号，拒绝注销卡充值，请使用私人卡')
            }

            if (platformId === 'I002' && (packageName === null || packageName === 'null' || !packageName)) {
                return this.unSupport('您的卡已超过1个月未充值，已不能充值')
            }

            if (dealer === 'default') {
                const res = await bindDealer(required.iccId)
                console.log("res:", res);
            }

            //渲染卡信息
            await this.renderCardInfo(info)
            //查询产品列表,并缓存产品列表，确保查询套餐可以查询到对应套餐
            await this.showProductList()
            //查询当前套餐，新卡显示默认套餐
            await this.showComboUsed(info)
            //设置必要参数
            this.setRequired()
        },
        //渲染卡信息
        async renderCardInfo(info: any) {
            let {defaultParams: required} = useFourGFlowStore()
            // 运营商标识 号码 总流量 剩余流量 有效期 卡状态 报警单位系数
            let {operator, msisdn, tPFlow, lPFlow, invDate, cState} = info
            // 报警等级
            let alarmValve = required.alarmLevel === '1' ? 0.5 : 1
            //总额
            this.total = parseFloat(String(tPFlow / 1024)).toFixed(2)
            //余额
            this.surplus = parseFloat(String(lPFlow / 1024)).toFixed(2)
            //有效日期检测
            if (!invDate || invDate === 'null' || invDate === '') invDate = '未激活'
            //日期规则
            const reg = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\s+(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/
            const regExp = new RegExp(reg)
            //日期检测
            if (regExp.test(invDate)) {
                let now = moment().format('YYYY-MM-DD HH:mm:ss')
                let end = invDate + ' 23:59:59'
                //套餐是否过期（判断条件一）
                this.isExpired = moment(now) > moment(end)
            }

            //剩余流量检测
            let flow = Number(this.surplus)
            if (flow <= 0) {         //流量已用完
                let text = '停止使用'
                if (cState === '待激活' || cState === '可激活' || cState === '可测试') text = '待激活'
                else if (cState === '已锁定' || cState === '正常') text = cState
                this.surplusColor = '#D9031B'
                this.surplusUnit = 'G'        //流量单位
                this.status = text        //卡状态值
                this.statusColor = '#FF0000'        //卡状态颜色值
            } else if (0 < flow && flow < alarmValve) {        //流量介于告警值，直接使用元数据
                this.surplus = (lPFlow - 0).toFixed(2)        //流量告警
                this.surplusColor = '#F49000'
                this.surplusUnit = 'M'        //流量单位
                this.status = '余额告警'        //卡状态值
                this.statusColor = '#ffb23a'        //卡状态颜色值
            } else {
                this.surplusColor = '#255BAB'        //流量充裕
                this.surplusUnit = 'G'        //流量单位
                this.status = '正常使用'        //卡状态值
                this.statusColor = '#FFFFFF'        //卡状态颜色值
            }

            //运营商参数
            let operatorValue = '-'
            switch (operator) {
                case 'CTCC':
                    operatorValue = '中国电信'
                    break;
                case 'CMCC':
                    operatorValue = '中国移动'
                    break;
                case 'CUCC':
                    operatorValue = '中国联通'
                    break;
            }

            //运营商标记
            required.operator = operator
            //运营商别称显示
            this.operatorNickname = operatorValue
            //卡号显示
            required.sim = msisdn
        },
        //渲染产品
        renderProduct() {
            let {defaultParams: required} = useFourGFlowStore()
            //非松果平台，不显示包看套餐
            if (required.platformId === 'I001') {
                this.showDetection = true // I001 才有智能检测 功能
                //判断是定向卡还是公网卡
                if (this.checkOrientation()) {
                    this.isUnlimited = true
                    this.isActive = 'unlimited_month'
                } else {
                    this.isUnlimited = false
                    this.isActive = 'plus_year'
                }
            } else if (required.platformId === 'I004') {
                this.isUnlimited = true
                this.isActive = 'unlimited_month'
            } else {
                this.isUnlimited = false
                this.isActive = 'plus_year'
            }
        },
        //查询产品列表
        async showProductList() {
            let {defaultParams: required} = useFourGFlowStore()
            const {userId, oemId, platformId, iccId, operator} = required
            const data = await productList(oemId, platformId, iccId, operator)
            console.log("productList===", data);
            if (!data) return this.unSupport('产品未上架，暂不支持充值' + required.iccId)
            this.isNormal = true
            let myList: any = {}
            data.forEach((item: any) => {
                if (myList[item.group]) myList[item.group].push(item)
                else myList[item.group] = [item]
            })
            console.log("分类后套餐", myList)
            const list = myList
            console.warn('产品列表', list)
            if (list && Object.keys(list).length > 0) {
                //缓存产品
                this.product = list
                setLocalParam('product', list)
                //合并混合产品
                this.mixProduct(list)
            }
            return true
        },
        mixProduct(list: any) {
            this.mixList = []
            const monthKey = 'basis_month'
            const yearKey = 'plus_year'
            const monthList = list[monthKey]
            const yearList = list[yearKey]

            if (monthList) {
                for (let i = 0; i < monthList.length; i++) {
                    const item1: any = monthList[i]
                    const remark = item1['remark']
                    if (remark.indexOf('3G月主套餐') !== -1) this.mixList.push(item1)
                }
            }

            if (yearList) {
                for (let j = 0; j < yearList.length; j++) {
                    const item2 = yearList[j]
                    const remark = item2['remark']
                    if (remark.indexOf('120G年主套餐') === -1) this.mixList.push(item2)
                }
            }
        },
        //查询在用套餐
        async showComboUsed(card: any) {
            let {defaultParams: required} = useFourGFlowStore()
            const cState = card['cState']
            const {iccId, platformId, operator} = required
            let comboUnit = '月'
            let invDate = this.info ? this.info['invDate'] : 'null'
            //套餐有效日期
            if (invDate && invDate !== 'null' && invDate !== '') this.expired = moment(invDate).format('YYYY-MM-DD')
            console.log("showComboUsed iccId ==== ", iccId);
            const data = await comboUsed(iccId)
            let {isOpen, info} = data
            console.log("comboUsed:-----", data, isOpen)
            if (!isOpen) {
                if (platformId === 'I001' && this.checkOrientation()) return this.setOrientationCombo()//定向卡默认套餐设置
                if (platformId === 'I002') {
                    //云杉平台；默认显示1G/季
                    comboUnit = '季'
                } else if (platformId === 'I003') {
                    //优为尔平台；默认显示1G/季
                    comboUnit = '季'
                } else if (platformId === 'I004') {
                    //松果平台；默认显示1G/月
                    comboUnit = '月'
                } else if (platformId === 'I001' && operator !== 'CMCC') {
                    //中亿平台；移动-默认显示1G/月；电信-默认显示1G/季
                    comboUnit = '季'
                }
                //当前套餐
                this.used = '5元/' + comboUnit
                return
            }
            console.warn('已用套餐', info)
            const {id, group, price, mode, expirationTime} = info
            //已开通套餐ID 套餐组 套餐价格 套餐类型 套餐有效期
            this.useId = id
            const start = expirationTime + ' 00:00:00'
            const now = moment().format('YYYY-MM-DD') + ' 00:00:00'
            const startUnix = moment(start).valueOf()
            const endUnix = moment(now).valueOf()
            const diff = moment(endUnix).diff(moment(startUnix), 'days')

            //云杉卡处理，超过30天拒绝充值
            if (platformId === 'I002' && diff >= 30) {
                return this.unSupport('您的卡已超过1个月未充值，已不能充值')
            }
            if (platformId !== 'I003') this.expired = moment(expirationTime).format('YYYY-MM-DD')
            //换算套餐单位
            comboUnit = getComboUnit(mode)
            //当前套餐
            if (group === 'unlimited_month' || group === "automatic_renewal") {
                const total = unlimitedComboTotal(group, this.useId)
                this.isUnlimitedUnit = true
                this.used = total + '天包看'
                this.total = total
                this.setOrientationTimeColor(cState, diff)
            } else {
                console.warn("非限制套餐", this.expired)
                this.used = String(price) + '元/' + comboUnit
            }
        },
        //设置定向卡默认套餐显示
        setOrientationCombo() {
            const info = this.info
            if (!info) return false
            const cState = info['cState']
            const metadata = info['Metadata']
            let metadataJson
            try {
                metadataJson = JSON.parse(metadata)
            } catch (e) {
                return false
            }
            const {createTime, expiredTime, activeTime, packageList} = metadataJson
            const packageListJson = JSON.parse(packageList)
            const now = moment().format('YYYY-MM-DD') + ' 00:00:00'
            const nowUnix = moment(now).valueOf()
            const total = '180'
            const expired = moment(createTime).add(Number(total), 'days').format('YYYY-MM-DD')
            const endUnix = moment(expired).valueOf()
            const diff = moment(endUnix).diff(moment(nowUnix), 'days')
            if (expiredTime) {
                console.warn('存在过期时间', packageListJson, typeof (packageListJson))
                this.used = '体验流量'
                this.isUnlimitedUnit = true
                let targetTotal = 7, targetSurplus = 0
                const filterPackage = packageListJson.filter((item: any) => [0, 1, 2].includes(item.status))
                console.log("过滤正常状态后：", filterPackage)
                if (filterPackage.length) {
                    let inEffectPackage = filterPackage.filter((item: any) => item.status === 1)
                    let notActivePackage = filterPackage.filter((item: any) => item.status === 0)
                    let expiredPackage = filterPackage.filter((item: any) => item.status === 2)
                    let targetPackage
                    if (inEffectPackage) {
                        targetPackage = inEffectPackage.find((item: any) => item.packageName.includes('2G'))
                            || inEffectPackage.find((item: any) => item.packageName.includes('3G'))
                            || inEffectPackage.find((item: any) => item.packageName.includes('500M'))
                        if (!targetPackage && inEffectPackage.length===0) targetSurplus = 0
                        else if(!targetPackage && inEffectPackage.length>0){
                            let name = inEffectPackage[0].packageName
                            switch (name){
                                case '季度无限流量套餐':
                                    targetTotal = 90
                                    break
                                case '半年无限流量套餐':
                                    targetTotal = 180
                                    break
                                case '一年无限流量套餐':
                                    targetTotal = 360
                                    break
                                case '两年无限流量套餐':
                                    targetTotal = 720
                                    break
                                default:
                                    targetTotal = 0
                            }
                            this.used = targetTotal!==0 ? (targetTotal + '天包看') : '体验流量'
                            const expireTime = moment(inEffectPackage[0].expireTime, 'x').format('YYYY-MM-DD HH:mm:ss');
                            targetSurplus = moment(expireTime).diff(moment(), "day")

                        }else{
                            const {expireTime, effectTime} = targetPackage
                            targetSurplus = moment(expireTime).diff(moment(), "day")
                        }
                    } else if (notActivePackage) {
                        targetSurplus = 7
                    } else {
                        const {expireTime, effectTime} = expiredPackage
                        targetSurplus = 0
                    }
                    // 去除流量多的
                } else {
                    this.expired = moment().format('YYYY-MM-DD')
                    this.used = '未订购'
                    targetSurplus = 0
                }
                this.total = targetTotal.toString()
                this.surplus = targetSurplus.toString()
            } else {
                console.warn('无过期时间', metadataJson)
                this.used = '待激活'
                this.isUnlimitedUnit = true
                this.expired = expired
                this.surplus = String(0)
                this.total = '0'
                this.setOrientationTimeColor(cState, 0)
            }
        },
        //设置定向卡计时颜色
        setOrientationTimeColor(cState: any, diff: any) {
            if (cState === '正使用' || cState === '可测试' || cState === '已激活' || cState === '待激活' || cState === '正常') {
                this.surplus = String(Math.abs(diff))
                //剩余值颜色
                this.surplusColor = '#255BAB'
                //卡状态值
                this.status = '正常使用'
                //卡状态颜色值
                this.statusColor = '#FFFFFF'
            } else {
                this.surplus = '0'
                //剩余值颜色
                this.surplusColor = '#D9031B'
                //卡状态值
                this.status = '停止使用'
                //卡状态颜色值
                this.statusColor = '#FF0000'
            }
        },
        //显示套餐组
        async showComboList(name: any) {
            //查询产品列表
            const productList = getLocalParam('product')
            //查询套餐组
            const comboGroup = productList ? productList[name] : []
            console.warn('套餐组', name, comboGroup)
            switch (name) {
                case 'unlimited_month':
                    this.unlimitedList = comboGroup
                    break;
                case 'plus_year':
                    this.yearList = comboGroup
                    break;
                case 'basis_month':
                    this.monthList = comboGroup
                    break;
            }
            // 判断是否定向卡，产品列表中添加自动签约选项(仅第一项)
            console.log("是否是定向卡", this.checkOrientation())
            if (this.checkOrientation()) {
                const choosePlanStore = useChoosePlanStore()
                let {defaultParams: required} = useFourGFlowStore()
                const autoSingItem = productList["automatic_renewal"][0]
                this.unlimitedList.unshift(autoSingItem)
                this.mixList.unshift(autoSingItem)
                this.haveAutoRenewal = true
                await choosePlanStore.showSigned(required.iccId)
                console.warn('新套餐组', this.unlimitedList)
            } else this.haveAutoRenewal = false
        },
        //检测定向卡,默认非定向卡
        checkOrientation() {
            let isOrientation = false
            if (!this.info) return isOrientation
            const metadata = this.info['Metadata']
            if (!metadata) return isOrientation
            let json
            try {
                json = JSON.parse(metadata)
            } catch (e) {
                console.error('定向卡检测数据不是json', e)
                return isOrientation
            }
            let tag = json['tag']
            if (tag && tag !== '') isOrientation = true
            return isOrientation
        },
        // 界面警告
        unSupport(description: string) {
            console.log("unSupport!==========")
            this.isNormal = false
            this.description = description
            throw new Error(description)
        },
        //设置必要参数
        setRequired() {
            let {defaultParams: required} = useFourGFlowStore()
            const {
                H5pay, alarmLevel, iccId, language, oemId,
                openid, operator, platformId, rgb, scenes, sim, skin, userId,
            } = required
            //必要参数
            const all = {
                H5pay, alarmLevel, iccId, language, oemId,
                openid, operator, platformId, rgb, scenes, sim, skin, userId
            }
            console.warn('存储的参数', all, required)
            //设置必要参数
            setLocalParam('business', all)
            //记录参数
            storage(userId, iccId, JSON.stringify(all))
            return all
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage,
                paths: [
                    "isNav",
                    "unlimitedList",
                    "yearList",
                    "monthList",
                    "mixList",
                    "autoSignList",
                    "haveAutoRenewal",
                ],
            }
        ]
    }
})