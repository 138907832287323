import {defineStore} from "pinia";
import {FAQType, FAQTransferType} from "/#/store";
import router from '@/router'
import {WebViewBridge} from '@/utils/bridge.js'
import {ElMessage} from 'element-plus'
import {
    addCollectionFrom,
    addComment,
    detailsContent,
    getProblemList,
    getTitleList,
    problemDiscussion,
    updateState
} from "@/api/FAQ";
import socketIo from 'socket.io-client'
import {ElMessageBox, ElNotification} from "element-plus";

const webViewBridge = new WebViewBridge()

export const useFAQStore = defineStore('FAQStore', {
    state: (): FAQType => ({
        titleList: [],
        titleObj: {},
        activeList: [],
        activeTitle: '猜你想问',
        problemsList: [],
        currentProblemsList: [],
        answerListObj: {},
        answerContentObj: {},
        webViewBridge: null,
        showCustomerService: false,
        showQuickService: false,
        authkey: '',
        submitted: false,
        from: {
            userInfo: {
                account: null,
                did: null,
                updateLink: [],
            },
            actionInfo: {
                language: 'zh',
                titleId: '',
                topicInfo: {},
                topicContent: {},
            },
            replyMethod: {
                phone: null,
                email: null,
                appPush: null,
                onlineService: null,
            }
        }
    }),
    getters: {},
    actions: {
        async initBridge(isNewApp = false) {
            this.webViewBridge = webViewBridge
            let bridgeInfo = await this.webViewBridge.init().catch(() => false)
            console.warn("bridgeInfo:", bridgeInfo)
            if (bridgeInfo) {
                this.webViewBridge.callHandler('getUserid', "", (res: any) => {
                    console.log("userid is:", res)
                    this.from.userInfo.account = res
                })
                if (isNewApp) {
                    console.log('新版APP分支')
                    this.webViewBridge.callHandler('showQuickService', "", (res: any) => {
                        console.log("showQuickService is:", res)
                        this.showQuickService = res === '1'
                    })
                } else {
                    this.webViewBridge.callHandler('showCustomerService', "", (res: any) => {
                        console.log("showCustomerService is:", res)
                        this.showCustomerService = res === '1'
                    })
                }
                this.webViewBridge.callHandler('getLanguage', "", (res: any) => {
                    console.log("language is:", res)
                    if (['zh', 'en', 'pl', 'tr'].indexOf(res) === -1) this.from.actionInfo.language = 'en'
                    else this.from.actionInfo.language = res
                    localStorage.setItem('language', this.from.actionInfo.language)
                })
                this.webViewBridge.callHandler('getDeviceId', "", (res: any) => {
                    console.log("deviceId is:", res)
                })
                this.webViewBridge.callHandler('getAuthkey', "", (data: any) => {
                    this.authkey = data
                    console.warn("getAuthkey", data)
                });
                console.warn("初始化成功")
                return true
            } else {
                console.warn("PC端 bridgeInfo初始化失败")
                return false
            }
        },
        async fetchTitleList() {
            const params = {activeType: true} // 默认获取猜你想问数据
            const {data} = await getTitleList(params)
            this.titleObj["猜你想问"] = data
            // this.titleList = data
            // this.titleList.forEach((item: any) => this.activeList.push(item._id))
        },
        async fetchTypeTitleList(title: string) {
            let value
            switch (title) {
                case "添加设备":
                    value = '1'
                    break
                case "监控异常":
                    value = '2'
                    break
                case "操作视频":
                    value = '3'
                    break
                case "报警功能":
                    value = '4'
                    break
                case "云存储":
                    value = '5'
                    break
                case "TF卡录像":
                    value = '6'
                    break
                case "其他":
                    value = '7'
                    break
            }
            if (title === '猜你想问') {
                const params = {activeType: true} // 默认获取猜你想问数据
                const {data} = await getTitleList(params)
                this.titleObj["猜你想问"] = data
            } else {
                const params = {belongType: value} // 默认获取猜你想问数据
                const {data} = await getTitleList(params)
                this.titleObj[title] = data
            }
        },
        // 获取问题列表
        async getNextProblemList(id: string) {
            const {language} = this.from.actionInfo
            const rep = {
                language,
                titleId: id,
                belongId: ''
            }
            const {data} = await getProblemList(rep)
            if (!data || (data && !data.length)) return false
            this.from.actionInfo.titleId = id
            this.problemsList = data
            this.getInitProblem()
            return true
        },
        // 设置父级标题下主干问题
        getInitProblem() {
            this.currentProblemsList.splice(0, this.currentProblemsList.length)
            this.currentProblemsList.push(
                ...this.problemsList.filter((item: any) =>
                    item.belongId === ''
                )
            )
        },
        // 处理点击下一步后的逻辑
        async nextProblem() {
            console.warn("下一步")
            const answerListObj_ValueList: any = []
            const answerListObj_KeyList = Object.keys(this.answerListObj)
            const answerContentObj_KeyList = Object.keys(this.answerContentObj)
            // 查询选中答案对应的下级问题id
            for (let key in this.answerListObj) {
                const optionIdList = this.answerListObj[key]
                answerListObj_ValueList.push(...optionIdList)
            }
            // 将选择后的结果追加到提交表单中，并将选中对象进行初始化重置
            this.from.actionInfo.topicInfo = {...this.from.actionInfo.topicInfo, ...this.answerListObj}
            this.answerListObj = {}
            // 判断是否需要把输入内容记录到表单中
            answerContentObj_KeyList.forEach((item: any) => {
                // 只有对应被选中问题的id才会记录到提交表单中
                if (answerListObj_KeyList.includes(item)) {
                    this.from.actionInfo.topicContent[item] = this.answerContentObj[item]
                } else {
                    // 防止后悔提交时遗留的脏数据存在
                    delete this.from.actionInfo.topicContent[item]
                }
            })
            // 获取下一个问题们的列表对象
            const nextProblemsList = this.problemsList.filter(
                (item: any) => answerListObj_ValueList.includes(item.optionsMark)
            )
            // 判断是否为最后一个问题了，若非最后一问，则替换当前问题列表
            if (nextProblemsList.length) this.currentProblemsList = nextProblemsList
            else await router.push({path: '/FAQ/chooseReply'})
        },
        // 处理点击上一步后的逻辑
        lastProblem() {
            let belongIdList: any = new Set()
            // 获取所有当前问题所属上级们的id,并进行去重处理
            this.currentProblemsList.forEach((item: any) => belongIdList.add(item.belongId))
            belongIdList = Array.from(belongIdList)
            // 查询所有父级id的问题信息列表对象
            this.currentProblemsList = this.problemsList.filter((item: any) => belongIdList.includes(item._id))
            // 初始化重置选中对象
            this.answerListObj = {}
            // 保持问题已选中项，以及保持已填写信息内容
            belongIdList.forEach((item: any) => {
                if (item in this.from.actionInfo.topicInfo) {
                    this.answerListObj[item] = this.from.actionInfo.topicInfo[item]
                }
                if (item in this.from.actionInfo.topicContent) {
                    this.answerContentObj[item] = this.from.actionInfo.topicInfo[item]
                }
            })
        },
        // 提交信息
        async submit() {
            console.log("发送啦：", this.from)
            const data = this.from
            console.log("data", data);
            const {data: {_id}} = await addCollectionFrom(data)
            // if (this.from.replyMethod.onlineService) {
            //   await ElMessageBox.alert('客服留言链接已生成，点击复制按钮，' +
            //     '打开浏览器输入粘贴链接即可进入在线客服服务', '在线客服留言', {
            //     confirmButtonText: '复制',
            //     callback: (action: any) => {
            //       let aux = document.createElement("input");
            //       const url = "https://webapp.eye4.cn/FAQ/informationTransfer?id=" + _id
            //       aux.setAttribute("value", url);
            //       document.body.appendChild(aux);
            //       aux.select();
            //       document.execCommand("copy");
            //       document.body.removeChild(aux);
            //       ElMessage({
            //         type: 'success',
            //         message: '复制成功！',
            //       })
            //       router.push({path: '/FAQ', replace: true})
            //     },
            //   })
            // } else {
            //   ElMessage({
            //     message: '提交成功！',
            //     type: 'success',
            //   })
            //   await router.push({path: '/FAQ', replace: true})
            // }
            ElMessage({
                message: '提交成功！',
                type: 'success',
            })
            await router.push({path: '/FAQ', replace: true})
            this.submitted = true
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage
            }
        ]
    }
})

let socketObj: any = null

export const useFAQTransferStore = defineStore('FAQTransferStore', {
    state: (): FAQTransferType => ({
        answerContent: '',
        detailsAnswer: {},
        questionList: [],
        title: {},
        correspondenceData: [],
        discussionList: [],
        account: '',
        questionStatus: '0',
        questionId: '',
        markContent: '',
        isShowMark: false,
    }),
    actions: {
        // 解析数据树结构
        async fetchTreeDate() {
            const data = {id: this.questionId}
            // 获取已经完成回答的数据记录
            const {data: {actionInfo, account, state: status}} = await detailsContent(data)
            if (!actionInfo) return
            const {language, titleId, topicInfo} = actionInfo
            const parameter = {
                language,
                titleId,
                belongId: ''
            }
            // 获取问题列表、标题列表元数据
            const {data: questionList} = await getProblemList(parameter)
            const {data: titleList} = await getTitleList({})
            // 过滤标题名称
            this.title = titleList.find((item: any) => item._id === titleId)
            // 保留数据
            this.questionStatus = status
            this.account = account
            this.questionList = questionList
            this.detailsAnswer = topicInfo
            console.log(this.questionList, this.detailsAnswer, "res")
            // processTree()
            this.processCorrespondence()
            await this.fetchDiscussionDate()
        },
        // 新数据格式的处理
        processCorrespondence() {
            // 获取已被回答的问题id列表
            const questionList_key = Object.keys(this.detailsAnswer)
            let topQuestion
            // 查找列表中首级问题
            for (const key of questionList_key) {
                topQuestion = this.questionList.find((question: any) =>
                    question._id === key && !question.belongId
                )
                if (topQuestion) break
            }
            const {remark, topic, options: optionsList, _id} = topQuestion
            // 过滤首级问题的选项，将被选中选项保留
            const options = optionsList.filter((item: any) => this.detailsAnswer[_id].includes(item.mark))
            // 存储新数据
            this.correspondenceData.push({
                topic, remark, options
            })
            // 将选项mark进入递归，查询紧跟着的子问题，防止多选情况
            options.forEach((item: any) => this.findNextCorrespondence(item.mark))
        },
        // 数据处理
        findNextCorrespondence(mark: string) {
            // 筛选mark 对应的问题
            const questionItem = this.questionList.find((item: any) => item.optionsMark === mark)
            // 若问题查找不到则说明已经到最后一问
            if (!questionItem) return
            const {remark, topic, options: optionsList, _id} = questionItem
            // 过滤问题的选项，将被选中选项保留
            const options = optionsList.filter((item: any) => this.detailsAnswer[_id].includes(item.mark))
            // 存储新数据
            this.correspondenceData.push({
                topic, remark, options
            })
            // 将选项mark进入递归，查询紧跟着的子问题，防止多选情况
            options.forEach((item: any) => this.findNextCorrespondence(item.mark))
        },
        // 获取评论内容
        async fetchDiscussionDate() {
            const data = {feedbackId: this.questionId}
            const {data: list} = await problemDiscussion(data)
            this.discussionList = list.reverse()
            console.warn("res:", list)
        },
        // 提交提问内容
        async onSubmit() {
            const {account, answerContent: comment, questionId: feedbackId} = this
            const data = {feedbackId, account, comment}
            const {data: newDiscussion} = await addComment(data)
            newDiscussion.reply = []
            this.discussionList.push(newDiscussion)
            const {_id: commentId} = newDiscussion
            this.socketEmit('discuss', {
                questionId: feedbackId,
                commentId,
                comment,
            })
            ElMessage({
                message: '提交成功！',
                type: 'success',
            })
            // await fetchDiscussionDate()
            this.answerContent = ''
        },
        // 触发问题已解决按钮
        onResolved() {
            ElMessageBox.confirm('请确认是否已经解决问题，若提交已解决问题，将不再可输入提问。', '温馨提示',
                {confirmButtonText: '确定', cancelButtonText: '取消'}
            ).then(async () => {
                const {questionId} = this
                const data = {id: questionId, state: '3'}
                const res = await updateState(data)
                console.warn(res)
                this.questionStatus = '3'
                this.socketEmit('closure', {questionId})
            })
                .catch(() => {
                    // catch error
                })
        },
        getSocketUrl() {
            return process.env.NODE_ENV === 'production' ?
                'https://api-dashboard.cam366.com/FAQSocket' : 'http://118.31.38.60:13233/FAQSocket'
        },
        initSocket() {
            socketObj = socketIo(this.getSocketUrl())
            socketObj.on('disconnect', (data: any) => {
                console.warn("disconnect:", data)
            })
            socketObj.on('loginRoom', (data: any) => {
                const {msg} = data
                if (msg === 'OK') this.socketEmit('loginRoom_discussion', {
                    questionId: this.questionId,
                    role: 'client'
                })
                console.warn("loginRoom:", data)
            })
            socketObj.on('loginRoomSuccess', (data: any) => {
                console.warn("loginRoomSuccess:", data)
            })
            socketObj.on('discussChange', (data: any) => {
                console.warn("discussChange:", data)
                this.discussionList.at(-1).reply.push(data)
                ElNotification({
                    title: '新消息!',
                    message: '客服已回复,请下拉查看！',
                    type: 'warning',
                    duration: 5000
                })
            })
        },
        socketEmit(eventName: string, data: any) {
            socketObj.emit(eventName, data)
        },
        // 展示选中问题后的详情
        showMark(content: string | any) {
            if (!content) return
            this.markContent = content
            this.isShowMark = true
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage
            }
        ]
        // enabled: true,
        // strategies: [
        //   {
        //     storage: localStorage,
        //     paths: [
        //       "isNav",
        //       "unlimitedList",
        //       "yearList",
        //       "monthList",
        //       "mixList",
        //       "autoSignList",
        //       "haveAutoRenewal",
        //     ],
        //   }
        // ]
    }
})
