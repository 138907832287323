<template>
  <div>
    <van-config-provider :theme-vars="{navBarBackgroundColor: '#' + required.skin}">
      <van-nav-bar
          class="navbar"
          :title="translateTitle('订单')"
          left-arrow
          @click-left="toHome"
          @click-right="router.push({path: '/RenewalManagement'})"
          v-if="rendered.isNav"
      >
        <template #left>
          <van-icon name="arrow-left" size="18" color="#000000"/>`
        </template>
        <template #right>
          <span style="color:#000">{{ translateTitle('续费管理') }}</span>
        </template>
      </van-nav-bar>
    </van-config-provider>
    <div class="container" v-if="isOk" :style="{marginTop: rendered.isNav ? '56px' : '0',height: rendered.isNav ? '95vh' : '100vh'}">
      <div class="card" v-for="(item,index) in list" :key="index" @click="item['isPayment'] === '0' ? doNow(item) : ''">
        <div class="container-top">
          <div>{{ translateTitle("订单状态") }}</div>
<!--          <div class="container-top-left">-->
<!--            <span style="font-weight: bold;margin-bottom: 5px;font-size: 16px;">-->
<!--              {{ translateTitle(item.title) }}-->
<!--            </span>-->
<!--            <span>{{ translateTitle(item.subtitle) }}</span>-->
<!--          </div>-->
          <div :style="{color: getStatusColor(item['isPayment'])}">
            {{ translateTitle(getStatusText(item['isPayment'])) }}
          </div>
        </div>
        <div class="container-center">
          <div class="container-center-item">
            <div class="container-center-item-title">{{ translateTitle('产品名称') }}</div>
            :
            <div style="text-indent: 10px">{{ item['zh-JT'] }}</div>
          </div>
          <div class="container-center-item">
            <div class="container-center-item-title">{{ translateTitle('订单编号') }}</div>
            :
            <div style="text-indent: 10px">{{ item['orderId'] }}</div>
          </div>
          <div class="container-center-item">
            <div class="container-center-item-title">{{ translateTitle('支付金额') }}</div>
            :
            <div style="text-indent: 10px">
              ¥<span style="font-size:22px;font-weight:bold;">{{ item['price'] }}</span>
              {{ translateTitle('元') }}
            </div>
          </div>
        </div>
        <div class="container-bottom">
          {{ orderTime(item) }}
        </div>
      </div>
    </div>
    <div class="div_none" v-else>您当前没有订单</div>
  </div>
</template>

<script>
import router from "@/router"
import {reactive, toRefs, onMounted} from "vue";
import {translateTitle} from "@/utils/i18n"
import {useOrderListStore} from "@/store/modules/订单详情/orderList";
import {
  setUrlParam,
  getLocalParam,
  mapCacheParameters,
  mapURLParameters,
  mapRenderParameters
} from "@/utils/tool"
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {useChoosePlanStore} from "@/store/modules/ChoosePlan";
import {useRoute} from "vue-router";
import {paymentAPP, paymentH5, setBeeCloudButton} from "@/utils/payment"
// import {registerWindowEvent, setNavColor, newPage, setRightNavRenewal, setNavRightBut} from "@/utils/setupBridge"
import {WebViewBridge} from '@/utils/bridge'
import {orderList} from '@/api'


export default {
  name: "orderPage",
  setup() {
    const webViewBridge = new WebViewBridge()

    const fourGFlowStore = useFourGFlowStore()

    const route = useRoute()

    //必要参数
    let {defaultParams: required} = fourGFlowStore

    //渲染参数
    let {renderedParams: rendered} = fourGFlowStore

    const state = reactive({
      list: [],
      isOk: true,
      orderTypeName: '',
    })
    const orderListStore = useOrderListStore()
    orderListStore.initData()

    const goBack = () => {
      router.go(-1);
    }

    //渲染订单时间
    const orderTime = (item) => {
      const {isPayment ,paymentTime ,time} = item
      return isPayment === '0' ? time : isPayment === '1' || isPayment === '2' ? paymentTime : time
    }

    //跳转支付页
    const toPay = (orderId) => {
      orderListStore.selectedOrderId = orderId
      Go('pay')
    }

    //跳转首页
    const toHome = () => Go('index')

    //页面跳转
    const Go = (route) => {
      const scenes = required.scenes
      const url = state.orderTypeName === 'automatic'
      ? setUrlParam('/' + route, {iccId: required.iccId,onlyAliPlay:true})
      : setUrlParam('/' + route, {iccId: required.iccId})

      console.warn(scenes, 'to ' + route, url)
      if (scenes === 'APP') {
        webViewBridge.newPage(url)
      } else {
        router.push({path: route})
      }
    }

    //立即支付
    const doNow = (item) => {
      console.warn('订单', item)
      //页面参数
      const {oemId,userId,iccId ,scenes,H5pay} = required
      //订单参数
      const { productID:productId, orderId ,price ,transaction_fee,pTypeName } = item
      state.orderTypeName = pTypeName.split('_')[2]
      const title = item['zh-JT']
      //支付
      if (scenes === 'APP') {
        toPay(orderId)
        // if (H5pay === '1' || H5pay === '2'){
        //   toPay(orderId)
        // }else{
        //   //使用APP的支付
        //   paymentAPP(false, orderId, productId, userId, price, transaction_fee, title, iccId)
        // }
      } else {
        //跳转到H5支付页面
        toPay(orderId)
      }
    }

    const getStatusText = (status) =>{
      switch (status){
        case '1':
          return '已支付'
        case '2':
          return '已退款'
        case '0':
          return '未支付'
      }
    }
    const getStatusColor = (status) =>{
      switch (status){
        case '1':
          return '#b2b2b2'
        case '2':
          return '#D9031B'
        case '0':
          return '#'+required.skin
      }
    }

    //业务逻辑
    const business = async () => {

      const {pageType} = route.query
      // TODO 如果订单页是由云存储套餐页进入，则进行处理
      if (pageType === '云存储') {
        state.isOk = false
        return
      }

      const { oemId, iccId} = required
      //查询订单列表
      const data = await orderList(oemId, iccId)
      console.warn('订单列表', data.length, data)
      if (data.length > 0) {
        //订单列表渲染
        state.list = data
      } else {
        //页面显示 "您当前没有订单"
        state.isOk = false
      }
    }

    //APP事件
    const reloadEvent = () => {
      webViewBridge.registerWindowEvent(() => {
        const skin = required.skin
        webViewBridge.setNavColor(skin)
        // setRightNavRenewal()
        webViewBridge.setNavRightBut()

        webViewBridge.setRightNavRenewal()
      })
    }

    //初始化参数
    const init = () => {
      //查询URL参数
      const opt = router.currentRoute.value.query
      console.warn('页面URL传参', opt)
      //映射缓存参数
      mapCacheParameters(required)
      mapURLParameters(required,opt)
      console.warn('页面参数映射完成,网站当前肤色', required.skin)
      //映射渲染参数
      mapRenderParameters(required)
      console.warn('渲染参数映射完成')
      //导航栏控制
      rendered.isNav = required.scenes === 'H5'
      console.warn(rendered.isNav ? 'H5' : 'APP' + '环境')
    }

    //组件已安装
    onMounted(async () => {
      await webViewBridge.init().catch(()=>{
        console.log("无语")})
      //初始化参数
      init()
      const scenes = required.scenes
      //设置beeCloud支付按钮
      setBeeCloudButton(required.oemId)

      //检测环境
      if (scenes === 'APP') {
        //加载APP事件
        reloadEvent()
        //业务处理
        business()
      } else {
        //业务处理
        business()
      }
    })

    return {
      ...toRefs(state),
      goBack,
      translateTitle,
      router,
      orderListStore,
      required,
      rendered,
      getStatusColor,getStatusText,
      doNow,
      toHome,
      orderTime
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  //margin-top: 56px;
  padding: 10px;
  overflow-y: scroll;
  height: calc(100vh - 76px);
  background: #F2FAFC;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .card {
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 10px
  }

  &-top {
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
    text-align: left;
    font-size: 14px;

    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-around
    }
  }

  &-center {
    border-bottom: 1px solid #f3f3f3;
    padding: 10px 0;
    text-align: left;
    font-size: 14px;
    color: #5e5e5e;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: baseline;

      &-title {
        min-width: 60px;
        color: #8f8f8f
      }
    }

    &-item:nth-of-type(2) {
      margin: 5px 0 3px 0
    }
  }

  &-bottom {
    text-align: left;
    padding: 20px 0 10px 0;
    font-size: 14px;
    color: #5e5e5e
  }
}
.navbar {
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  padding-top: 10px;
}

.div_none {
  width: 200px;
  height: 76px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
</style>