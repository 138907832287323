import request from "@/utils/request";
import {dayActiveListType} from "/#/api";


// 每日活跃数据列表
export function getDayActiveList(data: dayActiveListType) {
    return request({
        url: 'https://battery.eye4.cn:35001/battery/dayActive',
        method: 'post',
        data
    })
}

// 每日活跃数据列表
export function getDayBatteryList(data: dayActiveListType) {
    return request({
        url: 'https://battery.eye4.cn:35001/battery/dayBattery',
        method: 'post',
        data
    })
}