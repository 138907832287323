export default class Bridge {
    constructor() {
        this.bridge = null
    }

    init() {
        //判断ios环境
        const isIOS = () => {
            const u = navigator.userAgent
            return !!u.match(/\(i[^]+( U)? CPU.+Mac OS X/)
        }

        const setupWebViewJavascriptBridge = (callback) => {
            // 这里若是bridge存在则直接调用native方法并且return
            if (isIOS()) {
                if (window.WebViewJavascriptBridge) return callback(window.WebViewJavascriptBridge)
                if (window.WVJBCallbacks) return window.WVJBCallbacks.push(callback)
                window.WVJBCallbacks = [callback]
                let WVJBIframe = document.createElement('iframe')
                WVJBIframe.style.display = 'none'
                WVJBIframe.src = 'https://__BRIDGE_LOADED__'
                document.documentElement.appendChild(WVJBIframe)
                setTimeout(() => document.documentElement.removeChild(WVJBIframe), 0)
            } else {
                if (window.WebViewJavascriptBridge) return callback(window.WebViewJavascriptBridge)
                document.addEventListener('WebViewJavascriptBridgeReady', () => callback(window.WebViewJavascriptBridge), false)
            }
        }

        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => reject(new Error('bridge超时')), 1000)
            setupWebViewJavascriptBridge(bridge => {
                this.bridge = bridge
                clearTimeout(timer)
                resolve(true)
                bridge.registerHandler('WindowLoading', (data, responseCallback) => {
                    responseCallback('success')
                    console.log('APP请求了WindowLoading事件')
                })

                //设置状态栏颜色
                bridge.callHandler('configNavigationBarColor', "#52BFF5");
            })
        })
    }


    callHandler(name, data) {
        return new Promise(resolve => this.bridge.callHandler(name, data, data => resolve(data)))
    }

    registerHandler(name, callback) {
        this.bridge.registerHandler(name, (data, responseCallback) => {
            callback(data, responseCallback)
        })
    }


    //通过APP打开新页面
    async newPage(url) {
        await this.callHandler('newPage', url)
    }

    //显示系统提示窗口
    async showMessage(text) {
        await this.callHandler('showMessage', text)
    }

    //显示系统提示窗口
    async userInfo() {
        const uid = await this.callHandler('getUserid', '')
        const authkey = await this.callHandler('getAuthkey', '')
        const language = await this.callHandler('getLanguage', '')
        return {uid, authkey, language}
    }

    //通知设备刷新授权信息
    async set_update_push_user(did) {
        await this.callHandler('set_update_push_user.cgi', did)
        console.log("已通知设备刷新授权信息")
    }

    //获取头像
    async getDeviceCover(did) {
        const responseData = await this.callHandler('getDeviceCover', did)
        return `data:image/jpegbase64,${responseData}`
    }

    //判断设备是否支持云存储
    async supportCloud(did) {
        const response = await this.callHandler('supportCloud', did)
        return response === '1'
    }

    //是否支持哭声
    async supportCry(did) {
        const response = await this.callHandler('supportCry', did)
        return response === '1'
    }

    //是否支持离岗侦测
    async supportLeave(did) {
        const response = await this.callHandler('supportLeave', did)
        return response === '1'
    }

}