<template>
  <div class="back" v-loading="loading">
    <div class="box_1" v-if="power">
      <div class="box_content">
        <dv-percent-pond :config="config" style="width:200px;height:100px;margin: auto"/>
      </div>
    </div>
    <div>
      <van-cell title="选择日期区间" :value="dateValue" @click="show = true"/>
      <van-calendar v-model:show="show" type="range" :min-date="minDate" :max-date="maxDate" color="#47bcd5"
                    @confirm="onConfirm"/>
    </div>
    <div style="width: 100%">
      <div style="margin:0 auto;text-align: center">
        <el-radio-group v-model="radioValue" style="margin-top: 10px" @change="handleTime">
          <el-radio-button label="过去24小时"/>
          <el-radio-button label="过去一周"/>
        </el-radio-group>
      </div>
    </div>
    <div class="box_2" v-loading="batteryStore.batteryLoading">
      <h4 style="margin: 0">电量</h4>
      <e-chart ref="chartPower" type="power" :chart-info="batteryStore.dayBatteryChartInfo"/>
    </div>
    <div class="box_2" v-loading="batteryStore.activeLoading">
      <h4 style="margin: 0">活跃</h4>
      <e-chart ref="chartRef" type="active" :chart-info="batteryStore.dayActiveChartInfo"/>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent, onMounted, reactive, toRefs} from "vue";
import {useBatteryStore} from "@/store/modules/Battery";
import moment from "dayjs";
import {useRoute} from "vue-router";
import {Toast} from 'vant';

export default {
  name: "index",
  components: {
    EChart: defineAsyncComponent(() => import('@/components/EChart')),
  },
  setup() {
    const batteryStore = useBatteryStore()
    const route = useRoute()
    const {did, power} = route.params
    const state = reactive({
      chartRef: null,
      chartPower: null,
      radioValue: '过去24小时',
      config: {
        //值
        value: power,
        //颜色
        // colors: ['red'],
        //边框宽度
        // borderWidth: 5
      },
      dateValue: '',
      show: false,
      loading: false
    })

    onMounted(async () => {
      batteryStore.mark = '0'
      if (!did) {
        Toast.fail('请选择参数');
        return
      }
      batteryStore.did = did
      state.loading = true
      await batteryStore.getDayActiveDate()
      await batteryStore.getBatteryDate()
      state.loading = false
    })

    const handleTime = (val) => {
      if (val === '过去24小时') {
        handleHour()
      } else if (val === '过去一周') {
        handleWeek()
      }
    }

    //点击24小时
    const handleHour = async () => {
      state.dateValue = ''
      batteryStore.mark = '0'
      await batteryStore.getDayActiveDate()
      await batteryStore.getBatteryDate()
    }

    const handleWeek = async () => {
      state.dateValue = ''
      batteryStore.mark = '1'
      batteryStore.startDate = moment(new Date(new Date().getTime() - 24 * 60 * 60 * 7000)).format('YYYY-MM-DD')
      batteryStore.endDate = moment(new Date(new Date().getTime() - 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
      state.dateValue = `${moment(batteryStore.startDate, 'YYYY-MM-DD').format('MM/DD')} - ${moment(batteryStore.endDate, 'YYYY-MM-DD').format('MM/DD')}`;
      await batteryStore.getDayActiveDate()
      await batteryStore.getBatteryDate()
    }

    const formatDate = (date) => `${date.getMonth() + 1}/${date.getDate()}`;

    const onConfirm = async (values) => {
      const [start, end] = values;
      batteryStore.mark = '1'
      batteryStore.startDate = moment(start).format('YYYY-MM-DD')
      batteryStore.endDate = moment(end).format('YYYY-MM-DD')
      await batteryStore.getDayActiveDate()
      await batteryStore.getBatteryDate()
      state.show = false;
      state.dateValue = `${formatDate(start)} - ${formatDate(end)}`;
      state.radioValue = ''
    };

    return {
      ...toRefs(state),
      onConfirm,
      minDate: new Date(new Date().getTime() - 24 * 60 * 60 * 180000),
      maxDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      handleHour,
      handleWeek,
      handleTime,
      batteryStore,
      power
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  width: 100%;
  height: 100vh;
  background: #f2f4f1;

  .box_1 {
    width: 100%;
    height: 20vh;
    background: #47bcd5;

    .box_content {
      padding-top: 20px;
      color: white;
      text-align: center;
      font-size: 12px;
    }
  }

  .box_2 {
    margin: 2vh auto 0;
    width: 95%;
    height: 35vh;
    //border-radius: 10px;
    box-shadow: 0 3px 14px 1px #e7e7e7;
    background: #ffffff;
  }
}
</style>