import {setUrlParam} from "@/utils/tool";

/**
 * APP与H5交互管理
 */

//app、H5交互中间件
export function setupWebViewJavascriptBridge(callback) {
    if (isIOS()) {
        if (window.WebViewJavascriptBridge) {
            return callback(WebViewJavascriptBridge);
        }
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }
        window.WVJBCallbacks = [callback];
        const WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(() => {
            document.documentElement.removeChild(WVJBIframe)
        }, 0)
    } else {
        if (window.WebViewJavascriptBridge) {
            callback(window.WebViewJavascriptBridge)
        } else {
            document.addEventListener('WebViewJavascriptBridgeReady', () => {
                return callback(window.WebViewJavascriptBridge)
            }, false);
        }
    }
}

//判断ios环境
export function isIOS() {
    const u = navigator.userAgent;
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

//通过APP打开新页面
export function newPage(url, callback) {
    setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('newPage', url, callback);
    });
}

//显示系统提示窗口
export function showMessage(text) {
    setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showMessage', text, (res) => {
        });
    });
}

//通知设备刷新授权信息
export function set_update_push_user(did) {
    console.warn("开始通知刷新授权")
    setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('set_update_push_user.cgi', did, (res) => {
            console.warn("已刷新设备授权信息")
        });
    });
}

//注册窗口加载事件
export function registerWindowEvent(callback) {
    setupWebViewJavascriptBridge((bridge) => {
        bridge.registerHandler('WindowLoading', (data, responseCallback) => {
            console.warn('APP请求了WindowLoading事件')
            responseCallback('success');
            callback(data)
        });
    });
}

//设置导航栏右上角按钮
export function setNavRightBut(callback) {
    setupWebViewJavascriptBridge((bridge) => {
        //设置是否显示4G充值（仅在首页调用）
        bridge.callHandler('configNavigationBarOrder', '1', (responseData) => {
            console.warn('通知APP configNavigationBarOrder')
            if (typeof callback === 'function') callback(responseData)
        })
    })
}

//设置状态栏颜色
export function setNavColor(skin, callback) {
    const color = "#" + skin
    setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('configNavigationBarColor', color, (data) => {
            if (typeof callback === 'function') callback(data)
        });
    })
}

//替换字段
export function replaceField(oemId, url) {
    setRedirect(['redirect_url=http://payservice.beecloud.cn/spay/result.php', 'redirect_url=api.eye4.cn://'], url)
    if (oemId === 'OEM' || oemId === 'VEEPAI') {
        setRedirect(['"fromAppUrlScheme":"alipays"', '"fromAppUrlScheme":"veepai"'], url)
    } else {
        if (oemId === 'FLFDZ') {
            setRedirect(['"fromAppUrlScheme":"alipays"', '"fromAppUrlScheme":"LAICAM"'], url)
        } else {
            setRedirect(['"fromAppUrlScheme":"alipays"', '"fromAppUrlScheme":"' + oemId + '"'], url)
        }
    }
}

//设置重定向
export function setRedirect(data, url) {
    setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('redirect', data, (responseData) => {
        })

        bridge.callHandler('resumedPage', url, (responseData) => {
        })
    })
}

//获取设备ID
export function getDeviceId(callback) {
    setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getDeviceId', "", (did) => {
            if (typeof callback === 'function') callback(did)
        });
    })
}

//获取设备卡号信号强度
export function deviceSignalStrength(iccId, callback) {
    setupWebViewJavascriptBridge(bridge => {
        bridge.callHandler('signalStrength', iccId, (responseData) => {
            const signalStrength = responseData ? responseData : '5'
            if (typeof callback === 'function') callback(signalStrength)
        })
    })
}

//加载APP关键参数
export async function reloadRequired(callback) {
    await setupWebViewJavascriptBridge(bridge => {
        bridge.callHandler('getUserid', "", (responseData) => {
            //查询用户ID
            let userId = responseData
            bridge.callHandler('getLanguage', "", (responseData) => {
                //查询系统语言
                let language = responseData.split('-')[0]
                bridge.callHandler('getIccid', "", (iccId) => {
                    //查询iccId
                    callback({userId: userId, language: language, iccId: iccId})
                })
            })
        })
    })
}

//设置APP导航栏右侧订单按钮跳转数据
export function setRightNavData(iccId, scenes, skin, oemId, H5pay) {
    const required = {iccId, scenes, skin, oemId, H5pay}
    const url = setUrlParam('/orderPage', required)
    console.warn('订单URL：', url)
    const params = [{'name': '订单', 'url': url}]
    setupWebViewJavascriptBridge(bridge => {
        //设置导航栏右侧链接
        bridge.callHandler('configNavigationBarRightButtons', params, (responseData) => {
            console.warn('设置导航栏右上角按钮成功', params)
        });
    })
}

//设置APP导航栏右侧续费管理按钮跳转数据
export function setRightNavRenewal() {
    // iccId, scenes, skin, oemId, H5pay
    const required = {}
    const url = setUrlParam('/RenewalManagement', required)
    console.warn('续费管理URL：', url)
    const params = [{'name': '续费管理', 'url': url}]
    setupWebViewJavascriptBridge(bridge => {
        //设置导航栏右侧链接
        bridge.callHandler('configNavigationBarRightButtons', params, (responseData) => {
            console.warn('设置导航栏右上角按钮成功', params)
        });
    })
}

//获取头像
export function getDeviceCover(did) {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => reject(new Error('获取头像超时')), 3000)
        setupWebViewJavascriptBridge(bridge => {
            bridge.callHandler('getDeviceCover', did, (responseData) => {
                clearTimeout(timeout)
                resolve(`data:image/jpeg;base64,${responseData}`)
            });
        });
    })
}

export function set_update_push_userAsync(did) {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => reject(new Error('调用超时')), 1000)
        setupWebViewJavascriptBridge(function (bridge) {
            bridge.callHandler('set_update_push_user.cgi', did, (res) => {
                clearTimeout(timeout)
                resolve(res)
            })
        })
    })
}

//获取头像
export function pay(orderID, productID) {
    const options = {orderID, productID}
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => reject(new Error('调用支付超时')), 1000)
        setupWebViewJavascriptBridge(bridge => {
            bridge.callHandler('pay', options, (responseData) => {
                clearTimeout(timeout)
                const json = JSON.parse(responseData);
                const {code} = json
                switch (code) {
                    case '401': //苹果交易失败
                    case '403': //服务器验证失败
                    case '404': //没查到产品key
                        reject(new Error('支付失败'))
                        break;
                    case '402': //用户取消交易
                        reject(new Error('支付取消'))
                        break;
                    case '200':  //交易成功
                        resolve()
                        break;
                    default:
                        resolve('未知异常')
                }
            });
        });
    })
}

//查询不同区域的内购产品
export function queryProductInfo(area, array) {
    const groupID = `iap_productID_${area}`
    const queryProductInfo = {groupID, array}
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => reject(new Error('调用查询超时')), 5000)
        setupWebViewJavascriptBridge(bridge => {
            bridge.callHandler('queryProductInfo', queryProductInfo, (responseData) => {
                clearTimeout(timeout)
                resolve(responseData)
            });
        });
    })
}

export default {
    setupWebViewJavascriptBridge,
    isIOS,
    newPage,
    showMessage,
    set_update_push_user,
    registerWindowEvent,
    setNavRightBut,
    setNavColor,
    replaceField,
    setRedirect,
    getDeviceId,
    deviceSignalStrength,
    reloadRequired,
    setRightNavData,
    setRightNavRenewal
}