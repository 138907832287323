import {defineStore} from "pinia";
// @ts-ignore
import Bridge from "@/utils/bridgeAsync";
import {deviceList} from "@/api/device";
import {cameraActivation, couponShow} from "@/api/coupons";
import {couponsType} from "/#/store";

export const useCouponsStore = defineStore('couponsStore', {
  state: (): couponsType => ({
    business: 'D004',
    uid: '',
    authkey: '',
    language: '',  //语言 目前没用到
    clientType: '', //客户端类型,目前没用到
    code: '-',  //激活码
    cover: '',  //封面地址
    use: false, //是否试用
    skeleton: true, //骨架屏 用于网络较差的时候展示
    deviceListLoad: false,  //设备列表是否加载
    deviceList: [], //设备列表
    activeLoading: false, //激活中
  }),
  getters: {
    useText(state) {
      return state.use ? '已使用' : '未使用'
    },
    supportList: (state) => state.deviceList.filter((device: any) => device.isSupport),
    unSupportList: (state) => state.deviceList.filter((device: any) => !device.isSupport)
  },
  actions: {
    async couponShow() {
      const {code: stateCode, msg, data} = await couponShow({code: this.code})
      this.deviceListLoad = true
      this.skeleton = false
      switch (stateCode) {
        case 200:
          const {back, business} = data
          if (data.state === 1) this.use = true
          this.cover = back
          this.business = business
          break
        case 1001:
          throw new Error('激活码不存在')
        default:
          throw new Error('系统异常')
      }
    },
    async getDeviceList() {
      const bridge = new Bridge()
      await bridge.init()
      const data = {uid: this.uid, authkey: this.authkey}
      const result = await deviceList(data)
      for (let i = 0; i < result.length; i++) {
        const doc = result[i];
        const {did, info: {nickname, name = did}, _id} = doc
        const deviceName = nickname ? nickname : name
        let isSupport = true
        switch (this.business) {
          case 'D004':
            isSupport = await bridge.supportCloud(did)
            break
          case 'D011':
            isSupport = await bridge.supportCry(did)
            break
          case 'D012':
            isSupport = await bridge.supportLeave(did)
            break
          case 'D015':
            isSupport = true
            break
        }
        this.deviceList.push({did, deviceName, isSupport, _id})
      }
    },
    async activation(target: string) {
      this.activeLoading = true
      const data = {uid: this.uid, code: this.code, target}
      await cameraActivation(data)
      this.use = true
      this.activeLoading = false
      return '激活成功'
    }
  }
})