<template>
  <div class="select-box">
    <div class="combo-type" v-if="cloudStorageStore.showComboTypeButton">
      <p v-for="item in comboTypeFilter" :class="{'active-list' : combo_type === item}"
         @click="changeType(item)">{{translateTitle(item +'天')}}</p>
    </div>
    <div v-for="(item,index) in filterCombos" :key="index"
         @click="cloudStorageStore.selectedIndex = index;selected(item)"
         class="select-item" :class="{ 'select-item-active' : cloudStorageStore.selectedIndex === index}">
      <!--      <div v-if="item.isHaveTip" style="text-align: left">-->
      <!--          <span class="select-item-tip" :style="{background: index ? '#4ABED7' : '#FF8D59'}">-->
      <!--            {{ translateTitle(item.isHaveTip) }}-->
      <!--          </span>-->
      <!--      </div>-->
      <!--      <div v-else-if="comboCycle(item['unit'], item['mode']) === 720"-->
      <!--           style="height:20px;position:relative">-->
      <!--        <div class="recommend-tip"-->
      <!--             :style="{backgroundImage: 'url(' + require('@/assets/recommend.png')}">-->
      <!--        </div>-->
      <!--        <div class="coupon" :style="{backgroundImage: 'url(' + require('@/assets/优惠券.png')}">-->
      <!--          省{{ item.oldPrice - item.price }}元-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="recommend-tip" v-if="item.isRecommend"
           :style="{backgroundImage: 'url(' + require('@/assets/recommend3.png')}">
        <img :src="require('@/assets/点赞.png')" alt="" style="width: 23px">
        <span style="display: block;margin-right: 10px;">{{ translateTitle('推荐') }}</span>
      </div>
      <div class="coupon" :style="{backgroundImage: 'url(' + require('@/assets/优惠券.png')}"
           v-if="item.preferentialLabel">
        <span style="margin: 3px 6px 8px 12px;padding: 4% 0;display: block;min-width: 60px;}">
          {{ translateTitle('省') }}{{ parseFloat((item.oldPrice - item.price).toFixed(2)) }}{{ translateTitle('元') }}
        </span>
      </div>
      <div class="select-item-container" v-if="shortTemplate">
        <div class="select-item-container-left">
          <span style="font-size:20px;font-weight: bold;margin-bottom: 10px">{{
              translateTitle(item.localizedTitle)
            }}</span>
          <span style="font-size:14px;color:#bdbdbd">{{ translateTitle(item.localizedDescription) }}</span>
        </div>
        <div class="select-item-container-right">
          <span style="font-size:24px;color:#FF8D59">{{ item.currencySymbol }}{{ translateTitle(item.price) }}</span>
          <span v-if="item.oldPrice" class="oldPrice">{{ item.currencySymbol }}{{
              translateTitle(item.oldPrice)
            }}</span>
          <van-tag v-if="item.introduce" round color="#4ABED7" class="select-item-right-tag">
            {{ translateTitle(item.introduce) }}
          </van-tag>
        </div>
      </div>

      <div class="select-item-container" v-else>
        <div class="select-item-container-left">
          <span style="font-size:20px;font-weight: bold;margin-bottom: 10px">{{translateTitle(item.localizedTitle) }}</span>
          <span style="font-size:16px;color:#bdbdbd;margin-bottom: 10px">{{translateTitle(item.localizedDescription) }}</span>
          <van-tag v-if="item.introduce" round color="#4ABED7" class="select-item-right-tag2">
            {{ translateTitle(item.introduce) }}
          </van-tag>
        </div>
        <div class="select-item-container-right">
          <span style="font-size:24px;color:#FF8D59">{{ item.currencySymbol }}{{ translateTitle(item.price) }}</span>
          <span v-if="item.oldPrice" class="oldPrice">{{ item.currencySymbol }}{{translateTitle(item.oldPrice) }}</span>
          <span class="averagePrice" v-if="item.showAverageDailyPrice">
            {{ translateTitle("任意看日均") }}{{ item.showAverageDailyPrice }}{{ translateTitle("元") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {translateTitle} from "@/utils/i18n";
import {comboType_map_Id} from "@/utils/fixedPackage";
import {reactive, toRefs, computed} from "vue";
import {useChoosePlanStore} from "@/store/modules/ChoosePlan";
import {useCloudStorageStore} from "@/store/modules/cloudStorage";

export default {
  name: "selectListItem",
  setup() {
    const choosePlanStore = useChoosePlanStore()
    const cloudStorageStore = useCloudStorageStore()
    let language = localStorage.getItem('language')
    let isShortTemplate = ['kr', 'Jap']

    const state = reactive({
      shortTemplate: isShortTemplate.indexOf(language) !== -1,
      combo_type: "30", // 默认展示套餐为30天套餐
      comboType_map_Id
    })

    const filterCombos = computed(() => {
      let x
      // return cloudStorageStore.ComboList
      if (cloudStorageStore.ComboType === 'fixedPlan' || cloudStorageStore.ComboType === 'iapPlan') {
        x = cloudStorageStore.ComboList

      } else {
        x = cloudStorageStore.ComboList.filter(item => {
          return state.comboType_map_Id[state.combo_type].includes(item.id)
        })
      }
      return x
    })
    // 获取国内外状态，国内套餐有三类，国外套餐只有两类
    const comboTypeFilter = computed(() => (cloudStorageStore.getCountryCode === 'CN' && cloudStorageStore.bridgeInfo.language.toUpperCase() === 'ZH')
        ? ["30","15","3"] : ["30","15"])

    const selected = (item) => {
      cloudStorageStore.selectedCombo = item
      console.warn("被选中套餐", item)
    }

    const changeType = (item) => {
      state.combo_type = item
      cloudStorageStore.selectedCombo = {
        currencySymbol: "¥",
        price: 0,
        productID: '',
        id: ''
      }
      cloudStorageStore.selectedIndex = -1
    }
    // alert(cloudStorageStore.ComboType)

    return {
      ...toRefs(state),
      translateTitle,
      choosePlanStore,
      language,
      cloudStorageStore,
      filterCombos,
      changeType,
      selected,
      comboTypeFilter
    }
  }
}
</script>

<style scoped>

.averagePrice {
  font-size: 9px;
  border-radius: 15px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 8px;
  color: #fff;
  background: #4ABED7;
  /*transform: scale(0.8);*/
  letter-spacing: 1px;
}

.recommend-tip {
  top: -6px;
  height: 40px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: -17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 15px 8px 15px;
  font-size: 12px;
  color: white;
}

.coupon {
  width: fit-content;
  height: fit-content;
  position: absolute;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  right: -5px;
  font-size: 12px;
  color: white;
  top: 5px;
  letter-spacing: 1px;
  text-align: center;
}

.select-box {
  padding: 20px;
  background: #ffffff;
}

.select-item {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  margin-bottom: 10px;
  transition: .8s;
  position: relative
}

.select-item:last-of-type {
  margin-bottom: 0;
}

.select-item-tip {
  color: #fff;
  border-radius: 5px 0;
  font-size: 6px;
  width: fit-content;
  padding: 5px 15px;
  display: inline-block
}

.select-item-active {
  background-color: #DBF2F7;
  border: 1px solid #4ABED7
}

.select-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 20px);
  padding: 30px 10px 20px 10px;
  /*height: 70px*/
}

.select-item-container-left, .select-item-container-right {
  /*height: calc(100% - 20px);*/
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.select-item-container-right {
  text-align: center;
  justify-content: center;
  width: 38%
}

.select-item-right-tag {
  padding: 4px 8px;
  font-size: 7px;
  transform: scale(0.8);
  letter-spacing: 1px;
  color: #f9f9f9;
  align-self: center;
}

.select-item-right-tag2 {
  padding: 4px 8px;
  font-size: 7px;
  color: #f9f9f9;
  white-space: nowrap;
  width: fit-content;
}


.oldPrice {
  text-decoration: line-through;
  font-size: 18px;
  color: #bdbdbd
}

.combo-type {
  display: flex;
  flex-direction: row;
  color: #000000;
  margin-bottom: 20px;
  justify-content: space-around
}

.combo-type p {
  padding: 0;
  margin: 0;
  text-align: center;
}

.active-list {
  color: #4ABED7;
  position: relative;
}

.active-list::before {
  content: "";
  width: 150%;
  height: 2px;
  background: #4ABED7;
  position: absolute;
  bottom: -5px;
  left: -25%;
}
</style>