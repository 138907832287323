export default {
    '确认关闭':' Confermare chiusura ',
    '取消': 'soppressione',
    "请稍等": "Un momento, per favore.",
    "试用中": "In prova",
    "动态云录像": "Video dinamico su cloud",
    "全天云录像": "Video cloud tutto giorno",
    "连续包月": "Abbonamento mensile continuo",
    "首推": "Prima promozione",
    "新用户推荐": "Consigliato per nuovi utenti",
    "循环存储7天": "Archiviazione di ciclo per 7 giorni",
    "循环存储30天": "Archiviazione di ciclo per 30 giorni",
    "循环存储15天": "Archiviazione di ciclo per 15 giorni",
    "循环存储3天": "Archiviazione di ciclo per 3 giorni",
    "超80%人选择": "Oltre 80% di persone sceglie",
    "连续半年": "Abbonamento sei mesi continui",
    "连续包年": "Abbonamento anno consecutivo",
    "包年": "Abbonamento annuale",
    "包半年": "Mezzo anno",
    "包月": "Abbonamento mensile",
    "新用户首选": "Preferito per nuovi utenti",
    "尝鲜": "Primi utilizzatori",
    "家用省电套餐": "Pacchetto risparmio energetico domestico",
    "超长周期套餐": "Pacchetto ciclo ultra lungo",
    "看店必备套餐": "Pacchetto essenziale di negozio",
    "到期后自动续费，可随时取消": "Rinnovo automatico dopo scadenza, annullabile in qualsiasi momento",
    "自动续费协议": "Accordo di rinnovo automatico",
    "消息/视频无上限": "Messaggi/video illimitati",
    "云存储购买协议": "Contratto di acquisto di archiviazione cloud",
    "动态云录像.png": "DTYLX_Ita.png",
    "imageHeight": "360px",
    "已优惠": "Scontato",
    "AI智能服务": "Servizio intelligente AI",
    "未开通": "Non attivato",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "Dopo aver acquistato \" Servizio intelligente AI \", puoi goderti la ​​ultra-lunga archiviazione cloud, la personalizzazione dell'area di monitoraggio e la personalizzazione del suono dell'allarme. Tutti sono esclusivi! C'è anche la funzione di riconoscimento intelligente AI (riconoscendo borse, animali domestici e veicoli ) che sarà disponibile esclusivamente dopo il lancio.",
    "30天超长云存储": "30 giorni di ultra-lunga archiviazione cloud",
    "报警和推送声个性化": "Personalizzazione del suono di allarme e promozione",
    "报警区域自定义": "Personalizzazione dell'area di allarme",
    "人形追踪": "Tracciamento umanoide",
    "摄像头会实时跟踪人的行动轨迹": "Fotocamera traccia i movimenti delle persone in tempo reale",
    "人形框定": "Cornice umanoide",
    "在实时视频里面会自动框出并跟踪所出现的人": "Nel video in tempo reale, la persona che appare verrà automaticamente inquadrata e tracciata",
    "AI智能服务功能介绍": "Introduzione alla funzione di servizio intelligente AI",
    "免费服务 VS Al智能服务": "Servizio gratuito VS Servizio intelligente AI",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "Estendere fino a 30 giorni di archiviazione ultra-lunga (6 tipi di pacchetti sono opzionali), creando un servizio di archiviazione cloud crittografato di livello finanziario. Riavvolgere il tempo a portata di mano, in modo da non perdere mai nessun momento meraviglioso.",
    "循环存储": "Archiviazione di ciclo",
    "无": "Nessuno",
    "30天": "30 giorni",
    "15天": "15 giorni",
    "3天": "3 giorni",
    "报警推送": "Allarme di promozione",
    "100条": "100 pezzi",
    "无上限！": "Illimitato!",
    "视频时长": "Durata del video",
    "最长60秒": "Fino a 60 secondi",
    "报警声个性化": "Personalizzazione del suono dell'allarme",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "Allarme della fotocamera/suono di trasmissione, suoneria di promozione del telefono cellulare, possono essere personalizzati. Vengono preparate ricche voci locali, puoi anche usare voce e testo per registrare qualsiasi voce desideri.",
    "监测区域自定义": "Personalizzazione dell'area di monitoraggio",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "Una o più aree di monitoraggio possono essere impostate per catturare solo le aree che ti interessano per ridurre gli allarmi non necessari; quando viene attivato un allarme, saprai esattamente dove si trova l'allarme.",
    "AI智能通知(待开放)": "Notifica intelligente AI (da aprire)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "Utilizzando l'intelligenza artificiale AI per identificare la tecnologia di algoritmi avanzati, può identificare il contenuto principale dello schermo: animali domestici, veicoli, pacchi. Ed analizzare il suo comportamento d'azione per inviare informazioni più accurate ed intelligenti.",
    "已了解，去购买": "Capito, andare a comprare",
    "已开通": "Già aperto",
    "10秒/每条": "10 secondi/pezzo",
    "3天\n(100条/天，10秒/条) ": "3 giorni (100 pezzi/giorno, 10 secondi/pezzo)",
    "7/30天\n (消息/视频无上限)": "7/30 giorni (Messaggio/video illimitato)",
    "30天\n (消息/视频无上限)": "30 giorni (Messaggio/video illimitato)",
    "距离服务到期": "Fino alla scadenza del servizio",
    "永久": "Permanente",
    "体验": "Esperienza",
    "监测区域": "Area di monitoraggio",
    "报警推送100条/天": "Allarme di promozione 100 pezzi/giorno",
    "视频时长10秒": "Durata del video 10 secondi",
    "7天云存储": "7 giorni di archiviazione su cloud",
    "30天云存储": "30 giorni di archiviazione su cloud",
    "1个月": "1 mese",
    "6个月": "6 mesi",
    "12个月": "12 mesi",
    "循环存储7天（使用时间6个月）": "Archiviazione di ciclo per 7 giorni (tempo di utilizzo per 6 mesi)",
    "查看详情": "Guardare i detagli",
    "安全": "Sicurezza",
    "便捷": "Conveniente",
    "贴心": "Intimo",
    "云存储特权": "Privilegi di archiviazione su cloud",
    "云端保护": "Protezione del cloud",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "\"Il video è archiviato nel cloud, non può essere rotto, non può essere rubato, può proteggere la sicurezza dei dati\"",
    "隐私加密": "Crittografia della riservatezza",
    "金融级数据加密方案，保护隐私更\n安全": "Schema di crittografia dei dati di livello finanziario per proteggere la riservatezza in modo più sicuro",
    "存储灵活": "Archiviazione flessibile",
    "同时保存报警录像和视频，不错过任\n    何动态": "Salvare la registrazione dell'allarme e il video allo stesso tempo, non perdere nessuna dinamica",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "A causa di tasse fiscali e tassi di cambio, ecc., il prezzo pagato oscillerà leggermente in base al paese in cui è stato effettuato l'accesso al tuo conto",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "La funzione del servizio intelligente ha effetto solo nel privilegio di amministratore della fotocamera e l'utente condiviso seguirà il privilegio di amministratore del dispositivo per utilizzarlo",
    "付款：确认购买并付款后记入iTunes 账户": "Pagamento: accredito sul conto iTunes dopo la conferma dell'acquisto e il pagamento",
    " 去支付": "Pagare",
    "7天    ": "7 giorni",
    "60天": "60 giorni",
    "有效日期至": "Valido per",
    "可升级": 'Upgradeable',
    "已到期": 'Scaduto',
    "云视频录像": "Cloud video registrazione ",
    "关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长": "Dopo la chiusura, i video non verranno salvati nel cloud e il servizio di archiviazione cloud corrente non verrà esteso.",
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "Quando lo schermo del video in tempo reale cambia in modo dinamico, il dispositivo lo registrerà e lo caricherà automaticamente nell'archivio cloud. I dati audio e video sono sottoposti alla tecnologia di crittografia di livello finanziario per garantire la sicurezza dei dati.",
    "去支付": "Per pagare",
    "天": "giorno",
    "首选": "essere la prima scelta",
    "超过80%人选择": "Più dell'80% sceglie",
    "存储周期": "Ciclo di stoccaggio",
    "订单": "ordine",
    "去续费": "Da rinnovare",
    "云存储录像邀您试用": "Il video di archiviazione cloud ti invita a provarlo",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "Avrai un periodo di prova gratuito di un mese. Il video non verrà perso se memorizzato nel cloud. Il video verrà conservato per sette giorni e la riproduzione è semplice e conveniente.",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "media giornaliera",
    "元": "RMB",
    "省": "Save",
    "推荐": "raccomandare",
    "请等待": "Per favore aspetta",
    "激活": "Attiva",
    "支持": "Supporto",
    "不支持": "Non supportato",
    "年卡": "Abbonamento annuale",
    "半年卡": "Mezzo anno",
    "月卡": "Abbonamento mensile",
    "暂不支持，敬请期待": "Non ancora supportato, rimanete sintonizzati",
    "卡片暂不支持充值": "Le carte non sono attualmente ricaricabili",
    "HI~有什么需要查询的吗？": "Vi è bisogno di assistenza per le indagini?",
    "更多热门问题为你解答": "Altre interrogazioni di attualità vi danno una risposta",
    "热门分类": " Classificazione attuale",
    "添加设备": "Aggiunta di attrezzature",
    "监控异常": "Sorveglianza delle anomalie",
    "操作视频": "Video operativi",
    "更多其他": " Più altro",
    "常见问题": "domande e risposte",
    "在线客服": "Servizio clienti in linea",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}