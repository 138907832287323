<template>
  <div style="background:#F1F1F3;height: 100vh;">
    <div class="topBox" :style="{'background-color':'#'+required.skin}">
      <img :src="require('../../../assets/iccCard.png')" alt="加载失败" width="100">
      <span style="margin: 15px 0">{{ schedule }}%</span>
      <span>{{GlobalStatus}}</span>
    </div>
    <div>
      <div class="container">
        <div>
          <img :src="require('../../../assets/有效期检测.png')" alt="加载失败" width="24" style="margin: 0 5px">
          有效期
        </div>
        <div style="padding: 10px;border-radius:5px" :style="{color: stateColorJudgment(expirationStatus.status) }">
          {{ expirationStatus.msg || '检测中...'}}
        </div>
        <div>
          <img :src="require('../../../assets/流量检测.png')" alt="加载失败" width="24" style="margin: 0 5px">
          流量
        </div>
        <div style="padding: 10px;border-radius:5px" :style="{color: stateColorJudgment(trafficStatus.status) }">
          {{ trafficStatus.msg || '检测中...'}}
        </div>
        <div>
          <img :src="require('../../../assets/状态检测.png')" alt="加载失败" width="24" style="margin: 0 5px">
          状态
        </div>
        <div style="padding: 10px;border-radius:5px" :style="{color: stateColorJudgment(cardStatus.status) }">
          {{ cardStatus.msg || '检测中...'}}
        </div>
      </div>
    </div>
    <div style="height:10vh;width:100vw;position:fixed;bottom:0;text-align:center;">
      <van-button color="#46BCD5" style="border-radius: 5px;width:80%;"
      v-if="expirationStatus && trafficStatus && cardStatus " @click="fetchStatus()">
        重新检测
      </van-button>
    </div>
  </div>
</template>

<script>
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {onMounted, reactive, toRefs} from "vue";
import axios from "axios";
import {ElLoading} from "element-plus";
import {useRoute} from "vue-router";

export default {
  name: "intelligentDetection",
  setup() {
    const fourGFlowStore = useFourGFlowStore()
    // status 0-等待中 1-成功 2-失败
    const initState = () => {
      return {
        schedule: 1,
        expirationStatus: {status: 0,msg:''},
        trafficStatus: {status: 0,msg:''},
        cardStatus: {status: 0,msg:''},
        GlobalStatus: ""
      }
    }

    const state = reactive(initState())

    const route = useRoute()

    let loading,timerList = [],processList = []

    // const stateJudgment = (status) => status ? status === 1 ? "检测通过" : "检测失败" : "检测中..."

    const stateColorJudgment = (status) => status ? status === 1 ? "#18B284" : "#ff5c5c" : "#999999"

    const loadingEnd = () => {
      state.GlobalStatus = '检测完成'
      loading.close()
    }

    const increaseProgressDone = () => {
      state.schedule ++
      if(state.schedule === 100) loadingEnd()
    }

    const increaseProgressBegin = (index,speed) => {
      timerList[index] = null
      processList[index] = 0
      timerList[index] = setInterval(()=>{
        if(processList[index] ++ >= 32) {
          clearInterval(timerList[index])
          increaseProgressDone()
          return
        }
        state.schedule ++
      },speed)
    }

    const increaseProgressSpeedUp = (index,speed) => {
      clearInterval(timerList[index])
      timerList[index] = setInterval(()=>{
        if(processList[index] ++ >= 32) {
          clearInterval(timerList[index])
          increaseProgressDone()
          return
        }
        state.schedule ++
      },speed)
    }

    const requestStatus = (url,type,index) => {
      increaseProgressBegin(index,500)
      axios.post(url,{card: required.iccId}).then(response =>{
        console.warn("response：",response)
        const {code, msg} = response.data
        state[type].msg = msg
        state[type].status = !code ? 1 : 2
        // console.log(type,"已经修改了")
        increaseProgressSpeedUp(index,50)
      }).catch(()=> {
        console.warn("报错啦兄弟")
      })
    }

    const fetchStatus = () => {
      Object.assign(state,initState())

      requestStatus("https://wx.88iot.net/iot/card/v2/diagnosisEffective",'expirationStatus',0)
      requestStatus("https://wx.88iot.net/iot/card/v2/diagnosisCardStatus",'cardStatus',1)
      requestStatus("https://wx.88iot.net/iot/card/diagnosis",'trafficStatus',2)

      loading = ElLoading.service({
        lock: true,
        text: '检测中',
        background: 'rgba(255,255,255,0.7)',
      })
    }

    onMounted(()=>{
      // required.iccId = '8986112127503209974'
      const {iccId} = route.query
      if(!required.iccId) required.iccId = iccId
      if(!required.iccId) {
        console.warn("没有卡号？",required,required.iccId)
        return  alert("未检测到卡号!")
      }
      console.warn("检测卡号：",required.iccId)
      console.warn('查看皮肤', required.skin)
      fetchStatus()
    })

    //必要参数
    let {defaultParams: required} = fourGFlowStore
    return {
      ...toRefs(state),
      stateColorJudgment,
      required,
      fetchStatus
    }
  }
}
</script>

<style scoped lang="scss">
.topBox {
  width: 100vw;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.container {
  padding: 25px;
  width: calc(90vw - 50px);
  height: calc(35vh - 50px);
  margin: 0 auto;
  background: white;
  border-radius: 15px;
  transform: translateY(-5vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
  div:nth-of-type(even){
    background: #F9F9F9;
    color: #999999
  }
}
</style>