import {defineStore} from "pinia";
import {BatteryType} from "/#/store";
import {getDayActiveList, getDayBatteryList} from "@/api/Battery";
import moment from 'dayjs'
import {Notify} from 'vant';

export const useBatteryStore = defineStore('BatteryStore', {
    state: (): BatteryType => ({
        activeLoading: false,
        batteryLoading: false,
        dayActiveChartInfo: {
            title: '',
            XAxis: [],
            Series: []
        },
        dayBatteryChartInfo: {
            title: '',
            XAxis: [],
            Series: []
        },
        mark: '',
        did: '',
        startDate: '',
        endDate: '',
    }),
    getters: {},
    actions: {
        async getDayActiveDate() {
            if (!this.did) return
            this.activeLoading = true
            const rep = {
                mark: this.mark,
                did: this.did,
                startDate: this.startDate,
                endDate: this.endDate,
            }
            getDayActiveList(rep)
                .then(res => {
                    const {data = '', msg} = res
                    if (msg !== 'success') {
                        Notify({type: 'danger', message: msg});
                        this.activeLoading = false
                        return
                    }
                    const {keyArr, valueArr} = this.handle(data)
                    this.dayActiveChartInfo.title = '活跃'
                    this.dayActiveChartInfo.XAxis = keyArr
                    this.dayActiveChartInfo.Series = valueArr
                    this.activeLoading = false
                })
                .catch(err => {
                    Notify({type: 'danger', message: err});
                    this.activeLoading = false
                })

        },

        async getBatteryDate() {
            if (!this.did) return
            this.batteryLoading = true
            const rep = {
                mark: this.mark,
                did: this.did,
                startDate: this.startDate,
                endDate: this.endDate,
            }
            getDayBatteryList(rep)
                .then(res => {
                    const {data = '', msg} = res
                    if (msg !== 'success') {
                        Notify({type: 'danger', message: msg});
                        this.batteryLoading = false
                        return
                    }
                    const {keyArr, valueArr} = this.handle(data)
                    this.dayBatteryChartInfo.title = '电量'
                    this.dayBatteryChartInfo.XAxis = keyArr
                    this.dayBatteryChartInfo.Series = valueArr
                    this.batteryLoading = false
                })
                .catch(err => {
                    Notify({type: 'danger', message: err});
                    this.batteryLoading = false
                })
        },

        handle(data: any) {
            const keyArr = []
            const valueArr = []
            const arr = Object.keys(data).sort()
            for (let i = 0; i < arr.length; i++) {
                const tem = arr[i]
                let hour = ''
                if (this.mark === '0') {
                    hour = tem.substr(-2, 2)
                } else if (this.mark === '1') {
                    hour = moment(tem, 'YYYY-MM-DD').format('MM/DD')
                }
                const value = data[tem]
                keyArr.push(hour)
                valueArr.push(value)
            }
            return {keyArr, valueArr}
        },
    }
})