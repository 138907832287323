import request from "@/utils/request";
import {deviceNameType, deviceListType} from "/#/api";


//获取设备名称
export function deviceName(data: deviceNameType) {
  return request({
    url: '/device/name',
    method: 'post',
    data
  })
}


//获取设备列表
export function deviceList(data: deviceListType) {
  return request({
    url: '/device/list',
    method: 'post',
    data
  })
}
