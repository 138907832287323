<template>
  <van-overlay :show="store.use">
    <div class="wrapper" @click.stop>
      <h1 class="block">{{ translateTitle('已激活') }}</h1>
    </div>
  </van-overlay>
  <van-overlay :show="store.activeLoading">
    <div class="wrapper">
      <van-loading size="48px" text-color="#ffffff" vertical>{{ translateTitle('激活中...') }}</van-loading>
    </div>
  </van-overlay>
  <van-image width="100%" :src="store.cover">
    <template v-slot:loading>
      <van-loading type="spinner" size="20"/>
    </template>
  </van-image>
  <van-notice-bar :text="store.code" color="#1989fa" background="#ffffff" left-icon="coupon-o"/>
  <van-skeleton v-show="store.skeleton" title :row="3"/>
  <div v-show="store.deviceListLoad">
    <deviceList/>
  </div>
</template>

<script>
import {translateTitle} from "@/utils/i18n";
import {defineAsyncComponent} from 'vue'
import {useRoute} from "vue-router";
import {useCouponsStore} from "@/store/券";
import {Dialog} from "vant";

export default {
  name: "index",
  components: {
    deviceList: defineAsyncComponent(() => import("@/views/券/deviceList")),
  },
  setup() {
    const route = useRoute();
    const store = useCouponsStore();
    store.$reset()
    const {query: {uid, authkey, language, code}, query} = route
    if (!uid || !authkey || !language || !code) throw new Error('参数不完整')
    store.$state = {...query}
    store.couponShow()
        .then(() => {
          store.getDeviceList()
        })
        .catch(err => {
          const message = `${translateTitle(err.message)}`
          const options = {message}
          Dialog.confirm(options)
              .then(() => console.log('确定'))
              .catch(() => console.log('用户取消'))
        })
    return {
      store, translateTitle
    }
  }
}
</script>

<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  color: #fff;
}
</style>