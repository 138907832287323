<template>
  <div v-if="ready" class="page">
    <h3>{{ translateTitle('HI~有什么需要查询的吗？') }}</h3>
    <p style="color:#A6A6A6;margin-bottom: 20px">{{ translateTitle('更多热门问题为你解答') }}</p>
    <img :src="require('../../assets/FAQ/search.png')" width="280" style="position:absolute;right: 0;top: 10px">
    <div class="hotIssueBox" style="padding: 20px 10px">
      <p>
        <van-icon name="fire" color='#F56C6C' style="padding-right: 5px"/>
        {{ translateTitle('热门分类') }}
      </p>
      <div class="iconsBox">
        <div @click="goTitleListPage('添加设备')">
          <img style="width: 50%" :src="require('@/assets/FAQ提交.png')" alt="">
          <span>{{ translateTitle('添加设备') }}</span>
        </div>
        <div @click="goTitleListPage('监控异常')">
          <img style="width: 50%" :src="require('@/assets/FAQ异常告警.png')" alt="">
          <span>{{ translateTitle('监控异常') }}</span>
        </div>
        <div @click="goTitleListPage('操作视频')">
          <img style="width: 50%" :src="require('@/assets/FAQ操作视频.png')" alt="">
          <span>{{ translateTitle('操作视频') }}</span>
        </div>
      </div>
    </div>
    <div class="hotIssueBox">
      <p>
        <van-icon name="fire" color='#F56C6C' style="padding-right: 5px"/>
        {{ translateTitle('常见问题') }}
      </p>
      <span class="more" @click="goTitleListPage('其他')">{{ translateTitle('更多其他') }}<van-icon name="arrow"/></span>
      <van-tabs v-model:active="activeTab" color="#54CCFF" @click-tab="changeTab"
                title-active-color="#54CCFF" title-inactive-color="#6E6E6E">
        <van-tab v-for="type in tabList" :title="translateTitle(type)">
          <el-collapse>
            <el-collapse-item v-for="item in typeList(type)" :name="item._id" :title="item.nameInfo[language]" @click="goDescription(type,item._id)">
            </el-collapse-item>
          </el-collapse>
        </van-tab>
      </van-tabs>
    </div>
    <div class="bottom-box" v-if="isAppEnv && (FAQStore.showCustomerService || FAQStore.showQuickService)">
      <div class="bottom-box-content" @click="goService">
        <img :src="require('@/assets/service.png')" alt="">{{ translateTitle('在线客服') }}
      </div>
    </div>
  </div>
  <van-loading v-else style="text-align: center;top: 45vh;height: 100vh" color="#1989fa"/>
</template>

<script>
import {reactive, toRefs, computed} from "vue";
import {useFAQStore, useFAQTransferStore} from "@/store/modules/FAQ";
import {setUrlParam} from '@/utils/tool'
import {translateTitle} from "@/utils/i18n.js"
import {useRoute, useRouter} from 'vue-router'
const FAQStore = useFAQStore()
const FAQTransferStore = useFAQTransferStore()
export default {
  name: "index",
  setup() {
    const route = useRoute()
    const router = useRouter()

    const state = reactive({
      activeTab: 0,
      tabList: ["猜你想问", "报警功能", "云存储", "TF卡录像"],
      active: [],
      titleList: [],
      language: '',
      problemName: '',
      isAppEnv: false,
      ready: false,
    })

    document.title = "FAQ"

    const {language, supprotQuickService} = route.query

    const isNewApp = supprotQuickService ? true : false

    const enterFeedback = async (id) => {
      // await FAQStore.getNextProblemList(id) ? await router.push({path: '/FAQ/informationCollection'}) : console.log("请求失败")
      await FAQStore.getNextProblemList(id) ? pageChange('/FAQ/informationCollection', {}) : console.log("请求失败")
    }

    const filterList = computed(() =>
        FAQStore.titleList.filter(o => !state.problemName
            || (o.name && o.name.toLowerCase().includes(state.problemName.toLowerCase()))
        )
    )

    const changeTab = (el) => {
      const {title} = el

      let _title

      switch (title){
        case "猜你想问":
        case "Question":
          _title = '猜你想问'
          break
        case "报警功能":
        case "Alarm":
          _title = '报警功能'
          break
        case "云存储":
        case "Cloud":
          _title = '云存储'
          break
        case "TF卡录像":
        case "Recording":
          _title = 'TF卡录像'
          break
      }
      // 这里title要做英文转中文
      FAQStore.activeTitle = _title
      if (FAQStore.titleObj[_title]) return
      FAQStore.fetchTypeTitleList(_title)
      state.ready = true
    }

    const goTitleListPage = (title) => pageChange('/FAQ/titleList', {title})

    const goDescription = (typeName, id) => pageChange('/FAQ/description', {typeName, id})
    // 点击在线客服后通知app
    const goService = () => {
      if(supprotQuickService){
        console.log("触发==>enterQuickService")
        FAQStore.webViewBridge.callHandler("enterQuickService","",(res)=>{
          console.log("在线客服页：",res)
        })
      }else{
        console.log("触发==>enterCustomerService")
        FAQStore.webViewBridge.callHandler("enterCustomerService","",(res)=>{
          console.log("在线客服页：",res)
        })
      }
    }
    // 根据环境判断使用什么跳转方式
    const pageChange = (path, query) => {
      if (state.isAppEnv) {
        const url = setUrlParam(path, query)
        FAQStore.webViewBridge.newPage(url)
      } else {
        router.push({path, query})
      }
    }

    const typeList = computed(() => (key) => {
      return FAQStore.titleObj[key]
    })

    console.warn("host:", window.location.host, window.location.href, window.location)

    const process = async () => {
      state.ready = false
      const title = FAQStore.activeTitle
      switch (title){
        case "猜你想问":
        case "Question":
          state.activeTab = 0
          break
        case "报警功能":
        case "Alarm":
          state.activeTab = 1
          break
        case "云存储":
        case "Cloud":
          state.activeTab = 2
          break
        case "TF卡录像":
        case "Recording":
          state.activeTab = 3
          break
        default:
          state.activeTab = 0
      }
      // 初始化操作
      FAQStore.$reset()
      FAQTransferStore.$reset()
      FAQStore.activeTitle = title

      state.isAppEnv = await FAQStore.initBridge(isNewApp)
      if (FAQStore.titleObj[title]) return
      await FAQStore.fetchTypeTitleList(title)

      if(!state.isAppEnv){
        // 网页访问根据url传的language来
        const lang = ['zh', 'en', 'pl', 'tr'].indexOf(language)!==-1 ? language : 'en'
        state.language = lang
        FAQStore.from.actionInfo.language = lang
      }else{
        // APP访问根据APP的语言来
        state.language = FAQStore.from.actionInfo.language
      }
      console.warn("state.isAppEnv:", state.isAppEnv)
      state.ready = true
    }

    process().catch(()=>{
      state.ready = true
    })

    return {
      ...toRefs(state),
      goTitleListPage,
      enterFeedback,
      filterList,
      changeTab,
      typeList,
      FAQStore,
      goDescription,
      goService,
      translateTitle,
    }
  }
}
</script>
<style>
.el-collapse {
  border-top: none;
}

.el-collapse-item__header {
  line-height: normal;
}

p {
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>

.page {
  padding: 20px 20px 60px 20px;
  min-height: calc(100vh - 80px);
  //height: auto;
  background: linear-gradient(to left top, #F5FBFC 30%, #EEF2FD);

  .hotIssueBox {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 14px 1px #e7e7e7;
    background: white;
    margin-bottom: 20px;
    position: relative;

    .more {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 14px;
      color: #777777;
    }

    > p {
      font-size: 17px;
      font-weight: bold;
    }

    .iconsBox {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 10px 0;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.3%;

        //img {
        //  width: 75%;
        //}

        span {
          font-size: 14px;
          color: #6e6e6e;
          margin-top: 10px;
          text-align: center;
          //white-space: nowrap;
        }
      }
    }
  }

  .bottom-box {
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 10px;

    &-content {
      width: 100vw;
      height: 100%;
      background: rgb(255, 255, 255);
      position: absolute;
      bottom: 0;
      left: -20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 22px !important;margin-right: 10px;
      }
    }
  }
}
</style>