<template>
  <div class="container">
    <van-config-provider :theme-vars="{navBarBackgroundColor: '#' + required.skin}">
      <van-nav-bar
          title="订单详情"
          left-arrow
          @click-left="toBack"
          v-if="rendered.isNav"
      />
    </van-config-provider>
    <van-cell-group title="订单信息">
      <van-cell :border="false">
        <template #title>
          <div>
            订单编号
          </div>
        </template>
        <template #right-icon>
          <div style="width: 70%;color: #969799;text-align: right;">{{ orderListStore.selectedOrderId }}</div>
        </template>
      </van-cell>
      <van-cell title="产品名称" :value="rendered.title" :border="false"/>
      <van-cell title="卡号" :value="required.sim"/>
      <van-cell title="ICCID" :value="required.iccId"/>
    </van-cell-group>
    <div class="box_bottom">
      <van-cell-group title="交易明细">
        <van-cell title="订单金额" :value="rendered.price+'元'"/>
        <van-cell title="应付" :value="rendered.price+'元'"/>
      </van-cell-group>
    </div>
    <footer class="footers">
      <div>应付：{{ rendered.price + '元' }}</div>
      <div class="sty" :style="{'background-color':'#'+required.skin}" @click="doNow">立即支付</div>
    </footer>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
import {reactive, onMounted} from 'vue'
import {showOrder} from "@/api"
// import {newPage, registerWindowEvent, setNavColor} from "@/utils/setupBridge"
import {WebViewBridge} from '@/utils/bridge'
import {setUrlParam, mapCacheParameters} from "@/utils/tool"
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {paymentAPP, setBeeCloudButton} from "@/utils/payment"
import {useOrderListStore} from "@/store/modules/订单详情/orderList";
import {useChoosePlanStore} from "@/store/modules/ChoosePlan";

export default {
  name: "orderDetail",
  setup(){
    const webViewBridge = new WebViewBridge()

    const fourGFlowStore = useFourGFlowStore()
    //路由对象
    const router = useRouter()
    //必要参数 渲染参数
    let {defaultParams: required,renderedParams: rendered} = fourGFlowStore
    const orderListStore = useOrderListStore()
    const choosePlanStore = useChoosePlanStore()

    //APP事件
    const reloadEvent = () => {
      webViewBridge.registerWindowEvent(() => {
        const skin = required.skin
        webViewBridge.setNavColor(skin)
      })
    }

    //业务逻辑
    const business = async () => {
      //系统语言
      const language = required.language
      //订单ID
      const {selectedOrderId:orderId} = orderListStore
      //查询订单信息
      console.warn('selectedOrderId', orderListStore)
      const data = await showOrder(orderId, language)
      // if (err) return console.warn('查询订单信息异常', err)
      console.warn('订单信息', data)
      const {target,title,price} = data
      required.iccId = target
      rendered.title = title
      rendered.price = price
      rendered.info = data
    }

    //立即支付
    const doNow = () => {
      console.warn('立即支付')
      //必需参数
      const {oemId,userId,iccId,scenes,H5pay} = required
      const {info} = rendered
      //订单参数
      const { price,title,transaction_fee} = info
      const { selectedOrderId:orderId } = orderListStore
      const { productId } = choosePlanStore      //预购套餐产品ID
      console.log("orderId、scenes、H5pay",orderId,scenes,H5pay);
      //支付
      if (scenes === 'APP') {
        if (H5pay === '1' || H5pay === '2') {
          toPay()
        }else {
          //使用APP的支付
          paymentAPP(false, orderId, productId, userId, price, transaction_fee, title, iccId)
        }
      } else {
        //跳转到H5支付页面
        toPay()
      }
    }

    //跳转支付页
    const toPay = () => {
      Go('pay')
    }

    //页面跳转
    const Go = (route) => {
      const scenes = required.scenes
      const url = setUrlParam('/' + route, "")
      console.warn(scenes, 'to ' + route, url)
      if (scenes === 'APP') {
        webViewBridge.newPage(url)
      } else {
        router.push({path: route})
      }
    }

    //返回上一页
    const toBack = () => {
      router.go(-1)
    }

    //组件已安装
    onMounted(async () => {
      console.log('onMounted')

      await webViewBridge.init()

      //设置beeCloud支付按钮
      setBeeCloudButton(required.oemId)

      mapCacheParameters(required)

      const scenes = required.scenes
      //检测环境
      if (scenes === 'APP') {
        console.warn('APP环境')
        //加载APP事件
        reloadEvent()
        //业务逻辑
        business()
      } else {
        console.warn('H5环境')
        //业务逻辑
        business()
      }
    })

    return{
      required,
      rendered,
      orderListStore,
      doNow,
      toBack
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #EDEEEF;
  text-align: left;
}

.box_li div:nth-child(2) {
  margin-right: 20px;
}

.footers {
  width: 100%;
  height: 80px; /* footer的高度一定要是固定值*/
  /*position: absolute;*/
  /*bottom: 0px;*/
  /*left: 0px;*/
  position: fixed;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sty {
  width: 120px;
  height: 30px;
  background-color: #F7B81C;
  border-radius: 18px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.box_bottom {
  width: 100%;
  height: 200px;
}

.di {
  height: 100px;
}

/deep/ .van-nav-bar__title {
  color: white;
}

/deep/ .van-nav-bar__text {
  color: white;
}

/deep/ .van-icon-arrow-left::before {
  color: white;
}
</style>