import request from "@/utils/request";
import {
  cameraActivationType,
  couponType
} from "/#/api";

//券样式
export function couponShow(data: couponType) {
  return request({
    url: 'https://coupon-api.eye4.cn/coupon/show',
    method: 'post',
    data
  })
}

// 激活
export function cameraActivation(data: cameraActivationType) {
  return request({
    url: 'https://coupon-api.eye4.cn/coupon/action',
    method: 'post',
    data
  })
}