/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { ElNotification } from 'element-plus'
const isTestServer = window.location.host.includes("h5_test.eye4.cn")

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('Service worker has been ready.')
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'
      // )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.1')
      if( isTestServer ){
          ElNotification({
          title: 'Warning',
          message: '服务器已更新，正在同步中...',
          type: 'warning',
        })
      }
    },
    updated () {
      console.log('确实有更新')
      // window.localStorage.clear()
      window.location.reload();
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
