<template>
  <el-row
      :id=props.type
      class="echarts"
  />
</template>

<script>
import {onMounted, watch} from "vue";
import * as echarts from "echarts";

export default {
  name: "EChart",
  props: {
    type: {
      type: String,
      required: true,
    },
    chartInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    let myChart = null

    onMounted(async () => {
      //需要获取到element,所以是onMounted的Hook
      myChart = echarts.init(document.getElementById(props.type))
      // 绘制图表
      window.onresize = function () {
        //自适应大小
        myChart.resize()
      }
      drawEchart()
    })

    watch(props.chartInfo, (nweProps, oldProps) => {
      drawEchart()
    });

    const drawEchart = () => {
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          data: props.chartInfo.XAxis,
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} ',
            },
          },
        ],
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series: {
          name: props.chartInfo.title,
          type: 'bar',
          // barWidth: '60%',
          data: props.chartInfo.Series
        }
      })
    }


    return {
      props,
      drawEchart
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 30vh;
}
</style>