export default {
    '确认关闭':'ยืนยันการปิด',
    '取消': 'ยกเลิก',
    "请稍等": "กรุณารอสักครู่",
    "试用中": "กำลังทดลองใช้",
    "动态云录像": "บันทึกแบบไดนามิกทาง cloud",
    "全天云录像": "บันทึกทาง cloud ทั้งวัน",
    "连续包月": "รายเดือนต่อเนื่อง",
    "首推": "แนะนำอันดับแรก",
    "新用户推荐": "แนะนำสำหรับผู้ใช้ใหม่",
    "循环存储7天": "บันทึกเป็นวงจร 7 วัน",
    "循环存储30天": "บันทึกเป็นวงจร 30 วัน",
    "循环存储15天": "บันทึกเป็นวงจร 15 วัน",
    "循环存储3天": "บันทึกเป็นวงจร 3 วัน",
    "超80%人选择": "คนเกิน 80% เลือก",
    "连续半年": "ต่อเนื่องทุก 6 เดือน",
    "连续包年": "รายปีต่อเนื่อง",
    "包年": "รายปี",
    "包半年": "ราย 6 เดือน",
    "包月": "รายเดือน",
    "新用户首选": "ช้อยส์อันดับแรกสำหรับผู้ใช้ใหม่",
    "尝鲜": "ลองของใหม่",
    "家用省电套餐": "แพ็กเกจประหยัดไฟสำหรับครอบครัว",
    "超长周期套餐": "แพ็กเกจวงจรยาว",
    "看店必备套餐": "แพ็กเกจต้องลองสำหรับการเฝ้าร้าน",
    "到期后自动续费，可随时取消": "ต่ออายุโดยอัตโนมัติเมื่อหมดอายุ สามารถยกเลิกทุกเมื่อ",
    "自动续费协议": "ข้อตกลงในการต่ออายุอัตโนมัติ",
    "消息/视频无上限": "ไม่จำกัดจำนวนข้อความ / วิดีโอ",
    "云存储购买协议": "ข้อตกลงในการซื้อบริการบันทึกข้อมูลทาง Cloud",
    "动态云录像.png": "DTYLX_tha.png",
    "imageHeight": "310px",
    "已优惠": "ลดราคาแล้ว",
    "AI智能服务": "บริการ AI อัจฉริยะ",
    "未开通": "ยังไม่เปิด",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "เมื่อซื้อ \"บริการ AI อัจฉริยะ\" แล้ว จะเปิดฟังก์ชันบันทึกข้อมูลทาง Cloud เฝ้าสังเกตพื้นที่เลือกเอง เสียงเตือนภัยที่เป็นเอกลักษณ์ให้หมด! ยังมีบริการรู้จำสิ่งของแบบอัจฉริยะ(รู้จำพัสดุ สัตว์เลี้ยง รถยนต์)ที่จะมาในเร็ว ๆ นี้",
    "30天超长云存储": "บริการบันทึกข้อมูลทาง Cloud นาน 30 วัน",
    "报警和推送声个性化": "เสียงเตือนภัยและเสียงแจ้งเตือนที่เป็นเอกลักษณ์",
    "报警区域自定义": "เลือกพื้นที่เตือนภัยเอง",
    "人形追踪": "ติดตามร่างกายคน",
    "摄像头会实时跟踪人的行动轨迹": "กล้องจะติดตามเส้นทางการเดินของคนแบบเรียลไทม์",
    "人形框定": "กรอบใบหน้า",
    "在实时视频里面会自动框出并跟踪所出现的人": "ในวิดีโอเรียลไทม์จะกรอบและติดตามคนที่ปรากฏตัวโดยอัตโนมัติ",
    "AI智能服务功能介绍": "แนะนำฟังก์ชันบริการ AI อัจฉริยะ",
    "免费服务 VS Al智能服务": "บริการฟรี VS บริการ AI อัจฉริยะ",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "เลื่อนเวลาบันทึกให้นานสุด 30 วัน (มี 6 แพ็คเกจไว้ให้เลือก) ให้บริการบันทึกข้อมูลทาง Cloud  ที่เข้ารหัสระดับการเงิน ทำให้คุณไม่พลาดโมเมนต์ดี ๆ ทุกโมเมนต์",
    "循环存储": "บันทึกข้อมูลเป็นวงจร",
    "无": "ไม่มี",
    "30天": "30 วัน",
    "15天": "15 วัน",
    "3天": "3 วัน",
    "报警推送": "แจ้งเตือนการเตือนภัย",
    "100条": "100 ข้อความ",
    "无上限！": "ไม่จำกัด",
    "视频时长": "เวลาวิดีโอ",
    "最长60秒": "ยาวสุด 60 วินาที",
    "报警声个性化": "เสียงเตือนภัยที่เป็นเอกลักษณ์",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "เสียงเตือนภัย / เสียงประกาศของกล้อง เสียงแจ้งเตือนโทรศัพท์มือถือ สามารถตั้งค่าให้เป็นเอกลักษณ์ได้หมด เรามีเสียงให้เลือกในตัวที่หลากหลาย และคุณยังสามารถบันทึกเสียงที่คุณต้องการ",
    "监测区域自定义": "เลือกพื้นที่ตรวจจับเอง",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "สามารถตั้งค่าพื้นที่ตรวจจับพื้นที่เดียวหรือหลายพื้นที่ จับภาพพื้นที่ที่คุณสนใจเท่านั้น เพื่อลดการเตือนภัยที่ไม่จำเป็น เมื่อมีการเตือนภัยเกิดขึ้น คุณก็จะได้รู้ตำแหน่งแน่ชัด",
    "AI智能通知(待开放)": "แจ้งเตือนอัจฉริยะ (มาเร็ว ๆ นี้)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "ใช้เทคโนโลยีรู้จำแบบ AI อัจฉริยะระดับสูง ภาพที่รู้จำได้มี สัตว์เลี้ยง รถยนต์ พัสดุ และสามารถวิเคราะการเคลื่อนไหวของสิ่งเหล่านี้ ทำให้การแจ้งเตือนแม่นยำและอัจฉริยะมากขึ้น",
    "已了解，去购买": "รู้แล้ว ไปซื้อ",
    "已开通": "เปิดแล้ว",
    "10秒/每条": "10 วินาที / ข้อความ",
    "3天\n(100条/天，10秒/条) ": "3 วัน (100 ข้อความ / วัน 10 วินาที / ข้อความ)",
    "7/30天\n (消息/视频无上限)": "7 / 30 วัน (ไม่จำกัดจำนวนข้อความ / วิดีโอ)",
    "30天\n (消息/视频无上限)": "30 วัน (ไม่จำกัดจำนวนข้อความ / วิดีโอ)",
    "距离服务到期": "จากเวลาหมดอายุบริการ",
    "永久": "ถาวร",
    "体验": "ทดลองใช้",
    "监测区域": "พื้นที่ตรวจจับ",
    "报警推送100条/天": "แจ้งเตือน 100 ข้อความ / วัน",
    "视频时长10秒": "วิดีโอยาว 10 วินาที",
    "7天云存储": "บันทึกทาง Cloud 7 วัน",
    "30天云存储": "บันทึกทาง Cloud 30 วัน",
    "1个月": "1 เดือน",
    "6个月": "6 เดือน",
    "12个月": "12 เดือน",
    "循环存储7天（使用时间6个月）": "บันทึกเป็นวงจร 7 วัน (ระยะเวลาใช้งาน 6 เดือน)",
    "查看详情": "ดูรายละเอียด",
    "安全": "ปลอดภัย",
    "便捷": "สะดวก",
    "贴心": "ใส่ใจ",
    "云存储特权": "สิทธิพิเศษการบันทึกข้อมูลทาง Cloud",
    "云端保护": "การปกป้องทาง Cloud",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "วิดีโอทาง Cloud ทุบไม่แตก ขโมยไม่ได้ ป้องกันความปลอดภัยของไฟล์",
    "隐私加密": "เข้ารหัสข้อมูลส่วนตัว",
    "金融级数据加密方案，保护隐私更\n安全": "แผนการเข้ารหัสข้อมูลระดับการเงิน ทำให้ข้อมูลของคุณปลอดภัยยิ่งขึ้น",
    "存储灵活": "บันทึกข้อมูลอย่างยืดหยุ่น",
    "同时保存报警录像和视频，不错过任\n    何动态": "บันทึกคลิปเตือนภัยและวิดีโอพร้องกัน ไม่พลาดทุกการเคลื่อนไหว",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "ราคาจะมีการปรับเปลี่ยนตามประเทศและพื้นที่ที่คุณล็อกอิน เนื่องจากนโยบายภาษีและอัตราแลกเปลี่ยนที่แตกต่างกันตามพื้นที่",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "ฟังก์ชันบริการอัจฉริยะจะเกิดผลภายใต้สิทธิ์แอดมินของกล้องเท่านั้น ผู้ใช้ที่ผู้แชร์จะใช้งานตามสิทธิ์แอดมินอุปกรณ์ได้",
    "付款：确认购买并付款后记入iTunes 账户": "การชำระเงิน: ยืนยันการซื้อและชำระเงินแล้ว จะบันทึกเข้าบัญชี iTunes",
    " 去支付": "ไปชำระ",
    "7天    ": "7 วัน",
    "60天": "60 วัน",
    "有效日期至": "วันที่หมดอายุ",
    "已到期": 'หมดอายุ',
    "可升级": 'Upgradeable',
    '云视频录像':'บันทึกวิดีโอบนคลาวด์',
    '关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长':'หลังจากปิดแล้วเมฆจะไม่บันทึกวิดีโอของคุณ และไม่มีเวลาเพิ่มเติมสำหรับบริการจัดเก็บคลาวด์ในปัจจุบัน',
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "เมื่อภาพเรียลไทม์มีการเคลื่อนไหว อุปกรณ์จะเริ่มบันทึกวิดีโอโดยอัตโนมัติและส่งไปที่ทาง Cloud ข้อมูลเสียงบันทึกจะผ่านการเข้ารหัสระดับการเงิน เพื่อป้องกันความปลอดภัยของข้อมูล",
    "去支付": "ไปจ่าย",
    "天": "วัน",
    "首选": "เฟิร์ส",
    "超过80%人选择": "เลือกคนมากกว่า",
    "存储周期": "วงจรหน่วยความจำ",
    "去续费": "ไปต่ออายุ",
    "云存储录像邀您试用": "วิดีโอจัดเก็บข้อมูลเมฆขอเชิญให้คุณลอง",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "คุณจะได้รับหนึ่งเดือนทดลองใช้ฟรีนานไม่กลัวที่จะสูญเสียวิดีโอของคุณ",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "เฉลี่ยรายวัน",
    "元": "RMB",
    "省": "Save",
    "推荐": "แนะนำ",
    "请等待": "กรุณารอ",
    "激活": "เปิดใช้งาน",
    "支持": "สนับสนุน ",
    "不支持": "ไม่สนับสนุน",
    "年卡": "รายปี",
    "半年卡": "ราย 6 เดือน",
    "月卡": "รายเดือน",
    "暂不支持，敬请期待": "ไม่ได้รับการสนับสนุนในขณะนี้ดังนั้นโปรดคอยติดตาม",
    "卡片暂不支持充值": "บัตรไม่รองรับการเติมเงินในขณะนี้",
    "HI~有什么需要查询的吗？": "Hi～ มีอะไรที่ต้องการความช่วยเหลือในการสอบถาม?",
    "更多热门问题为你解答": "คำถามยอดนิยมเพิ่มเติมสำหรับคุณ",
    "热门分类": "หมวดหมู่ยอดนิยม",
    "添加设备": "เพิ่มอุปกรณ์",
    "监控异常": "การตรวจสอบความผิดปกติ",
    "操作视频": "วิดีโอการดำเนินงาน",
    "更多其他": "อื่น ๆ อีกมากมาย",
    "常见问题": "คำถามที่พบบ่อย",
    "在线客服": "บริการลูกค้าออนไลน์",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}