<script>
import Bridge from "@/utils/bridgeAsync";
import {useRouter, useRoute} from "vue-router";

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const {query: {code}} = route
    if (!code) throw new Error('参数缺失')
    const debug = false
    const process = async () => {
      const bridge = new Bridge()
      await bridge.init()
      const {uid, authkey, language} = await bridge.userInfo()
      console.log("当前语言是：",language)
      localStorage.setItem("language", language)
      if (debug) {
        const url = new URL('http://127.0.0.1:8080/coupons/activate')
        url.searchParams.set('uid', uid)
        url.searchParams.set('authkey', authkey)
        url.searchParams.set('language', language)
        url.searchParams.set('code', code)
        return console.log(url.href)
      }
      await router.push({path: '/coupons/activate', query: {uid, authkey, code, language}})
      return '客户端初始化成功'
    }
    process()
        .catch(err => {
          //跳转到不支持的页面
          console.log(err)
        })
  }
}
</script>