<template>
  <div style="background:#F9F9F8;padding-top: 10px">
    <div style="background: white;padding: 0 30px;margin-top: 10px">
    <el-collapse>
      <el-collapse-item class="collapseItem" :style="{display: item.nameInfo[language] ? 'block' : 'none'}" v-for="item in FAQStore.titleObj[titleClass]" :name="item._id" :title="item.nameInfo[language]"
                        @click="goDescription(titleClass,item._id);FAQStore.activeList.length = 0">
      </el-collapse-item>
    </el-collapse>
    <el-empty :image-size="180" description="暂无评论" v-if="FAQStore.titleObj[titleClass] && !FAQStore.titleObj[titleClass].length"/>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {onMounted, ref} from "vue";
import {ElMessage} from 'element-plus'
import {useFAQStore} from "@/store/modules/FAQ";
import {useRoute} from "vue-router";
export default {
  name: "titleListPage",
  setup() {
    const route = useRoute()
    const FAQStore = useFAQStore()

    const titleClass = ref("")
    titleClass.value = route.query.title
    const language = FAQStore.from.actionInfo.language

    onMounted(async ()=>{
      if(!titleClass.value)
        await router.push({path: "/FAQ", replace: true})
      if (!FAQStore.titleObj[titleClass.value])
        await FAQStore.fetchTypeTitleList(titleClass.value)
    })

    const enterFeedback = async (id) => {
      await FAQStore.getNextProblemList(id) ?
          await router.push({path: '/FAQ/informationCollection'}) :
          ElMessage({
            dangerouslyUseHTMLString: true,
            message: '<p style="line-height:20px">抱歉！问题<strong>未完善</strong>，请选择其他问题</p>',
            type: 'warning',
            center: true,
            duration: 5000,
          })
    }

    const goDescription = (typeName,id) => router.push({path: '/FAQ/description',query: {typeName,id}})

    return{
      enterFeedback,
      goDescription,
      titleClass,
      FAQStore,
      language
    }
  }
}
</script>

<style lang="scss">
.el-collapse {
  border-top: none;
}
.collapseItem{
  .el-collapse-item__header{
    line-height: normal;
  }
}
</style>