<template>
  <div style="text-align:left">
    <h2 style="text-align:center;margin-bottom:0" @dblclick="hideEnter">4G流量购买协议</h2>
    <div style="padding:0 10px;margin-bottom: 20px">
      <p>本协议由用户（以下简称“甲方”）和深圳市维拍物联智能技术有限公司（以下简称“乙方”）签订。</p>

      一、乙方按照本协议规定向甲方提供4G流量（以下简称：本服务）：主要包括SIM卡提供的4G流量。具体服务内容以甲方实际购买的服务项目为准，乙方有权不断更新以上服务内容。<br />
      二、甲方知晓并明确表示同意：乙方变更4G流量或协议内容的，将在乙方服务平台——{{ appName }}APP上以公告的方式予以公布，无需另行单独通知甲方。若甲方在本协议内容公告变更后继续使用本服务，表示甲方已充分阅读、理解、接受并将遵守修改后的协议内容；若甲方不同意修改后的内容，视为同意终止使用本服务。<br />
      三、甲方承诺：自甲方向乙方申请使用本服务时直至本协议的签订履行完毕的全过程中，甲方具有法律规<br />
      定的独立的民事权利能力和民事行为能力，不具备前述条件的，应立即终止注册或终止使用本服务；否则，乙方有权直接终止本协议，并追究甲方相应责任。<br />
      四、甲方承诺并声明在使用本服务时符合所在地法律的相关规定，不得以履行本协议名义从事其他违反中国及所在地法律规定的行为。并且保证不利用本服务侵犯任何第三方（包括但不限于公司，社会组织，个人，未成年人等）的合法权益，也不违反任何法律法规、部门规章或政策性规范，并确保内容合法。<br />
      五、甲方使用本服务应当按照实际使用的服务套餐向乙方缴纳服务费用。乙方提供详细服务套餐及资费说明，并同时在订购页面列明公示，甲方可自行选择具体服务套餐并按照公示的价格予以支付。本服务为在线使用的数字化商品服务，一经购买成功后，均不提供退换货、退款，同时乙方保留调整本服务收费标准及/或销售方式的权利。<br />
      六、4G流量套餐相关内容说明<br />
      1、{{ appName }}4G流量服务相关内容说明{{ appName }}4G流量服务有连续季度组、连续半年租、月租、季度租、年租和两年租六种套餐，一个套餐订单只供一台设备使用。每次购买4G流量套餐，乙方会给甲方充值一个4G流量套餐，无论设备是否开机，甲方设备中的SIM卡的流量套餐都会生效，并且可以使用4G流量正常观看实时直播、云存储，正常访问设备和相关的数据。4G流量套餐在结算日时间内正常使用，结算日到期时后，SIM卡会过期，过期后，无论4G流量是否使用完，该SIM都无法正常使用，剩余的4G流量也不做返还。例如：SIM卡结算日为2022年07月24日，在当天的24:00时，SIM卡到期。App在结算日到期前15天，会在首页弹窗提示；4G流量低于1G时，会在首页弹窗提示。<br />
      七、续费服务说明<br />
      当甲方购买的4G流量套餐还未到期，在该套餐设备上继续选购了另一个套餐，即完成了续费服务。续费服务可让甲方在上一个套餐用完后自动使用第二个套餐，并按照第二个套类型延续服务。若甲方在一个套餐使用完之后才购买了另一个套餐，购买情况不属于续费服务，不能延续服务只能分别按套餐使用。<br />
      八、如发生下列任何一种情形，我方有权无需通知用户而随时中断或终止提供本服务，对因此而产生的不便或损害，我方对用户或第三人均不承担任何责任：<br />
      （1）定期检查或施工，更新软硬件等；<br />
      （2）服务器遭受损坏，无法正常运作；<br />
      （3）突发性的软硬件设备与电子通信设备故障；<br />
      （4）网络提供商线路或其它故障；<br />
      （5）在紧急情况之下为维护国家安全或其它用户及第三者之人身安全；<br />
      （6）不可抗力及其他第三方原因。<br />
      九、购买并使用4G流量，即表示甲方已经接受本应用的《隐私政策》和《用户协议》。如您需要回看隐私相关协议，可前往“我的”进入“关于”，点击《隐私政策》和《用户协议》了解详情。<br />
      此协议共九条，甲方确认购买4G流量即表示甲方已经仔细阅读、充分理解并接受本协议的所有条款。本协议对甲方构成有效的、带有约束力的、可强制执行的法定义务，甲方对本协议下所有条款及定义等内容均已明确知悉，并无异议。<br />
    </div>
  </div>
</template>

<script>
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {ref,onMounted} from "vue";
const fourGFlowStore = useFourGFlowStore()
export default {
  name: "purchaseAgreement",
  setup() {
    let appName = ref('')
    onMounted(async ()=>{
      await fourGFlowStore.initBridge()
      fourGFlowStore.webViewBridge.callHandler('getOEMID', "", (data) => {
        //获取客户端编号
        if(data === 'OKAM') appName.value = "小鹰看看（O-KAM）"
        else if(data === 'AKCXKJ') appName.value = "FOWL"
        console.warn("OEMID:", data)
      })
    })
    const hideEnter = () =>{
      fourGFlowStore.webViewBridge.newPage("https://h5_test.eye4.cn/index")
    }
    return{
      hideEnter,
      appName
    }
  }
}
</script>

<style scoped>

</style>