<template>
  <div class="scroll-container">

    <selectListItem/>

    <div>
      <div class="switch-box" v-if="showSwitch">
        {{ translateTitle('云视频录像') }}
        <van-switch v-model="activationSwitch" size="20px" active-color="#4ABED7"
                    @click="changeAction"/>
      </div>
      <div class="switch-box-msg" v-if="showSwitch">
        <span>{{ translateTitle('关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长') }}</span>
      </div>
      <div class="serverDetail" @click="goIntroduce"
           :style="{backgroundImage: 'url(' + imageUrl + ')',height: translateTitle('imageHeight')}"></div>

      <div class="purchase-agreement" @click="renewalManagerBtn">
        {{ translateTitle('云存储购买协议') }}
        <van-icon name="arrow"/>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineAsyncComponent, reactive, toRefs} from "vue"
import router from "@/router";
import {translateTitle} from "@/utils/i18n"
import {useCloudStorageStore} from "@/store/modules/cloudStorage"
import {setUrlParam} from "@/utils/tool"
import { Dialog } from 'vant'

export default {
  name: "serverDetail",
  components: {
    selectListItem: defineAsyncComponent(() => import('@/components/selectListItem'))
  },
  setup() {
    const cloudStorageStore = useCloudStorageStore()

    const state = reactive({
      checked: true,
      imageUrl: require('@/assets/' + translateTitle('动态云录像.png'))
      // imageUrl: require('@/assets/DTYLX_zh.png')
    })

    const renewalManagerBtn = () => {
      GO("/YCC_purchaseAgreement")
    }

    const GO = (path) => {
      const url = setUrlParam(path, '')
      if (cloudStorageStore.webViewBridge.bridge) {
        console.warn("新页面", url)
        cloudStorageStore.webViewBridge.newPage(url)
      } else {
        router.push({
          path: path
        })
      }
    }

    const activationSwitch = computed(() => {
      return cloudStorageStore.activation === "1"
    })

    const showSwitch = computed(() => {
      return (cloudStorageStore.ComboType === 'fixedPlan' || cloudStorageStore.ComboType === 'iapPlan') && cloudStorageStore.status
    })

    const goIntroduce = () => {
      // GO("/advantage")
      if( process.env.NODE_ENV === "development" ){
        console.warn("开发模式")
      }else{
        console.warn("生成模式",window.location.host + "/introduction")
        window.location.href = "/introduction"
      }
    }

    const changeAction = () => {
      if(activationSwitch.value){
        Dialog.confirm({
          title: translateTitle('云视频录像'),
          message: translateTitle('关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长'),
          confirmButtonText: translateTitle('确认关闭'),
          confirmButtonColor: '#71c4ce',
          cancelButtonText: translateTitle('取消')
        })
        .then(() => {
          cloudStorageStore.changeActivation()
        })
        .catch(() => {
          // on cancel
        });
      }else{
        cloudStorageStore.changeActivation()
      }
    }

    return {
      ...toRefs(state),
      renewalManagerBtn,
      translateTitle,
      cloudStorageStore,
      activationSwitch,
      showSwitch,
      goIntroduce,
      changeAction
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-container {
  //height: 440px;
  //overflow-y: scroll;
  background: #F2FAFC;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.privilege-Introduction {
  background: white;
  padding: 20px;
  margin-bottom: 10px;

  .p-i-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .p-i-icons-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-item {
      width: 50%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
    }

    &-icons {
      margin-bottom: 5px;
      width: 48px;
      height: 48px;
    }
  }

  .p-i-icon-box {
    width: 100%;
    height: 195px;
    //background: url('../../../../public/img/动态云录像.png') no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 61%;
  }
}

.server-detail {
  //background: url('../../../public/img/4Gbg.png') no-repeat;
  //background-size: cover;
  //background:linear-gradient(to right, #E7F6FA, #DBF8FC);
  background: #F2FAFC;
  overflow: hidden;
  height: 105px;
  width: 100%;
  border-radius: 0 0 50% 50% / 0 0 15% 15%;

  &-item {
    width: 33%;
    //max-width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  &-title {
    width: 90%;
    margin: 20px auto 0 auto;
    height: 90px;
    background: linear-gradient(to right, #E7F6FA, #DBF8FC);
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 30px;
  }

  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}

.title-left-box, .title-right-box {
  margin: 0 10px;
  display: inline-block;
  width: 15%;
  height: 3px;
  background: linear-gradient(to right, rgb(255, 255, 255), #B7914C)
}

.title-right-box {
  background: linear-gradient(to right, #B7914C, rgb(255, 255, 255))
}

.switch-box {
  padding: 15px 15px 5px;
  box-shadow: 0 1px 2px 0 #e1e1e1;
  font-size: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  font-weight: bold;
}

.switch-box-msg {
  padding: 0 15px 15px;
  background: #fff;
  font-size: 12px;
  color: #939191;
}

.serverDetail {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.automatic-renewal, .purchase-agreement {
  padding: 15px;
  font-size: 14px;
  text-align: left;
  color: #bdbdbd
}


</style>