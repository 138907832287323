export default {
    "请稍等": "请稍等",
    "AI智能服务": "AI智能服务",
    "订单": "订单",
    "连续包月开通说明": "连续包月开通说明",
    "到期前一天为您自动续费": "到期前一天为您自动续费",
    "到期前一天，可随时取消自动续费服务": "到期前一天，可随时取消自动续费服务",
    "取消自动续费服务路径": "取消自动续费服务路径",
    "AI智能服务 - 订单 - 续费订单": "AI智能服务 - 订单 - 续费订单",
    "取消": "取消",
    "确定开通": "确定开通",
    "去续费": "去续费",
    "已优惠": "已优惠",
    "动态云录像": "动态云录像",
    "全天云录像": "全天云录像",
    "门口摄像头": "门口摄像头",
    "存储周期": "存储周期",
    "距离服务到期": "距离服务到期",
    "天": "天",
    "自动续费协议": "自动续费协议",
    "云存储购买协议": "云存储购买协议",
    "连续包月": "连续包月",
    "循环存储7天": "循环存储7天",
    "新用户推荐": "新用户推荐",
    "首选": "首选",
    "超过80%人选择": "超过80%人选择",
    "看店必备套餐": "看店必备套餐",
    "超长周期套餐": "超长周期套餐",
    "续费管理": "续费管理",
    "设备ID": "设备ID",
    "订单编号": "订单编号",
    "支付金额": "支付金额",
    "元": "元",
    "24小时全天云录像": "24小时全天云录像",
    "未支付": "未支付",
    "已支付": "已支付",
    "动态云录像.png": "DTYLX_zh.png",
    "imageHeight": "334px",
    "续费管理.png": "XFGL_zh.png",
    "奥凯续费管理.png": "OK_XFGL_zh.png",
    "未开通": "未开通",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。",
    "30天超长云存储": "30天超长云存储",
    "报警和推送声个性化": "报警和推送声个性化",
    "报警区域自定义": "报警区域自定义",
    "人形追踪": "人形追踪",
    "摄像头会实时跟踪人的行动轨迹": "摄像头会实时跟踪人的行动轨迹",
    "人形框定": "人形框定",
    "在实时视频里面会自动框出并跟踪所出现的人": "在实时视频里面会自动框出并跟踪所出现的人",
    "AI智能服务功能介绍": "AI智能服务功能介绍",
    "免费服务 VS Al智能服务": "免费服务 VS Al智能服务",
    "云存储": "云存储",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。",
    "循环存储": "循环存储",
    "无": "无",
    "30天": "30 天",
    "15天": "15 天",
    "3天": "3 天",
    "报警推送": "报警推送",
    "100条": "100条",
    "无上限！": "无上限！",
    "视频时长": "视频时长",
    "最长60秒": "最长60秒",
    "报警声个性化": "报警声个性化",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。",
    "监测区域自定义": "监测区域自定义",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。",
    "AI智能通知(待开放)": "AI智能通知(待开放)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。",
    "已了解，去购买": "已了解，去购买",
    "已开通": "已开通",
    "10秒/每条": "10秒/每条",
    "3天\n(100条/天，10秒/条) ": "3天\n(100条/天，10秒/条) ",
    "7/30天\n (消息/视频无上限)": "7/30天\n (消息/视频无上限)",
    "30天\n (消息/视频无上限)": "30天\n (消息/视频无上限)",
    "永久": "永久",
    "体验": "体验",
    "监测区域": "监测区域",
    "报警推送100条/天": "报警推送100条/天",
    "视频时长10秒": "视频时长10秒",
    "7天云存储": "7天云存储",
    "30天云存储": "30天云存储",
    "1个月": "1个月",
    "6个月": "6个月",
    "12个月": "12个月",
    "循环存储7天（使用时间6个月）": "循环存储7天（使用时间6个月）",
    "查看详情": "查看详情",
    "安全": "安全",
    "便捷": "便捷",
    "贴心": "贴心",
    "云存储特权": "云存储特权",
    "云端保护": "云端保护",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "录像云端存储，砸不坏，偷不走，保护\n数据安全",
    "隐私加密": "隐私加密",
    "金融级数据加密方案，保护隐私更\n安全": "金融级数据加密方案，保护隐私更\n安全",
    "存储灵活": "存储灵活",
    "同时保存报警录像和视频，不错过任\n    何动态": "同时保存报警录像和视频，不错过任\n    何动态",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用",
    "付款：确认购买并付款后记入iTunes 账户": "付款：确认购买并付款后记入iTunes 账户",
    " 去支付": " 去支付",
    "7天    ": "7天    ",
    "60天": "60天",
    "云存储开关": "云存储开关",
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。",
    " 去续费": " 去续费",
    "请等待": "请等待",
    "年卡": "年卡",
    "半年卡": "半年卡",
    "月卡": "月卡",
}