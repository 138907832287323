<template>
  <div style="text-align:left">
    <h2 style="text-align:center;margin-bottom:0">自动续费协议</h2>
    <div class="content">
      <p>1. 服务条款的接受与修改</p>
      <span>
        1.1 本协议是{{appName}}会员（下称“会员”）与深圳市维拍物联智能技术有限公司 （下称“维拍”）之间关于会员使用{{appName}}提供的自动续费委托扣款服务（下称“本服务”）所订立的协议，本协议描述维拍与会员之间关于本服务的使用及相关方面的权利义务。“会员”是指完成了成为维拍4G流量会员的全部程序，且在遵守维拍相关使用规则下使用维拍提供的会员服务的自然人。本协议构成会员使用维拍所提供的本服务之先决条件，维拍已经以字体加粗或其他合理方式提示会员重点阅读协议中相关免除或限制责任条款，双方确认前述条款不属于 《合同法》第40条规定的“免除其责任、加重对方责任、排除对方主要权利”条款，用户和维拍均认可其合法性及有效性。除非会员接受本协议条款，否则会员无权使用本服务，会员选择使用本服务行为将视为同意接受本协议各项条款的约束。
      </span>
      <span>
        1.2 维拍有权随时对服务条款进行修改一旦服务条款发生变更和修改，维拍将在相关页面上进行公告，且修改内容在公告之日的10日后生效和执行；如果会员不同意本协议的任一修改，可以取消自动续费功能；如果会员继续使用的，则视为会员已经接受本协议的全部修改。
      </span>
      <span>
        1.3 会员在使用本服务时须具备相应的权利能力和行为能力，能够独立承担法律责任，如果会员在18周岁以下，必须在父母或其他监护人的监护参与下才能使用本服务，其使用本服务的行为视为已获得了父母或其他监护人的认可。
      </span>

      <p>2. 服务说明</p>
      <span>
        2.1本服务是出于会员对于自动续费的需求，在会员已开通本服务的前提下，避免会员因疏忽或其他原因导致未能及时续费造成损失而推出的服务，会员授权维拍可在会员有效期即将过期时，委托支付渠道从会员的自有充值账户、与会员账号绑定的第三方支付账户、银行卡、通信账户（以下统称“账户”）余额中代扣下一个计费周期的费用，计费周期包括但不限于月度、季度、年度等（下同），会员可根据维拍平台上显示的计费周期自行选择。该服务实现的前提是会员已将其维拍会员账号与上述账户绑定，且可成功从其上述账户中扣款。
      </span>
      <span>
        2.2 自动续费具体指，基于2.1的前提，维拍通过上述账户收取会员下一计费周期费用的扣费方式，会员需保证维拍可以从上述账户扣款成功，因上述账户中可扣款余额不足导致的续费失败，由会员自行承担责任，维拍有权中断/终止相应的会员服务。
      </span>
      <span>
        2.3 会员选择开通自动续费，则视为同意授权维拍向账户发出扣款指令，并同意账户可以根据维拍发出的扣款指令，在不验证会员账户密码、支付密码、短信校验码等信息的情况下从账户中扣划下一个计费周期的费用。除非会员主动明确地取消了自动续费，否则，会员对维拍的自动扣款委托为不可撤销。除法律法规另有规定外，维拍不以任何方式对会员所支付的费用予以退还。
      </span>
      <span>
        2.4 会员在享受本服务时，应受《购买协议》《隐私政策》以及《用户协议》各项条款及相关子协议、页面说明或规范流程等全部内容的约束，当会员使用本服务时，会员的使用行为视为其对本服务的服务条款以及维拍针对本服务发出的各类公示的同意。本协议如与《用户协议》《购买协议》发生冲突的，以本协议为准。
      </span>
      <span>
        2.5 维拍向会员提供的本服务均仅限于会员在维拍平台使用，任何以恶意破解等非法手段将维拍提供的本服务与维拍平台分离的行为，均不属于本协议中约定的本服务。由此引起的一切法律后果由行为人负责，维拍将依法追究行为人的法律责任。
      </span>

      <p>3.双方的权利和义务</p>
      <span>
        3.1维拍负责向会员提供有关自动续费收费具体情况的查询方式，但不负责打印相关发票并/或转送会员。
      </span>
      <span>
        3.2 维拍将在会员有效期即将过期前以发送手机短信或其他方式向会员发送自动续费提示，通过会员上述账户扣除下一计费周期费用的，将在划扣当天将划扣款项记入会员支付记录，并同时相应延长会员有效期。一旦扣款成功，则维拍有权即时收取该费用，并不提供退费服务。
      </span>
      <span>
        3.3 如在扣费过程出现差错，维拍和会员应密切配合查明原因，各自承担己方过错造成的损失；若因双方各自存在不均等过错造成损失的，由双方按过错的程度承担相应责任；因双方共同过错造成损失但无法区分各自过错的，由双方均摊责任。
      </span>
      <span>
        3.4维拍可根据其业务开展或技术升级等情況变更或修改本协议的有关服务内容、规则及条款，维拍将通过在维拍相关页面或其他适当的位置进行公示的方式告知用户相关变更或修改内容，但无义务另行做个别通知。上述公示自维拍发送之日视为已送达用户，如多种通知方式并存的，则送达时间以上述方式中最早发送之时为准。如果会员不同意本协议的修改，可以取消已经获取的本服务并停止使用；如果会员继续使用维拍提供的服务，则视为会员已经接受本协议的全部修改。
      </span>
      <span>
        3.5 本服务由会员自主选择是否取消，若会员选择不取消，则视为会员同意维拍有权持续地按照一定规则进行不定期的扣款尝试一旦扣款成功，维拍将为会员开通下一个计费周期的会员服务。
      </span>
      <span>
        3.6 若在自动续费时/之前，维拍服务价格发生调整，应以扣款时维拍平台上公示的现时有效的价格为准
      </span>
      <span>
        3.7 维拍对会员开通本服务不收取任何额外手续费用，但维拍有权根据业务需要或市场变化等原因决定是否对本服务本身进行收费或调整自动续费周期及费用，并在相关页面向会员进行公示。
      </span>
      <span>
        3.8 会员在会员有效期内续费的，有效期将在原服务有效期基础上延长。
      </span>
      <span>
        3.9 会员理解并同意，会员授权维拍的代扣权限将视为会员本人的充值行为。如因此导致会员自身的任何损失，会员不会向维拍及其关联公司主张任何权利及追究任何责任。
      </span>

      <p>4. 协议有效期限、终止</p>
      <span>
        4.1 本协议自会员选择接受或使用本服务后生效，直至会员终止本服务/注销会员资格时终止，除非维拍另有公告。
      </span>
      <span>
        4.2 会员有权随时在会员账号设置中选择终止本服务，终止本服务后，维拍将停止向会员提供本服务
      </span>
      <span>
        4.3 会员在选择终止本服务前已经委托维拍自动续费扣款的指令仍然有效，维拍对于基于该指令已经扣除的费用不予退还，由会员承担相关责任与损失。
      </span>
      <span>
        4.4 维拍有权根据自身业务调整、价格变动等原因中止、终止本服务。一旦发生此种情形，维拍会以公告的方式通知会员。
      </span>

      <p>5.违约责任</p>
      <span>如果一方违约，守约方有权通过双方约定的违约解决方式获得补偿或赔偿。</span>

      <p>6.争议解决和法律适用</p>
      <span>维拍与会员应通过友好协商解决本协议履行过程中产生的争议，经协商无法解决的，任何一方均有权将争议提交至深圳市南山区人民法院诉讼解决。本协议的解释、效力和执行等有关问题均适用中华人民共和国法律。</span>

      <p>7.其他</p>
      <span>
        7.1如会员对本协议或使用维拍的自动续费服务相关的事宜有任何问题（包括问题咨询、投诉等）请通过功能页面的意见反馈入口或在线客服、app@vstarcam.com与维拍联系，维拍会在收到会员的意见、建议后尽快向会员回复。
      </span>
      <span>
        7.2本协议的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。
      </span>
      <span>
        7.3 本协议于首页更新时间的10日后生效。
      </span>

      <div style="margin-top: 24px;text-align:left">
        深圳市维拍物联智能技术有限公司<br />
        Copyright &copy;2022维拍 All Rights Reserved
      </div>

    </div>
  </div>
</template>

<script>
import {WebViewBridge} from "@/utils/bridge";
import {ref} from 'vue'

export default {
  name: "autoRenewalAgreement",
  setup() {
    const webViewBridge = new WebViewBridge()
    let appName = ref('')
    webViewBridge.init().then(()=>{
      webViewBridge.callHandler('getOEMID', "", (data) => {
        //获取客户端编号
        if(data === 'OKAM') appName.value = "小鹰看看（O-KAM）"
        else if(data === 'AKCXKJ') appName.value = "FOWL "
        console.warn("OEMID:", OEMID)
      });
    })
    return{
      appName
    }
  }
}
</script>

<style scoped lang="scss">
.content{
  padding:0 10px;margin-bottom: 20px;
  p{
    font-weight: bold;
  }
  span{
    text-indent: 24px;
    display: inline-block;
  }
}
</style>