import {setUrlParam} from "@/utils/tool";
import {ElMessage} from "element-plus";

export const WebViewBridge = class WebViewBridge {
    constructor() {
        this.bridge = null
        // this.init()
    }

    init() {
        let _this = this
        return new Promise((resolve, reject) => {
            let timer = setTimeout(() => {
                reject(false)
            }, 5000)
            this.setupWebViewJavascriptBridge(function (bridge) {
                _this.bridge = bridge;
                clearTimeout(timer)
                resolve(true);
            })
        })
    }

    setupWebViewJavascriptBridge(callback) {
        // 这里若是bridge存在则直接调用native方法并且return
        if (this.isIOS()) {
            if (window.WebViewJavascriptBridge) {
                return callback(window.WebViewJavascriptBridge)
            }
            if (window.WVJBCallbacks) {
                return window.WVJBCallbacks.push(callback)
            }
            window.WVJBCallbacks = [callback]
            let WVJBIframe = document.createElement('iframe')
            WVJBIframe.style.display = 'none'
            WVJBIframe.src = 'https://__BRIDGE_LOADED__'
            document.documentElement.appendChild(WVJBIframe)
            setTimeout(() => {
                document.documentElement.removeChild(WVJBIframe)
            }, 0)
        } else {
            if (window.WebViewJavascriptBridge) {
                callback(window.WebViewJavascriptBridge)
            } else {
                document.addEventListener('WebViewJavascriptBridgeReady', () => {
                    return callback(window.WebViewJavascriptBridge)
                }, false);
            }
        }
    }

    callHandler(name, data, callback) {
        this.bridge.callHandler(name, data, callback)
    }

    registerHandler(name, callback) {
        this.bridge.registerHandler(name, function (data, responseCallback) {
            callback(data, responseCallback)
        })
    }

    //判断ios环境
    isIOS() {
        const u = navigator.userAgent;
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    }

    //通过APP打开新页面
    newPage(url, callback) {
        this.callHandler('newPage', url, callback)
    }

    //显示系统提示窗口
    showMessage(text) {
        this.callHandler('showMessage', text, (res) => {
        });
    }

    //通知设备刷新授权信息
    set_update_push_user(did) {
        this.callHandler('set_update_push_user.cgi', did, (res) => {
            console.warn("已通知设备刷新授权信息", res)
        });
    }

    //注册窗口加载事件
    registerWindowEvent(callback) {
        this.registerHandler('WindowLoading', (data, responseCallback) => {
            console.warn('APP请求了WindowLoading事件')
            responseCallback('success');
            callback(data)
        });
    }

    //设置导航栏右上角按钮
    setNavRightBut(callback) {
        //设置是否显示4G充值（仅在首页调用）
        this.callHandler('configNavigationBarOrder', '1', (responseData) => {
            console.warn('通知APP configNavigationBarOrder')
            if (typeof callback === 'function') callback(responseData)
        })
    }

    //设置状态栏颜色
    setNavColor(skin, callback) {
        const color = "#" + skin
        this.callHandler('configNavigationBarColor', color, (data) => {
            if (typeof callback === 'function') callback(data)
        })
    }

    //替换字段
    replaceField(oemId, url) {
        setRedirect(['redirect_url=http://payservice.beecloud.cn/spay/result.php', 'redirect_url=api.eye4.cn://'], url)
        if (oemId === 'OEM' || oemId === 'VEEPAI') {
            setRedirect(['"fromAppUrlScheme":"alipays"', '"fromAppUrlScheme":"veepai"'], url)
        } else {
            if (oemId === 'FLFDZ') {
                setRedirect(['"fromAppUrlScheme":"alipays"', '"fromAppUrlScheme":"LAICAM"'], url)
            } else {
                setRedirect(['"fromAppUrlScheme":"alipays"', '"fromAppUrlScheme":"' + oemId + '"'], url)
            }
        }
    }

    //设置重定向
    setRedirect(data, url) {
        this.callHandler('redirect', data, (responseData) => {
        })
        this.callHandler('resumedPage', url, (responseData) => {
        })
    }

    //获取设备ID
    getDeviceId(callback) {
        this.callHandler('getDeviceId', "", (did) => {
            if (typeof callback === 'function') callback(did)
        });
    }

    //获取设备卡号信号强度
    deviceSignalStrength(iccId, callback) {
        this.callHandler('signalStrength', iccId, (responseData) => {
            const signalStrength = responseData && responseData !== 'success' ? responseData : '5'
            if (typeof callback === 'function') callback(signalStrength)
        })
    }

    //加载APP关键参数
    async reloadRequired(callback) {
        await this.callHandler('getUserid', "", (responseData) => {
            //查询用户ID
            let userId = responseData
            this.callHandler('getLanguage', "", (responseData) => {
                //查询系统语言
                let language = responseData.split('-')[0]
                this.callHandler('getIccid', "", (iccId) => {
                    //查询iccId
                    this.callHandler('getDeviceId', "", (did) => {
                        callback({userId: userId, language: language, iccId: iccId, did})
                    });
                })

            })
        })
    }

    //设置APP导航栏右侧订单按钮跳转数据
    setRightNavData(iccId, scenes, skin, oemId, H5pay) {
        const required = {iccId, scenes, skin, oemId, H5pay}
        const url = setUrlParam('/orderPage', required)
        console.warn('订单URL：', url)
        const params = [{'name': '订单', 'url': url}]
        //设置导航栏右侧链接
        this.callHandler('configNavigationBarRightButtons', params, (responseData) => {
            console.warn('设置导航栏右上角按钮成功', params)
        });
    }

    //设置APP导航栏右侧续费管理按钮跳转数据
    setRightNavRenewal() {
        // iccId, scenes, skin, oemId, H5pay
        const required = {}
        const url = setUrlParam('/RenewalManagement', required)
        console.warn('续费管理URL：', url)
        const params = [{'name': '续费管理', 'url': url}]
        //设置导航栏右侧链接
        this.callHandler('configNavigationBarRightButtons', params, (responseData) => {
            console.warn('设置导航栏右上角按钮成功', params)
        });
    }

    //获取头像
    getDeviceCover(did) {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => reject(new Error('获取头像超时')), 3000)
            this.callHandler('getDeviceCover', did, (responseData) => {
                clearTimeout(timeout)
                resolve(`data:image/jpeg;base64,${responseData}`)
            });
        });
    }

    //调用内部接口判断设备是否支持哭声检测
    isSupport(did) {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => reject(new Error('获取哭声检测超时')), 3000)
            this.callHandler('supportCloud', did, (responseData) => {
                clearTimeout(timeout)
                if (responseData === '1') {
                    resolve(true)
                } else {
                    resolve(false)
                }
                // resolve(`data:image/jpeg;base64,${responseData}`)
            });
        });
    }

    getCountryCode() {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => reject("CN"), 2000)
            this.callHandler('getCountryCode', '', (responseData) => {
                clearTimeout(timeout)
                resolve(responseData)
            });
        });
    }

    set_update_push_userAsync(did) {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => reject(new Error('调用超时')), 1000)
            this.callHandler('set_update_push_user.cgi', did, (res) => {
                clearTimeout(timeout)
                resolve(res)
            })
        })
    }

    //调用支付
    pay(orderID, productID) {
        const options = {orderID, productID}
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => reject(new Error('调用支付超时')), 1000)
            this.callHandler('pay', options, (responseData) => {
                clearTimeout(timeout)
                const json = JSON.parse(responseData);
                const {code} = json
                switch (code) {
                    case '401': //苹果交易失败
                    case '403': //服务器验证失败
                    case '404': //没查到产品key
                        reject(new Error('支付失败'))
                        break;
                    case '402': //用户取消交易
                        reject(new Error('支付取消'))
                        break;
                    case '200':  //交易成功
                        resolve()
                        break;
                    default:
                        resolve('未知异常')
                }
            });
        });
    }

    isSupportGoogle() {
        return new Promise((resolve, reject) => {
            console.warn("找google")
            this.callHandler('google', "", (responseData) => {
                if (responseData === '1') resolve(true)
                else reject(false)
            });
        })
    }

    //查询不同区域的内购产品
    queryProductInfo(area, array) {
        const groupID = `iap_productID_${area}`
        const queryProductInfo = {groupID, array}
        const _this = this
        console.log("queryProductInfo", queryProductInfo)
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                // ElMessage({
                //     showClose: true,
                //     message: '"APP环境错误！"',
                //     center: true,
                //     duration: 0,
                //     type: "error",
                // })
                const timer = setTimeout(() => reject(new Error('调用查询超时')),3000)

                _this.callHandler('queryProductInfo', queryProductInfo, (responseData) => {
                    console.log("responseData", responseData)
                    clearTimeout(timer)
                    resolve(responseData)
                });

            }, 3000)
            _this.callHandler('queryProductInfo', queryProductInfo, (responseData) => {
                console.log("responseData", responseData)
                clearTimeout(timeout)
                resolve(responseData)
            });
        })
    }
}