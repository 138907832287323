<template>
  <van-popup
      v-model:show="choosePlanStore.monthlyDescriptionShow"
      round
      position="bottom"
      :style="{ height: '40%'}"
  >
    <template #default>
      <p style="font-size:21px;font-weight:bold;text-align:center">{{ translateTitle("自动续费说明") }}</p>
      <div class="popup-container">
        <span>1.{{ translateTitle("到期前一天为您自动续费") }}</span>
        <span>2.{{ translateTitle("到期前一天，可随时取消自动续费服务") }}</span>
        <span>3.{{ translateTitle("取消自动续费服务路径") }}</span>
        <span style="font-weight: bold">{{ translateTitle(choosePlanStore.comboType + " - 订单 - 续费管理") }}</span>
      </div>
      <div style="display:flex;flex-direction: row;justify-content: space-around">
        <van-button round type="primary" style="width:40%" color="linear-gradient(to right, #51FADA, #17BCE0)"
                    @click="goAutoRenewalProtocol">
          {{ translateTitle("自动续费套餐协议") }}
        </van-button>
        <van-button round type="info" style="width:40%;border:none" @click="choosePlanStore.monthlyDescriptionShow = false">
          {{ translateTitle("知道了") }}
        </van-button>
      </div>
    </template>
  </van-popup>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {translateTitle} from "@/utils/i18n"
import {useChoosePlanStore} from "@/store/modules/ChoosePlan";
import {setUrlParam} from "@/utils/tool";
// import {newPage} from "@/utils/setupBridge";
import {WebViewBridge} from '@/utils/bridge'
import router from "@/router";
import {useFourGFlowStore} from "@/store/modules/FourGFlow";

export default {
  name: "monthlyDescription",
  setup() {
    const choosePlanStore = useChoosePlanStore()
    const fourGFlowStore = useFourGFlowStore()
    const {webViewBridge} = fourGFlowStore
    // const webViewBridge = new WebViewBridge()
    //必要参数渲染参数
    let {defaultParams: required} = fourGFlowStore
    const state = reactive({
      active: 1,
      popupShow: false,
    })

    //跳转自动续费协议页面
    const goAutoRenewalProtocol = () => {
      if (required.scenes === 'APP') {
        const url = setUrlParam('/autoRenewalAgreement', '')
        webViewBridge.newPage(url)
      }else router.push({path: '/autoRenewalAgreement'})
    }

    // onMounted(async () => await webViewBridge.init() )

    return {
      ...toRefs(state),
      translateTitle,
      choosePlanStore,
      goAutoRenewalProtocol
    }
  }

}
</script>

<style scoped lang="scss">
.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  padding: 0 20px;
  text-align: left;

  span {
    margin-bottom: 10px
  }
}
</style>