<template>
  <div>
    <div style="text-align:left;border-bottom:1px solid #f6f6f6;position:relative;padding: 10px 30px">
      订单状态
      <div style="color: #969799;position:absolute;right:30px;top:9px;">{{ status }}</div>
    </div>
    <div class="item-box">
      <div>订单编号</div>
      <div>{{ orderId }}</div>
    </div>
    <div class="item-box">
      <div>充值号码</div>
      <div>{{ iccId }}</div>
    </div>
    <div class="item-box">
      <div>充值套餐</div>
      <div>{{ title }}</div>
    </div>
    <div class="item-box">
      <div>支付金额</div>
      <div>{{ price }}</div>
    </div>
  </div>
  <div v-if="isWeiXin">
    <div class="btnPayment" :style="{'background-color': '#'+required.skin}" @click="closeWindow">关闭</div>
  </div>
  <div v-else-if="isH5">
    <div style="text-align:center;margin-top:50vh;letter-spacing: 4px" :style="{'color': '#'+required.skin}">温馨提示:请返回APP查看详情</div>
  </div>
  <div v-else-if="isApp">
    <div class="btnPayment" :style="{'background-color': '#'+required.skin}" @click="toHome">继续充值</div>
    <div class="btnPayment" :style="{'margin-top': '80px','background-color': '#'+required.skin}" @click="toList">订单详情
    </div>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
import {reactive, onMounted, toRefs} from 'vue'
import {showOrder} from "@/api"
// import {newPage, registerWindowEvent, setNavColor} from "@/utils/setupBridge"
import {WebViewBridge} from '@/utils/bridge'
import {
  getLocalParam,
  setUrlParam,
  mapCacheParameters,
  mapURLParameters,
  mapRenderParameters
} from "@/utils/tool"
import {useFourGFlowStore} from "@/store/modules/FourGFlow";

export default {
  name: "succeed",
  setup() {
    const webViewBridge = new WebViewBridge()

    const fourGFlowStore = useFourGFlowStore()

    //必要参数
    let {defaultParams: required} = fourGFlowStore
    //路由对象
    const router = useRouter()
    //渲染参数
    let state = reactive({
      //微信浏览器
      isWeiXin: false,
      //订单状态
      status: '',
      //订单ID
      orderId: '',
      //iccId
      iccId: '',
      //套餐名称
      title: '',
      //交易金额
      price: '',
      // 订单查询执行次数（防止支付宝还没回调）
      executionNum: 0,
      isH5: false,
      isApp: false,
    })

    //关闭页面
    const closeWindow = () => {
      if (state.isWeiXin) {
        WeixinJSBridge.call('closeWindow')
      }
    }

    //跳转首页
    const toHome = () => router.push({path: "index",replace: true,query:{iccId: required.iccId}})

    //跳转订单列表页面
    const toList = () => router.push({path: "orderPage",replace: true})

    //初始化参数
    const init = () => {
      //查询URL参数
      const opt = router.currentRoute.value.query
      console.warn('页面URL传参', opt)
      mapCacheParameters(required, () => {
        //映射URL参数
        mapURLParameters(required, opt, () => {
          console.warn('页面参数映射完成,网站当前肤色', required.skin)
          //映射渲染参数
          mapRenderParameters(state, opt, () => {
            console.warn('渲染参数映射完成')
          })
        })
      })
    }

    //APP事件
    const reloadEvent = () => {
      try{
        webViewBridge.registerWindowEvent(() => {
          const skin = required.skin
          webViewBridge.setNavColor(skin)
        })
      }catch (e) {
        console.warn("浏览器非APP内",e)
      }
    }

    //业务逻辑
    const business = async () => {
      //系统语言
      const language = required.language
      //订单ID
      const orderId = state.orderId
      //查询订单信息
      const data = await showOrder(orderId, language)
      const {id, target, title, price, isPayment} = data
      // if (err) return console.warn('查询订单信息异常', err)
      console.warn('订单信息', data)
      state.orderId = id
      state.iccId = target
      state.title = title
      state.price = price
      state.status = isPayment === '1' ? '已支付' : '未支付'
      if (isPayment !== '1' && state.executionNum < 5) setTimeout(() => {
        state.executionNum++
        business()
      },2000)
    }

    //组件已安装
    onMounted(async () => {
      console.log('onMounted')
      console.log('pinia store required:',required)

      try{
        await webViewBridge.init()
        state.isApp = true
      }catch (e) {
        console.warn("浏览器非APP内",e)
        state.isH5= true
      }

      //微信浏览器
      const u = navigator.userAgent.toLowerCase();
      state.isWeiXin = u.indexOf('micromessenger') !== -1;

      //初始化参数
      init()
      const scenes = required.scenes
      //检测环境
      if (scenes === 'APP') {
        console.warn('APP环境')
        //加载APP事件
        reloadEvent()
        //业务逻辑
        await business()
      } else {
        console.warn('H5环境')
        //业务逻辑
        await business()
      }
    })

    return {
      ...toRefs(state),
      required,
      closeWindow,
      toList,
      toHome
    }
  }
}
</script>

<style scoped lang="scss">
.btnPayment {
  position: fixed;
  top: 75vh;
  left: 2%;
  margin-top: 20px;
  margin-left: 10%;
  width: 80%;
  height: 42px;
  line-height: 42px;
  border-radius: 15px;
  background: #ffbe0f;
  color: white;
  text-align: center;
}

.tips {
}

///deep/ .van-nav-bar__title {
//  color: white;
//}
//
///deep/ .van-nav-bar__text {
//  color: white;
//}
//
///deep/ .van-icon-arrow-left::before {
//  color: white;
//}
.item-box {
  padding: 10px 30px;
  text-align: center;
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  div:nth-of-type(2) {
    color: #969799
  }
}
</style>