export default {
    '确认关闭':'Confirmar para fechar',
    '取消': 'cancelar',
    "请稍等": "Um momento, por favor.",
    "试用中": "Em teste",
    "动态云录像": "Gravação em nuvem dinâmica",
    "全天云录像": "Gravação em nuvem por 24 horas",
    "连续包月": "Assinatura mensal consecutiva",
    "首推": "Primeira recomendação",
    "新用户推荐": "Recomendação para novos usuários",
    "循环存储7天": "Armazenamento de ciclo por 7 dias",
    "循环存储30天": "Armazenamento de ciclo por 30 dias",
    "循环存储15天": "Armazenamento de ciclo por 15 dias",
    "循环存储3天": "Armazenamento de ciclo por 3 dias",
    "超80%人选择": "Mais de 80% da escolha do povo",
    "连续半年": "Assinatura semestral consecutiva",
    "连续包年": "Assinatura anual consecutiva",
    "包年": "Assinatura anual",
    "包半年": "Assinatura semestral",
    "包月": "Assinatura mensal",
    "新用户首选": "Primeira escolha para novos usuários",
    "尝鲜": "Adotantes iniciais",
    "家用省电套餐": "Pacote de economia de energia doméstica",
    "超长周期套餐": "Pacote de ciclo ultra-longo",
    "看店必备套餐": "Pacote essencial de monitoramento da loja",
    "到期后自动续费，可随时取消": "Renovação automática no vencimento e você pode cancelar a assinatura a qualquer momento.",
    "自动续费协议": "Contrato de Renovação Automática",
    "消息/视频无上限": "Mensagens/vídeos ilimitados",
    "云存储购买协议": "Contrato de Compra de Armazenamento em Nuvem",
    "动态云录像.png": "DTYLX_pt.png",
    "imageHeight": "350px",
    "已优惠": "Descontado",
    "AI智能服务": "Serviço Inteligente de IA",
    "未开通": "Não ativado",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "Depois de comprar o \"Serviço Inteligente de IA\", você pode aproveitar o Armazenamento em Nuvem super longo, a personalização da área de monitoramento e a personalização do som de alarme, todos eles estarão disponíveis exclusivamente! Há também a função de reconhecimento inteligente de IA (identificação de bolsas, animais de estimação e veículos) que estará disponível exclusivamente após o lançamento subsequente.",
    "30天超长云存储": "Armazenamento em Nuvem ultralongo de 30 dias",
    "报警和推送声个性化": "Personalização de alarme e som de empurrão",
    "报警区域自定义": "Personalização da área de alarme",
    "人形追踪": "Rastreamento humanóide",
    "摄像头会实时跟踪人的行动轨迹": "A câmera rastreará os movimentos das pessoas em tempo real",
    "人形框定": "Enquadramento humanóide",
    "在实时视频里面会自动框出并跟踪所出现的人": "No vídeo em tempo real, a pessoa que aparece será automaticamente enquadrada e rastreada",
    "AI智能服务功能介绍": "Introdução à função de Serviço Inteligente de IA",
    "免费服务 VS Al智能服务": "Serviço Gratuito VS Serviço Inteligente de IA",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "Prolongue até 30 dias de armazenamento ultralongo (6 tipos de pacotes são opcionais), criando um serviço de Armazenamento em Nuvem criptografado de nível financeiro. Rebobine o tempo na ponta dos dedos, para que você nunca perca nenhum momento maravilhoso.",
    "循环存储": "Armazenamento cíclico",
    "无": "Nenhum",
    "30天": "30 dias",
    "15天": "15 dias",
    "3天": "3 dias",
    "报警推送": "Empurrão de alarme",
    "100条": 100,
    "无上限！": "Ilimitada!",
    "视频时长": "Duração de vídeo",
    "最长60秒": "Até 60 segundos",
    "报警声个性化": "Personalização do som de alarme",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "Alarme de câmera / som de transmissão e toque de empurrão de telefone celular podem ser personalizados.",
    "监测区域自定义": "Personalização da área de monitoramento",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "Uma ou mais áreas de monitoramento podem ser configuradas para capturar apenas as áreas de seu interesse para reduzir alarmes desnecessários; quando um alarme é acionado, você saberá exatamente onde está o alarme.",
    "AI智能通知(待开放)": "Notificação inteligente de IA (a ser aberta)",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "Usando inteligência artificial de IA para identificar a tecnologia de algoritmo avançado, ele pode identificar o conteúdo principal da tela: animais de estimação, veículos e pacotes. E analisar seu comportamento de ação para enviar informações mais precisas e inteligentes.",
    "已了解，去购买": "Entendido, vá comprar",
    "已开通": "Ativado",
    "10秒/每条": "10 segundos/cada",
    "3天\n(100条/天，10秒/条) ": "3 dias (100 registros/dia, 10 segundos/cada)",
    "7/30天\n (消息/视频无上限)": "7/30 dias (mensagem/vídeo ilimitado)",
    "30天\n (消息/视频无上限)": "30 dias (mensagem/vídeo ilimitado)",
    "距离服务到期": "Até o serviço expirar",
    "永久": "Permanente",
    "体验": "Experimente",
    "监测区域": "Área de monitoramento",
    "报警推送100条/天": "Empurrão de alarme, 100 registros/dia",
    "视频时长10秒": "Duração de vídeo, 10 segundos",
    "7天云存储": "Armazenamento em Nuvem de 7 dias",
    "30天云存储": "Armazenamento em Nuvem de 30 dias",
    "1个月": "1 mês",
    "6个月": "6 meses",
    "12个月": "12 meses",
    "循环存储7天（使用时间6个月）": "Ciclo de armazenamento de 7 dias (tempo de uso por 6 meses)",
    "查看详情": "Ver detalhes",
    "安全": "Seguro",
    "便捷": "Conveniente",
    "贴心": "Atencioso",
    "云存储特权": "Privilégios do Armazenamento em Nuvem",
    "云端保护": "Proteção em Nuvem",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "Os vídeos são armazenados na nuvem, que não podem ser quebrados e roubados, podem proteger a segurança de dados",
    "隐私加密": "Criptografia de privacidade",
    "金融级数据加密方案，保护隐私更\n安全": "Esquema de criptografia de dados de nível financeiro para proteger a privacidade com mais segurança",
    "存储灵活": "Armazenamento flexível",
    "同时保存报警录像和视频，不错过任\n    何动态": "Salve gravação de alarme e vídeo ao mesmo tempo, não perca nenhum movimento",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "Devido a impostos e taxas de câmbio, o preço pago flutuará ligeiramente de acordo com o país onde sua conta está logada",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "A função do Serviço Inteligente só tem efeito no privilégio de administrador da câmera, e o usuário compartilhado seguirá o privilégio de administrador do dispositivo para usá-lo",
    "付款：确认购买并付款后记入iTunes 账户": "Pagamento: Crédito na conta de iTunes após a confirmação da compra e pagamento",
    "7天    ": "7 dias",
    "60天": "60 dias",
    "有效日期至": "Válido para",
    "已到期": 'Expirou',
    "可升级": 'Upgradeable',
    '云视频录像':'Gravação de vídeo na nuvem',
    '关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长':'Após o encerramento, os seus vídeos não serão guardados na nuvem e o atual serviço de armazenamento na nuvem não será prolongado',
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "Quando a tela de vídeo em tempo real muda dinamicamente, o dispositivo grava e carrega automaticamente no Armazenamento em Nuvem. Os dados de áudio e vídeo passam por tecnologia de criptografia de nível financeiro para garantir a segurança dos dados.",
    "去支付": "Para pagar",
    "天": "dias",
    "首选": "ser a primeira escolha",
    "超过80%人选择": "Mais de 80% escolhem",
    "存储周期": "Ciclo de armazenamento",
    "订单": "ordem",
    "去续费": "Para renovar",
    "云存储录像邀您试用": "Vídeo de armazenamento em nuvem convida você a experimentá-lo",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "Você terá um período de teste gratuito de um mês. O vídeo não será perdido quando armazenado na nuvem. O vídeo será mantido por sete dias, e a reprodução é simples e conveniente",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "média por dia",
    "元": "RMB",
    "省": "Save",
    "推荐": "Por favor, espere.",
    "激活": "Activar",
    "支持": "Suporte",
    "不支持": "Não suportado",
    "年卡": "Assinatura anual",
    "半年卡": "Assinatura semestral",
    "月卡": "Assinatura mensal",
    "暂不支持，敬请期待": "Ainda não suportado, fique atento",
    "卡片暂不支持充值": "Actualmente, os cartões não são recarregáveis",
    "HI~有什么需要查询的吗？": "Há alguma coisa que eu possa fazer para ajudar no inquérito?",
    "更多热门问题为你解答": "Perguntas mais populares respondidas para si",
    "热门分类": "Categorias quentes",
    "添加设备": "Adicionar dispositivo",
    "监控异常": "Monitorizar anormal",
    "操作视频": "Vídeo de funcionamento",
    "更多其他": "Mais informações",
    "常见问题": "Perguntas frequentes",
    "在线客服": "Atendimento ao cliente online",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}