export default {
    '确认关闭':'Confirmer la fermeture',
    '取消': 'La suppression des',
    "请稍等": "Un instant.",
    "试用中": "À l'essai ",
    "动态云录像": "Enregistrement dynamique sur le cloud",
    "全天云录像": "Enregistrement sur le cloud 24 heures sur 24",
    "连续包月": "Abonnement mensuel consécutif",
    "首推": "Première recommandation",
    "新用户推荐": "Recommandation pour de nouveaux utilisateurs",
    "循环存储7天": "Stockage cyclique pendant 7 jours",
    "循环存储30天": "Stockage cyclique pendant 30 jours",
    "循环存储15天": "Stockage cyclique pendant 15 jours",
    "循环存储3天": "Stockage cyclique pendant 3 jours",
    "超80%人选择": "Choix de plus de 80 % des personnes",
    "连续半年": "Abonnement semestriel consécutif",
    "连续包年": "Abonnement annuel consécutif",
    "包年": "Abonnement annuel",
    "包半年": "Abonnement semestriel",
    "包月": "Abonnement mensuel",
    "新用户首选": "Premier choix pour de nouveaux utilisateurs ",
    "尝鲜": "Ceux qui adoptent plus tôt ",
    "家用省电套餐": "Forfait d'économies d'énergie pour les ménages",
    "超长周期套餐": "Forfait du cycle ultra-long ",
    "看店必备套餐": "Forfait essentiel de surveillance des magasins",
    "到期后自动续费，可随时取消": "Renouvellement automatique à l'échéance et vous pouvez résilier votre abonnement à tout moment ",
    "自动续费协议": "Accord de renouvellement automatique ",
    "消息/视频无上限": "Messages/vidéos illimités",
    "云存储购买协议": "Contrat d'achat de stockage cloud",
    "动态云录像.png": "DTYLX_fr.png",
    "imageHeight": "360px",
    "已优惠": "Réduction déjà faite ",
    "AI智能服务": "Service intelligent de l’IA",
    "未开通": "Non activé",
    "购买“AI智能服务”后，超长云存储、监测区域自定义、报警声个性化，全部开通专享!还有AI智能识别功能(识别包裏、宠物、车辆)后续上线后均包含专享。": "Après l’achat du « Service intelligent de l’IA », vous pouvez bénéficier exclusivement du stockage ultra-long dans le cloud, de la personnalisation de la zone de surveillance, de la personnalisation des sons d’alarme, tous sont exclusifs pour vous ! La fonction d’identification intelligente par l’IA (identification des colis, des animaux domestiques, des véhicules) est également disponible lorsqu’elle sera mise en service ultérieurement.",
    "30天超长云存储": "30 jours de stockage ultra-long dans le cloud",
    "报警和推送声个性化": "Personnalisation des sons d’alarme et de poussée ",
    "报警区域自定义": "Personnalisation de la zone d’alarme ",
    "人形追踪": "Suivi humanoïde ",
    "摄像头会实时跟踪人的行动轨迹": "La caméra suit les mouvements d’une personne en temps réel ",
    "人形框定": "Encadrement humanoïde ",
    "在实时视频里面会自动框出并跟踪所出现的人": "La personne qui apparaît est automatiquement encadrée et suivie dans la vidéo en temps réel ",
    "AI智能服务功能介绍": "Présentation des fonctions du service intelligent de l’IA ",
    "免费服务 VS Al智能服务": "Service gratuit VS Service intelligent de l’IA ",
    "延长至最高达30天超长存储（6种套餐可选）， 打造金融级加密的云端存储服务。让时光在指尖倒流，使您不再错过任何精彩的瞬间。": "Augmenter votre capacité de stockage ultra-long jusqu’à 30 jours (6 forfaits disponibles), en créant un service de stockage en cloud crypté de qualité financière. Remonter le temps sur le bout des doigts, pour ne jamais manquer un moment merveilleux. ",
    "循环存储": "Stockage cyclique ",
    "无": "Aucun",
    "30天": "30 jours",
    "15天": "15 jours",
    "3天": "3 jours",
    "报警推送": "Poussée d’alarme ",
    "100条": "100 enregistrements ",
    "无上限！": "Sans limite supérieure ",
    "视频时长": "Durée de la vidéo ",
    "最长60秒": "60 secondes au maximum ",
    "报警声个性化": "Personnalisation des sons d’alarme",
    "摄像机报警/播报声、手机推送铃声，均可进行个性化设置。准备了丰富的本地语音，您也可以使用语音和文字录制您想要的任何声音。": "Les sons d’alarme et d’annonce de la caméra, ainsi que les sonneries de poussée du téléphone portable peuvent être personnalisés. Des voix locales riches sont préparées, ou vous pouvez enregistrer des sons que vous souhaitez en utilisant la voix et le texte. ",
    "监测区域自定义": "Personnalisation de la zone de surveillance ",
    "可以设置一个或多个监测区域，只捕捉您关心的区域从而减少不必要的报警;当触发报警时，您将确切的知道报警的具体位置。": "Vous pouvez définir une ou plusieurs zones de surveillance pour capturer seulement les zones qui vous intéressent, ce qui réduit des alarmes inutiles ; lorsqu’une alarme est déclenchée, vous pourrez savoir exactement où elle se trouve. ",
    "AI智能通知(待开放)": "Notification intelligente de l’IA (à ouvrir) ",
    "使用AI人工智能识别高级算法技术，可识别画面主体内容：宠物、车辆、包裹。并分析其动作行为，推送更精准、智能的信息。": "En utilisant l’intelligence artificielle pour identifier la technologie d’algorithme avancée, le contenu principal sur l’écran peut être identifié : animaux domestiques, véhicule, colis. Et analyser leur comportement d’action pour fournir des informations plus précises et plus intelligentes. ",
    "已了解，去购买": "Compris, aller acheter ",
    "已开通": "Activé ",
    "10秒/每条": "10 secondes/enregistrement ",
    "3天\n(100条/天，10秒/条) ": "3 jours (100 enregistrements/jour, 10 secondes/enregistrement)",
    "7/30天\n (消息/视频无上限)": "7/30 jours (messages/vidéos illimités)",
    "30天\n (消息/视频无上限)": "30 jours (messages/vidéos illimités)",
    "距离服务到期": "Expiration du service à distance",
    "永久": "Permanent",
    "体验": "Expérience",
    "监测区域": "Zone de surveillance",
    "报警推送100条/天": "Poussée d’alarme 100 enregistrements/jours ",
    "视频时长10秒": "Durée de la vidéo 10 secondes ",
    "7天云存储": "7 jours de stockage dans le cloud",
    "30天云存储": "30 jours de stockage dans le cloud",
    "1个月": "1 mois ",
    "6个月": "6 mois",
    "12个月": "12 mois",
    "循环存储7天（使用时间6个月）": "7 jours de stockage cyclique (temps d’utilisation : 6 mois) ",
    "查看详情": "Consulter des détails",
    "安全": "Sûr ",
    "便捷": "Pratique ",
    "贴心": "Attentionné ",
    "云存储特权": "Privilèges de stockage dans le cloud",
    "云端保护": "Protection cloud",
    "录像云端存储，砸不坏，偷不走，保护\n数据安全": "Les vidéos sont stockées dans le cloud, elles ne peuvent pas être cassées ni volées pour protéger la sécurité des données",
    "隐私加密": "Cryptage de vie privée",
    "金融级数据加密方案，保护隐私更\n安全": "Programme de cryptage des données de niveau financier pour protéger la vie privée plus sûre",
    "存储灵活": "Stockage flexible",
    "同时保存报警录像和视频，不错过任\n    何动态": "Sauvegarder à la fois l’enregistrement vidéo et la vidéo d’alarme, pour ne pas manquer les mouvements",
    "由于税收和汇率等原因，付费价格会根据\n您账号的登陆国家略有浮动": "Pour des raisons fiscales et de taux de change, le prix payé variera légèrement en fonction du pays où votre compte est connecté ",
    "智能服务的功能只在摄像机管理员权限中生效，被分享的用户将跟随该设备的管理员权限对其进行使用": "Les fonctions du service intelligent ne prennent effet qu’avec les autorisations de l’administrateur de la caméra, et l’utilisateur partagé devra suivre les autorisations de l’administrateur de l’appareil pour l’utiliser.",
    "付款：确认购买并付款后记入iTunes 账户": "Paiement : crédité sur le compte iTunes après confirmation de l’achat et du paiement",
    " 去支付": "Aller payer",
    "7天    ": "7 jours    ",
    "60天": "60 jours",
    "有效日期至": "Efficace à",
    "可升级": 'Upgradeable',
    "已到期": 'Échéance',
    "云视频录像": "Enregistrement vidéo en nuage",
    "关闭之后，云端将不会保存您的视频，当前云存储服务时间不会延长": "Après la fermeture, vos vidéos ne seront pas sauvegardées dans le nuage et le service actuel de stockage dans le nuage ne sera pas prolongé.",
    "当实时视频画面出现动态变化时，设备将自动录像并上传到云端存储。音视频数据经过金融级加密技术，以确保数据安全。": "Lorsque l’écran vidéo en temps réel change de manière dynamique, l’appareil l’enregistre automatiquement et télécharge sur le cloud pour stocker. Les données audio et vidéo sont traitées par les technologies cryptées de qualité financière pour garantir leur sécurité",
    "去支付": "Pour payer",
    "天": "jours",
    "首选": "Préféré",
    "超过80%人选择": "Plus de 80% ont choisi",
    "存储周期": "Cycle de stockage",
    "订单": "Ordre",
    "去续费": "Pour renouveler",
    "云存储录像邀您试用": "La vidéo de stockage en nuage vous invite à l'essayer",
    "您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便": "Vous aurez une période d'essai gratuite d'un mois, le stockage vidéo dans le cloud n'est pas perdu, la vidéo est conservée pendant sept jours, facile à lire",
    "温馨提示": "Kind tips",
    "购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存": "After the purchase, the service takes effect immediately. When an object or person is detected, the video from the beginning to the end of the movement will be recorded and uploaded to the cloud for storage.",
    "知道了": "understood",
    "免费试用(录像保存7天)": "Free trial (records are saved for 7 days)",
    "任意看日均": "Moyenne quotidienne",
    "元": "RMB",
    "省": "Save",
    "推荐": "Recommend",
    "请等待": "Attendez, s'il vous plaît.",
    "激活": "activation",
    "支持": "soutien",
    "不支持": "pas de soutien",
    "年卡": "Abonnement annuel",
    "半年卡": "Abonnement semestriel",
    "月卡": "Abonnement mensuel",
    "暂不支持，敬请期待": "Pas encore pris en charge, à suivre",
    "卡片暂不支持充值": "Les cartes ne sont pas rechargeables pour le moment",
    "HI~有什么需要查询的吗？": "HI ~ avez-vous besoin d’aide avec votre demande?",
    "更多热门问题为你解答": "Plus de questions chaudes répondues pour vous",
    "热门分类": "Catégories populaires populaires",
    "添加设备": "Ajouter un appareil",
    "监控异常": "Surveiller les exceptions",
    "操作视频": "Vidéo de fonctionnement",
    "更多其他": "Q&R",
    "常见问题": "FAQ",
    "在线客服": "Service client en ligne",
    "问题没有得到解决？": "The problem has not been resolved?",
    "点击反馈": "Click feedback",
    "暂无评论": "No comments at the moment.",
    "备注留言": "Notes and comments",
    "上一问": "Previous question",
    "下一问": "Next question",
    "为了更快解决问题，请详细描述您遇到的问题": "In order to solve the problem faster, please provide a detailed description of the problem you encountered.",
    "信息提交": "Information submission",
    "提交账号": "Submit account",
    "提交设备ID": "Submit device ID",
    "选择异常设备": "Select abnormal device",
    "上传图片说明": "Upload explanatory images",
    "回复方式": "Reply method",
    "电话": "Mobile",
    "请输入手机号": "Please enter your mobile number.",
    "请输入完整手机号!": "Please enter the complete mobile number.",
    "邮箱": "Email address",
    "请输入邮箱": "Please enter your email address.",
    "邮箱格式有误!": "Incorrect email format!",
    "提交": "Submit",
    "设备列表": "Device list",
    "猜你想问": "Question",
    "报警功能": "Alarm",
    "云存储": "Cloud",
    "TF卡录像": "Recording",
}