<template>
  <van-empty v-if="store.deviceList.length === 0" :description="emptyText"/>
  <van-cell-group :title="translateTitle('设备列表')" v-if="store.deviceList.length > 0">
    <van-cell v-for="item in store.supportList"
              center
              :key="item._id"
              :label="item.did"
              :value="translateTitle('激活')"
              is-link
              @click="activation(item)"
    >
      <template #title>
        <span class="custom-title">{{ item.deviceName }}</span>
        <van-tag type="success">{{translateTitle('支持')}}</van-tag>
      </template>
    </van-cell>
    <van-cell v-for="item in store.unSupportList"
              center
              :key="item._id"
              :label="item.did"
    >
      <template #title>
        <span class="custom-title">{{ item.deviceName }}</span>
        <van-tag type="warning">{{ translateTitle("不支持") }}</van-tag>
      </template>
    </van-cell>
  </van-cell-group>
</template>
<script>

import {translateTitle} from "@/utils/i18n";
import {useCouponsStore} from "@/store/券";
import {Dialog, Toast} from "vant";

export default {
  name: "deviceList",
  setup() {
    const store = useCouponsStore();
    const emptyText = translateTitle('设备列表为空')

    //激活
    const activation = async (item) => {
      console.log(item)
      const message = `${translateTitle('确定激活')}  ${item.deviceName}?`
      const options = {
        message,
        confirmButtonText: translateTitle('确认'),
        cancelButtonText: translateTitle('取消')
      }
      Dialog.confirm(options)
          .then(() => {
            store.activation(item.did)
                .catch(() => Toast(translateTitle('激活失败,请联系商家')))
          })
          .catch(() => console.log('用户取消'))
    }

    return {
      emptyText,
      activation,
      store,
      translateTitle
    }
  }
}
</script>

<style>
.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}
</style>