<template>
  <div class="home">
    <div class="head_box" :style="{'background-color':'#'+required.skin}">
      <div style="padding: 30px">{{ title }}</div>
      <div>￥{{ price }}元</div>
      <div style="padding: 30px">支付剩余时间:
        <van-count-down format="mm:ss" :time="time" :onFinish="timeFinish"/>
      </div>
    </div>
    <div>
      <van-radio-group v-model="channel">
        <van-cell-group inset>
          <van-cell clickable @click="channel === 'WX'" v-show="isWXShow">
            <template #title>
              <van-icon name="wechat-pay" color="#07c160" size="18px"/>
              <span class="custom-title"> 微信支付</span>
            </template>
            <template #right-icon>
              <van-radio name="WX"/>
            </template>
          </van-cell>
          <van-cell clickable @click="channel === 'ALI'" v-show="isAliShow">
            <template #title>
              <van-icon name="alipay" color="#1989fa" size="18px"/>
              <span class="custom-title"> 支付宝支付</span>
            </template>
            <template #right-icon>
              <van-radio name="ALI"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="btnPayment" @click="payNow" :style="{color:'white','background-color':'#'+required.skin}">确认支付</div>
  </div>
</template>

<script>
import {Toast} from 'vant'
import {useRouter} from 'vue-router'
import {reactive, onMounted, ref, toRefs} from 'vue'
import {paymentEyeCloudCreate, paymentEyeCloudSign, showOrder} from "@/api"
// import {registerWindowEvent, setNavColor} from "@/utils/setupBridge"
import {WebViewBridge} from '@/utils/bridge'
import {eyeCloudPaymentH5, eyeCloudPaymentJSAPI} from "@/utils/payment"
import {
  defaultParams,
  mapCacheParameters,
  mapRenderParameters,
  mapURLParameters,
  setUrlParam
} from "@/utils/tool"
import router from '@/router'
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {useOrderListStore} from "@/store/modules/订单详情/orderList";
export default {
  name: "Pay",
  setup(){
    const fourGFlowStore = useFourGFlowStore()
    const orderListStore = useOrderListStore()
    const webViewBridge = new WebViewBridge()

    let {selectedOrderId:currentOrderId} = orderListStore

    //必要参数
    let {defaultParams: required} = fourGFlowStore

    //渲染参数
    const state = reactive({
      //微信浏览器
      isWeiXin: false,
      //ALI支付，默认显示
      isAliShow: true,
      //ALI支付，默认显示
      isWXShow: true,
      //支付计时器；超时页面回退
      time: 15 * 60 * 1000,
      //选择支付方式；微信 WX 支付宝 ALI；默认微信 WX
      channel: 'WX',
      //订单ID
      orderId: '',
      //套餐标题
      title: '',
      //价格
      price: '-',
      //金额（分）
      transaction_fee: '0',
      //支付成功，跳转地址
      return_url: '',
      //订单信息
      info: {},
    })

    //初始化参数
    const init = () => {
      //查询URL参数
      const opt = router.currentRoute.value.query
      console.warn('页面URL传参', opt)
      mapCacheParameters(required)
      const { orderId, iccId, skin,onlyAliPlay } = opt
      if(onlyAliPlay) {
        state.isWXShow = false
        state.channel = 'ALI'
      }
      if(orderId) currentOrderId = orderId
      if(iccId) required.iccId = iccId
      if(skin) required.skin = skin
      console.warn('页面参数映射完成,网站当前肤色', required.skin)
      console.warn('订单ID前后是否一致', orderId,currentOrderId)

      const scenes = required.scenes
      //支付成功，跳转URL
      state.return_url = setUrlParam('/' + 'succeed', {orderId: currentOrderId, iccId: required.iccId, scenes})

      //查询订单信息
      return reloadOrderInfo(currentOrderId, required.language)
    }

    //加载订单信息
    const reloadOrderInfo = async (orderId, language) => {
      //查询订单信息
      const data = await showOrder(orderId, language)
      console.warn('订单信息', data)
      state.title = data['title']
      state.price = data['price']
      state.transaction_fee = data['transaction_fee']
      //订单信息
      state.info = data
    }

    //APP事件
    const reloadEvent = () => {
      try{
        webViewBridge.registerWindowEvent(() => {
          const skin = required.skin
          webViewBridge.setNavColor(skin)
        })
      }catch (e) {
        console.warn("浏览器非APP内",e)
      }
    }

    //立即支付
    const payNow = async () => {
      const {isWeiXin,channel,transaction_fee,title,return_url} = state
      const orderId = currentOrderId
      //支付场景
      let payScenes = 'IH5'
      if (isWeiXin) payScenes = 'IWX'
      console.warn('选用支付渠道', channel, payScenes)
      //参数
      const {oemId,openid} = required

      console.warn('支付创建订单参数', oemId, payScenes, channel, openid, orderId, transaction_fee, title, return_url)
      //创建支付宝或微信订单 (接口不统一)
      const data = await paymentEyeCloudCreate(oemId, payScenes, channel, openid, orderId, transaction_fee, title, return_url)
      console.warn("data:", data)
      // if (err) return Toast.fail('操作失败，请稍后重试！');
      if (isWeiXin) {
        const prepay_id = data['prepay_id']
        const res2 = await paymentEyeCloudSign(oemId, prepay_id)
        const data2 = res2.data
        // if (err) return console.error(err)
        eyeCloudPaymentJSAPI(return_url, data2)

      } else {
        console.log("paymentEyeCloudCreate data is ",data)
        eyeCloudPaymentH5(channel, return_url, data);//H5支付操作
      }
    }

    //支付倒计时结束
    const timeFinish = () => {
      console.warn('倒计时结束,页面回退')
      window.history.go(-1)
    }

    const bridgeInfo = async () => {
      const scenes = required.scenes
      try{
        await webViewBridge.init()
        //检测环境
        if (scenes === 'APP') {
          console.warn('APP环境')
          //加载APP事件
          await reloadEvent()
        } else {
          console.warn('H5环境')
        }
      }catch (e) {
        console.warn("浏览器非APP内",e)
      }
    }

    //组件已安装
    onMounted(() => {

      bridgeInfo()

      console.log('onMounted')
      //微信浏览器判断
      const u = navigator.userAgent.toLowerCase();
      state.isWeiXin = u.indexOf('micromessenger') !== -1
      const scenes = required.scenes
      // 防止用户刷新页面，订单id已经不存在
      if(!currentOrderId && scenes === 'H5') router.push({path: "orderPage", replace: true})

      //初始化参数
      init()
      //隐藏指定经销商支付渠道
      if (required.oemId === 'COOCAM') state.isAliShow = false
    })

    return{
      ...toRefs(state),
      required,
      timeFinish,
      payNow
    }
  }
}
</script>

<style scoped>
.head_box {
  text-align: center;
  color: white;
  width: 100%;
  padding-bottom: 5vh;
  background: #ffbe0f;
}

.btnPayment {
  position: fixed;
  bottom: 5vh;
  top: 90%;
  left: 10%;
  width: 80%;
  height: 42px;
  line-height: 42px;
  border-radius: 15px;
  background: #ffbe0f;
  color: white;
  text-align: center;
}

/deep/ .van-count-down {
  color: white;
  display: inline;
}

/deep/ .van-nav-bar__title {
  color: white;
}

/deep/ .van-nav-bar__text {
  color: white;
}

/deep/ .van-icon-arrow-left::before {
  color: white;
}
</style>