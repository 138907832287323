import request from "@/utils/request";
import {paymentH5RequestType} from "/#/api";


//获取支付的H5地址
export function payment_h5(data: paymentH5RequestType) {
  return request({
    url: '/payment/h5',
    method: 'post',
    data
  })
}