import { baseURL, contentType, requestTimeout } from '@/config'
import router from '@/router'
import axios from 'axios'
import {Toast} from 'vant'

/**
 * axios响应拦截器
 * @param data response数据
 * @returns {Promise<*|*>}
 */
const handleData = async (data) => {
    console.log("requestData:",data)
    // if (status !== 200) throw new Error('系统接口异常')
    switch (data.status) {
        case 0:
        case 200:
            return data.data.hasOwnProperty('data') ? data.data.data : data.data
        case 404:
            Toast(data.data.msg)
            break
        default:
            console.log("问题接口回应:",data)
            console.error('未知异常')
            throw "未知异常"
    }
}

/**
 * axios请求拦截器配置
 * @param config
 * @returns {any}
 */
const requestConf = (config) => {
    const uid = '12218045'
    const authkey = 's+9nPELJoZFCrQfAhgYorshUc0TAcZXuVLYwa0e6BPHUgb903MKcgJU7w1A4Io8W'
    config.headers['uid'] = uid
    config.headers['authkey'] = authkey
    return config
}

/**
 * @description axios初始化
 */
const instance = axios.create({
    baseURL:baseURL,
    timeout: requestTimeout,
    headers: {
        'Content-Type': contentType,
    },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(requestConf, (error) => {
    return Promise.reject(error)
})

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
    (response) => handleData(response),
    (error) => {
        const { response } = error
        if (response === undefined) {
            console.log("response is undefined")
            return {}
        } else return handleData(response)
    }
)

export default instance
