import request from "@/utils/FAQ_request";
import {problemListType,
    detailsContentType,
    discussionType,
    addCommentType,
    titleListType,
    updateStateType} from "/#/api";
import {FAQFromTYpe} from "/#/store";

// 获取一级标题数据
export function getTitleList(data: titleListType) {
    return request({
        url: 'problemSet/title/list',
        method: 'post',
        data
    })
}
// 获取标题下所有问题
export function getProblemList(data: problemListType) {
    return request({
        url: 'problemSet/topic/list',
        method: 'post',
        data
    })
}
// 添加收集表单
export function addCollectionFrom(data: FAQFromTYpe) {
    return request({
        url: 'questionCollection/add',
        method: 'post',
        data
    })
}
// 问题提交内容详情
export function detailsContent(data: detailsContentType) {
    return request({
        url: 'questionCollection/details',
        method: 'post',
        data
    })
}
// 评论列表
export function problemDiscussion(data: discussionType) {
    return request({
        url: 'problemDiscussion/list',
        method: 'post',
        data
    })
}
// 客户发表评论
export function addComment(data: addCommentType) {
    return request({
        url: 'problemDiscussion/comment',
        method: 'post',
        data
    })
}
// 客户发表评论
export function updateState(data: updateStateType) {
    return request({
        url: 'questionCollection/updateState',
        method: 'post',
        data
    })
}