/**
 * 支付管理
 */
import {getApplyId} from "./tool";
import {setupWebViewJavascriptBridge} from './setupBridge'
// import {WebViewBridge} from '@/utils/bridge'
// const webViewBridge = new WebViewBridge()
// webViewBridge.init()

//调用APP支付界面
export function paymentAPP(iap, orderID, productID, userid, price, transaction_fee, title, iccId) {
    const money = String(price) + '￥';
    const options = {
        iap: iap
        , devId: 'default'
        , userid: userid
        , orderID: orderID
        , productID: productID
        , localizedTitle: title
        , localizedDescription: title
        , price: transaction_fee
        , money: money
        , cNo: iccId
    };
    console.log('调用APP支付页面');
    // webViewBridge.callHandler('payPage', options, (responseData) => {})
    setupWebViewJavascriptBridge((bridge) => {
        console.log('调用APP支付页面-收到回调APP');
        bridge.callHandler('payPage', options, (responseData) => {
        });
    })

}

//调用H5支付页面
export function paymentH5(orderId, paySign, price, title) {
    if (typeof BC == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('beecloud:onready', bcPay, false);
        } else if (document.attachEvent) {
            document.attachEvent('beecloud:onready', bcPay);
        }
    } else {
        bcPay();
    }

    function bcPay() {
        const out_trade_no = orderId;
        const sign = paySign;
        /**
         * click调用错误返回：默认行为console.log(err)
         */
        BC.err = (data) => {
            //注册错误信息接受
            alert(data["ERROR"]);
        };
        /**
         * 3. 调用BC.click 接口传递参数
         */
        BC.click({
            "title": title,
            "amount": price,
            "out_trade_no": out_trade_no, //唯一订单号
            "analysis": {"ip": "58.110.23.35"},
            "sign": sign,
            /**
             * optional 为自定义参数对象，目前只支持基本类型的key ＝》 value, 不支持嵌套对象；
             * 回调时如果有optional则会传递给webhook地址，webhook的使用请查阅文档
             */
            "optional": {"order_id": orderId}
        });
    }
}

//设置BeeCloud支付按钮
export function setBeeCloudButton(oemId) {
    let src, new_element
    const appId = getApplyId(oemId)
    if (appId) {
        src = "https://jspay.beecloud.cn/1/pay/jsbutton/returnscripts?appId=" + appId;
        new_element = document.createElement("script")//创建新的script节点
        new_element.setAttribute("type", "text/javascript")
        new_element.setAttribute("src", src)
        new_element.setAttribute("id", "spay-script")
        document.head.appendChild(new_element)//head加入
    }
}

//eyeCloud支付系统H5支付
export function eyeCloudPaymentH5(channel, redirect_url, data) {
    switch (channel) {
        case 'ALI':
            const div = document.createElement('div')
            div.innerHTML = data
            document.body.appendChild(div)
            document.forms[0].submit()
            break;
        case 'WX':
            try {
                redirect_url = encodeURIComponent(redirect_url)
                const h5_url = data['h5_url']
                window.location.href = h5_url + '&redirect_url=' + redirect_url
            } catch (e) {
                console.error('微信支付参数异常', e)
            }
            break;
        default:
            console.error('支付渠道异常', channel)
    }
}

//eyeCloud支付系统JSAPI支付
export function eyeCloudPaymentJSAPI(redirect_url, data) {
    WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
            "appId": data.appId,       //公众号名称，由商户传入
            "nonceStr": data.nonceStr, //随机串
            "package": data.package,
            "signType": data.signType,  //微信签名方式：
            "timeStamp": String(data.timeStamp), //时间戳，自1970年以来的秒数
            "paySign": data.paySign     //微信签名
        },
        function (res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
                window.location.replace(redirect_url);//支付成功跳转
            } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                console.error('微信内支付失败')
            }
        });
}

export default {
    paymentAPP,
    paymentH5,
    setBeeCloudButton,
    eyeCloudPaymentH5,
    eyeCloudPaymentJSAPI,
}