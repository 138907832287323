<template>
  <div class="page">
    <div style="margin-top: 20px">
      <h3 style="text-align: center">{{ translateTitle('信息提交') }}</h3>
      <el-form :model="form" label-width="120px">
        <el-form-item :label="translateTitle('提交账号')">
          <el-input v-model="FAQStore.from.userInfo.account" :disabled="userInfoInput"/>
        </el-form-item>
        <el-form-item :label="translateTitle('提交设备ID')">
          <el-input v-if="!FAQStore.authkey" v-model="FAQStore.from.userInfo.did" style="margin-bottom: 20px"/>
          <p v-if="FAQStore.authkey">{{ FAQStore.from.userInfo.did }}</p>
          <el-button v-if="FAQStore.authkey" @click="getDeviceList">{{ translateTitle('选择异常设备') }}</el-button>
        </el-form-item>
        <el-form-item :label="translateTitle('上传图片说明')">
          <el-upload
              ref="uploadRef"
              action="https://faq-api.eye4.cn/upload"
              list-type="picture-card"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
          >
            <el-icon>
              <Plus/>
            </el-icon>
            <template #file="{ file }">
              <div>
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt=""/>
                <span class="el-upload-list__item-actions">
                <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                >
                  <el-icon><zoom-in/></el-icon>
                </span>
                <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                >
                 <el-icon><Delete/></el-icon>
                </span>
                </span>
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item :label="translateTitle('回复方式')">
          <el-checkbox-group v-model="form.type">
            <el-checkbox :label="translateTitle('电话')" name="type"/>
            <el-input v-if="form.type.includes('电话')"
                      v-model="FAQStore.from.replyMethod.phone" placeholder="请输入手机号"/>
            <p class="validator" v-if="validatorPhone">{{ translateTitle('请输入完整手机号!') }}</p>
            <el-checkbox :label="translateTitle('邮箱')" name="type"/>
            <el-input v-if="form.type.includes('邮箱')"
                      v-model="FAQStore.from.replyMethod.email" placeholder="请输入邮箱"/>
            <p class="validator" v-if="validatorEmail">{{ translateTitle('邮箱格式有误!') }}</p>
            <!--            <el-checkbox label="App推送" name="type"/>-->
<!--            <el-checkbox label="在线客服留言" name="type"/>-->
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :disabled="FAQStore.submitted" style="width:50%" size="large">
            {{ translateTitle('提交') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog v-model="dialogVisible" width="80%" round :show-close="false" style="padding-top:0">
      <h2>{{ translateTitle('设备列表') }}</h2>
      <el-radio-group v-model="deviceId" class="ml-4" @change="selectDid">
        <el-radio :label="item.did" size="large" v-for="item in deviceList">
          {{ item.did }}
        </el-radio>
      </el-radio-group>
    </el-dialog>

    <el-dialog v-model="dialogImageVisible" width="80%">
      <img style="width: 100%;margin-top: 10px" :src="dialogImageUrl">
    </el-dialog>
  </div>
</template>

<script>
import {useFAQStore} from "@/store/modules/FAQ";
import {reactive, toRefs, onMounted, computed} from 'vue'
import {deviceList} from "@/api/device";
import {Delete, Download, Plus, ZoomIn} from '@element-plus/icons-vue'
import {ElMessage} from 'element-plus'
import router from "@/router";
import {translateTitle} from "../../utils/i18n";

export default {
  name: "chooseReply",
  methods: {translateTitle},
  components: {
    Delete,
    Download,
    Plus,
    ZoomIn
  },
  setup() {
    const FAQStore = useFAQStore()
    const state = reactive({
      form: {
        type: [],
        phone: '',
        email: ''
      },
      dialogVisible: false,
      dialogImageVisible: false,
      dialogImageUrl: '',
      deviceList: [],
      deviceId: '',
      userInfoInput: false,

      uploadRef: null
    })

    const validatorPhone = computed(() =>
        state.form.type.includes('电话') && FAQStore.from.replyMethod.phone &&
        !(/^1(3|4|5|6|7|8|9)\d{9}$/.test(FAQStore.from.replyMethod.phone))
    )
    const validatorEmail = computed(() =>
        state.form.type.includes('邮箱') && FAQStore.from.replyMethod.email &&
        !(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/).test(FAQStore.from.replyMethod.email)
    )

    onMounted(() => {
      if (!FAQStore.from.actionInfo.titleId) router.push({path: '/FAQ', replace: true})
      state.userInfoInput = !!FAQStore.from.userInfo.account
    })

    // 点击提交对回复方式进行数据处理后再提交
    const onSubmit = () => {
      if (!FAQStore.from.userInfo.did || !FAQStore.from.userInfo.account) {
        return ElMessage({
          message: '提交用户和设备ID不能为空！',
          type: 'warning',
          during: 3000
        });
      } else if (!state.form.type.length) {
        return ElMessage({
          message: '请至少选择一种回复方式！',
          type: 'warning',
          during: 3000
        });
      } else if (
          (state.form.type.includes("电话") && !FAQStore.from.replyMethod.phone) ||
          (state.form.type.includes("邮箱") && !FAQStore.from.replyMethod.email) ||
          (validatorPhone.value || validatorEmail.value)
      ) {
        console.warn(state.form.type.includes("电话"), !FAQStore.from.replyMethod.phone, validatorPhone.value, validatorEmail.value)
        return ElMessage({
          message: '电话或者邮箱不能有误、为空',
          type: 'warning',
          during: 5000
        });
      }
      console.log("submit", state.form)
      FAQStore.from.replyMethod.appPush = state.form.type.includes("App推送")
      FAQStore.from.replyMethod.onlineService = state.form.type.includes("在线客服留言")
      if (!state.form.type.includes("电话")) FAQStore.from.replyMethod.phone = ""
      if (!state.form.type.includes("邮箱")) FAQStore.from.replyMethod.email = ""
      FAQStore.submit()
    }
    // 根据uid和authkey获取设备列表，并展示让用户选择
    const getDeviceList = async () => {
      const data = {
        uid: FAQStore.from.userInfo.account,
        authkey: FAQStore.authkey
      }
      // const data = {uid: "11252314", authkey: "K8a1Mlr8i11RN/R835UGlVc/ek/uSXdCCRCGxPOa9D5jf0takIHh/PyoArxtcbRW"}
      state.deviceList = await deviceList(data)
      // console.log("list", list)
      state.dialogVisible = true
    }
    // 选择设备列表后修改表单did并关闭弹框
    const selectDid = (did) => {
      FAQStore.from.userInfo.did = did
      state.dialogVisible = false
    }
    // 上传的图片进行二次浏览
    const handlePictureCardPreview = (file) => {
      state.dialogImageVisible = true
      state.dialogImageUrl = file.url
    }
    // 删除已上传图片
    const handleRemove = (file) => {
      // console.warn("被删掉：",file)
      const {response: {data}} = file
      state.uploadRef.handleRemove(file)
      const index = FAQStore.from.userInfo.updateLink.indexOf(data)
      FAQStore.from.userInfo.updateLink.splice(index, 1)
    }
    // 上传图片成功后将返回值添加入提交表单中
    const handleAvatarSuccess = (res, file) => {
      // console.warn("成功：",res,file)
      const {data: url} = res
      FAQStore.from.userInfo.updateLink.push(url)
    }
    // 判断上传文件格式是否符合标准
    const beforeAvatarUpload = (file) => {
      // const isLt10M = file.size / 1024 / 1024 < 10;
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'png'
      const extension2 = testmsg === 'jepg'
      const extension3 = testmsg === 'jpg'
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 png、jepg、jpg格式的图片文件',
          type: 'warning',
          during: 5000
        });
      }
      return (extension || extension2 || extension3);
    }

    return {
      ...toRefs(state),
      onSubmit,
      FAQStore,
      getDeviceList,
      selectDid,
      handlePictureCardPreview,
      handleRemove,
      handleAvatarSuccess,
      beforeAvatarUpload,
      validatorPhone,
      validatorEmail
    }
  }
}
</script>
<style>
.el-dialog__header {
  padding: 0
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 48%;
  margin: 0 2% 8px 0;
}

.el-upload--picture-card {
  width: 100px;
  height: 100px;
}

.el-checkbox.el-checkbox--large {
  height: auto;
  margin-bottom: 20px;
  margin-right: 0;
}
</style>
<style scoped>
.page {
  padding: 20px;
  height: calc(100vh - 40px);
  background: linear-gradient(to left top, #EEF2FD, #F5FBFC 30%);
}

.checkBoxGroup {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.validator {
  color: #ff766f;
  font-size: 13px;
  line-height: inherit;
  text-indent: 15px;
  margin: 10px 0;
}
</style>