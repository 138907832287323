import {Toast} from 'vant'
import router from '@/router'
import {defineStore} from 'pinia'
import {agreementType} from '/#/api'
import {setUrlParam} from "@/utils/tool.js";
import {choosePlanModuleType} from '/#/store'
import {eyeCloudPaymentH5} from "@/utils/payment.js";
import {useFourGFlowStore} from "@/store/modules/FourGFlow";
import {useOrderListStore} from "@/store/modules/订单详情/orderList";
import {createOrder, agreement, showSigned, showPurchased, showOrder, paymentEyeCloudCreate} from '@/api/index.js'

export const useChoosePlanStore = defineStore('choosePlanStore', {
    state: (): choosePlanModuleType => ({
        currentSelectIndex: -1,
        currentPrice: 0,
        discountedPrice: 0,
        productId: '',
        selectedCombo: null,
        monthlyDescriptionShow: false,
        comboType: '',
        isAutoContract: false,
        autoContractList: [],
        showAgreement: false,
        showAgreementDes: false
    }),
    getters: {},
    actions: {
        // 近路选中的套餐类别（低功耗、常电）
        initData(type: string) {
            this.comboType = type
        },
        // 判断是否已经签约
        async showSigned(iccId: string){
            let {isSign,list} = await showSigned(iccId)
            console.log("是否已经签约",isSign,list)
            this.isAutoContract = isSign
            if(list) this.autoContractList = list
        },
        // 判断是否有签约后未购买的套餐，如果有则获取第一项进行支付购买
        async isPurchased(iccId: string){
            const fourGFlowStore = useFourGFlowStore()
            const orderListStore = useOrderListStore()
            let {defaultParams: required} = fourGFlowStore
            const data = await showPurchased(iccId,this.productId)
            if(data.length) {
                const {id} = data.find((item: any) => item.isPayment === "0")
                orderListStore.selectedOrderId = id
                const url = setUrlParam('/pay', {iccId: required.iccId,onlyAliPlay:true})
                if (required.scenes === 'APP') {
                    fourGFlowStore.webViewBridge.newPage(url)
                } else {
                    await router.push({path: '/pay',query:{"onlyAliPlay": 1}})
                }
            }else{
                // 提示用户已经签约并购买，请勿重复购买
                Toast({
                    message: '已购买过自动续费套餐，请勿重复购买',
                    icon: 'goods-collect-o',
                });
            }
        },
        // 判断是需要提示连续包月开通说明
        isRemind() {
            const fourGFlowStore = useFourGFlowStore()
            //必要参数
            let {renderedParams: rendered} = fourGFlowStore
            const list = rendered.isUnlimited ? rendered.unlimitedList : rendered.mixList
            let isRemind = list.some((item: any) => item.id === this.productId && item.group === 'automatic_renewal')
            isRemind ? this.monthlyDescriptionShow = true : router.push({path: 'combo'})
        },
        //获取签约，并拉起支付宝
        async onSubmit() {
            const fourGFlowStore = useFourGFlowStore()
            //必要参数
            let {defaultParams: required} = fourGFlowStore
            const {oemId, userId, iccId, skin} = required
            const productId = this.productId.toString()
            const price = this.currentPrice.toString()//点击时，选择的产品的价格
            const title = '季度无限流量套餐'//硬编码写死
            const period = '90'//硬编码写死
            //创建订单
            const data = await createOrder(oemId, userId, iccId, productId)
            const orderId = data['id']
            // const return_url = 'https://h5_test.eye4.cn/pay?orderId=' + orderId + '&iccId=' + iccId + "&skin=" + skin //跳转支付页面
            // const return_url = 'http://192.168.1.103:8080/pay?orderId=' +
            const return_url = 'https://webApp.eye4.cn/pay?orderId=' +
            orderId + '&iccId=' + iccId + "&skin=" + skin + "&onlyAliPlay=true" //跳转支付页面
            //OKAM
            const params = {customerId:'VEEPAI', userId, iccId, productId, price, title, period, return_url}

            await this.getContractData(params)
        },
        // 展示支付宝拉起支付内容
        async getContractData(arg: agreementType) {
            console.log("arg", arg)
            const data = await agreement(arg)
            const div = document.createElement('div')
            div.id = 'alipayFromBox'
            div.innerHTML = data
            document.body.appendChild(div)
            for (let i = 0; i < document.forms.length; i++) {
                const id = document.forms[i].id
                if (id.indexOf("alipay") !== -1) {
                    document.forms[i].submit()
                }
            }
        },
        async doNow( paymentPlatform: string) {
            let {defaultParams: required} = useFourGFlowStore()
            console.warn("支付平台:", paymentPlatform)
            const {scenes, iccId, oemId, openid, language,userId} = required
            const {productId} = this
            const {id: orderId} = await createOrder(oemId, userId, iccId, productId)
            let payScenes = 'IH5'
            const return_url = setUrlParam('/' + 'succeed', {orderId, iccId, scenes})
            const {transaction_fee, title} = await showOrder(orderId, language)
            //创建支付宝或微信订单 (接口不统一)
            const data = await paymentEyeCloudCreate(oemId, payScenes, paymentPlatform, openid, orderId, transaction_fee, title, return_url)
            eyeCloudPaymentH5(paymentPlatform, return_url, data); //H5支付操作
        },
        async fourGtoPay(paymentPlatform: string){
            // paymentPlatform WX : 微信 ALI: 支付宝
            let {defaultParams: required} = useFourGFlowStore()
            const {selectedCombo, isAutoContract, isPurchased, doNow} = this
            console.log("套餐组", selectedCombo)
            /*
            * 先判断是否为自动续费套餐，并判断是否已经签约过，若已签约则直接支付
            * 否则先进行签约再支付
            * 若不是自动续费套餐则直接进入支付模式，判断支付平台
             */
            if (selectedCombo.group === 'automatic_renewal' && isAutoContract) await isPurchased(required.iccId)
            else if (selectedCombo.group === 'automatic_renewal') this.showAgreement = true // await onSubmit()
            else await doNow(paymentPlatform)
        }
    },
})