<template>
  <div
      :style="{'background-image':'url(' + (cloudStorageStore.status ? require('../../assets/OpenBG.png')
       : require('../../assets/UnOpenBG.png')) + ')'}"
      id="page">
    <div class="container" v-if="!loading">
      <div class="card-box">
        <div :class="cloudStorageStore.status ? 'card-box-top' : 'card-box-top-unOpen' ">
          <van-icon name="video-o" size="50" style="width: 30%"/>
          <aside style="display:flex;flex-direction: column;text-align: left;justify-content: space-around">
            <span style="display: -webkit-box; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis;-webkit-box-orient: vertical;word-break: break-all">{{ translateTitle(cloudStorageStore.deviceName) }}</span>
            <span style="font-size: 12px">{{ cloudStorageStore.did }}</span>
            <span v-if="cloudStorageStore.status" style="font-size: 12px">
              {{ translateTitle('有效日期至') }}：{{ cloudStorageStore.expirationTime }}
            </span>
            <van-tag v-else round class="card-box-tag">
              <span style="font-size: 24px;margin-right: 5px">·</span> {{ translateTitle('未开通') }}
            </van-tag>
          </aside>
        </div>
        <div :class="cloudStorageStore.status ? 'card-box-bottom' : 'card-box-bottom-unOpen' ">
          <div class="card-box-bottom-left">
            <span style="font-size:12px;margin-bottom:5px">{{ translateTitle("存储周期") }}</span>
            <span style="font-size:20px;font-weight: bold">
              {{ cloudStorageStore.key.split("_")[0] }}
              <span style="font-size:12px;font-weight: normal">{{ translateTitle("天") }}</span>
            </span>
          </div>
          <div class="card-box-bottom-right">
            <span style="font-size:12px;margin-bottom:5px;padding:0 3px">{{ translateTitle("距离服务到期") }}</span>
            <span style="font-size:20px;font-weight: bold" v-if="cloudStorageStore.deadline - 0 > 0">
              {{ cloudStorageStore.deadline }}
              <span style="font-size:12px;font-weight: normal">{{ translateTitle("天") }}</span>
            </span>
            <span v-else-if="cloudStorageStore.deadline - 0 <= 0" style="font-size:18px;">{{ translateTitle("已到期") }}</span>
            <span v-else-if="!cloudStorageStore.status">
              {{ translateTitle("未开通") }}
            </span>
          </div>
        </div>
      </div>

      <div class="intruduction-box">

        <div class="p-i-title" @click="showWarnTip=true">
          <span class="title-left-box"></span>
          <span style="font-size:18px;letter-spacing: 1px;max-width: 65%;text-align: center;">
            {{ translateTitle('动态云录像') }}
            <van-icon style="font-size:16px" name="question-o"/>
          </span>
          <span class="title-right-box"></span>
        </div>

        <server-detail/>

      </div>
      <div class="payment-box">
        <div style="text-align: left">
          <span style="font-size:12px">{{ cloudStorageStore.selectedCombo.currencySymbol }} </span>
          <span style="font-size:25px;font-weight:bold">{{ cloudStorageStore.selectedCombo.price }}</span>
          <br>
          <span style="font-size:12px;color:#FFB58E" v-if="cloudStorageStore.selectedCombo.oldPrice">
            {{ translateTitle("已优惠") }}
            {{ cloudStorageStore.selectedCombo.currencySymbol }}
            {{
              parseFloat((cloudStorageStore.selectedCombo.oldPrice - cloudStorageStore.selectedCombo.price).toFixed(2))
            }}
          </span>
        </div>
        <div style="width: 50%">
          <van-button color="linear-gradient(to right, #51FADA, #17BCE0)" style="width: 100%"
                      @click="cloudStorageStore.onSubmit" :disabled="!cloudStorageStore.selectedCombo.id">
            {{ translateTitle("去支付") }}
          </van-button>
        </div>
      </div>
    </div>
<!--    <van-empty v-else description="加载静态资源中，请稍等..." />-->

    <el-dialog v-model="showWarnTip" top="30vh" :show-close="false" width="80%">
      <template #header>
        <div class="showWarnTipTitle">{{ translateTitle("温馨提示") }}</div>
      </template>
      <div>
        <div style="margin-bottom: 20px">
          {{
            translateTitle("购买后，服务立即生效，当检测到有物体或人移动时，将会录制并上传从移动开始到结束的视频到云端保存")
          }}
        </div>
        <van-button color="linear-gradient(to right, #51FADA, #17BCE0)" style="width: 100%"
                    @click="showWarnTip=false">
          {{ translateTitle("知道了") }}
        </van-button>
      </div>
    </el-dialog>
    <el-dialog v-model="showUseReminders" top="20vh" :show-close="false" width="80%">
      <template #header>
        <!--        <img style="width: 100%;border-radius: 15px 15px 0 0"-->
        <!--             :src="'https://payment-vstc.eye4.cn/v2/img/free_zh.jpg'" alt="">-->
        <img style="width: 100%;border-radius: 15px 15px 0 0" :src="'https://payment-vstc.eye4.cn/v2/img/free_'
              + (cloudStorageStore.bridgeInfo.language === 'ita' ? 'en' : cloudStorageStore.bridgeInfo.language) + '.jpg'"
             alt="">
      </template>
      <div>
        <div style="margin-bottom: 20px">
          <h2 style="margin-top: 0">{{ translateTitle("云存储录像邀您试用") }}</h2>
          <p>
            {{ translateTitle("您将拥有为期一个月的免费试用时长，录像存云端不怕丢，视频保留七天，回放简单方便") }}
          </p>
        </div>
        <van-button color="linear-gradient(to right, #51FADA, #17BCE0)" style="width: 100%;word-break: break-word;"
                    @click="cloudStorageStore.openTrial();showUseReminders=false">
          {{ translateTitle("免费试用(录像保存7天)") }}
        </van-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import router from "@/router"
import {useRoute} from "vue-router";
import {ElLoading} from "element-plus";
import {translateTitle} from "@/utils/i18n"
import {isSupport, tryoutStatus} from "@/api/D004";
import {useChoosePlanStore} from "@/store/modules/ChoosePlan";
import {useCloudStorageStore} from "@/store/modules/cloudStorage";
import {defineAsyncComponent, onMounted, reactive, toRefs} from "vue";

export default {
  name: "index",
  components: {
    serverDetail: defineAsyncComponent(() => import('./components/serverDetail')),
    monthlyDescription: defineAsyncComponent(() => import('@/components/monthlyDescription')),
    skeleton: defineAsyncComponent(() => import('@/components/skeleton'))
  },
  setup() {
    const route = useRoute()
    const choosePlanStore = useChoosePlanStore()
    const cloudStorageStore = useCloudStorageStore()
    const {initBridge, getDeviceInfo, initFixedPlan, initIapPlan} = cloudStorageStore

    const {language} = route.query


    const state = reactive({
      active: 1,
      AutomaticRenewalShow: false,
      language: language || 'zh',
      showWarnTip: false,
      showUseReminders: false,
      userId: '',
      cOEMID: '',
      authKey: '',
      comboList: [],
      loading: true,
    })
    // 使用何种购买方式
    const paymentMethod = async (area) => {
      const u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // TODO clientType 判断多个平台
      console.warn("地区与平台", area, isIOS)
      // 非苹果大陆用户使用固定套餐
      if (area === 'cn' && !isIOS) initFixedPlan()
      // 非大陆用户,或苹果用户获取内购套餐
      else initIapPlan(area, isIOS)
      // initIapPlan('frankfurt', true)
    }
    // 判断是否有试用权限
    const deviceIsSupport = async (did) => {
      let {area, isSupport: support} = await isSupport({did})
      cloudStorageStore.area = area

      console.log("设备是否支持", support);

      if(!cloudStorageStore.status){
        const {isTryout} = await tryoutStatus({did})
        state.showUseReminders = !isTryout
      }

      await paymentMethod(area)
    }

    const init = async (did) => {
      choosePlanStore.initData('AI智能服务')
      // 获取设备基础信息
      await getDeviceInfo(did)
      // 是否支持云存储
      await deviceIsSupport(did)

      try {
        state.avatar = await cloudStorageStore.webViewBridge.getDeviceCover(did)
        console.log(state.avatar, "头像")
      } catch (e) {
        console.warn(e)
      }
    }
    // 初始化检测参数，判断是否调试模式
    onMounted(async () => {
      choosePlanStore.$reset()
      cloudStorageStore.$reset()
      const loading = ElLoading.service({
        lock: true,
        // text: translateTitle('请等待') + '...',
        background: 'rgba(255, 255, 255)',
      })

      //VSTA888496WBVVK VSTF106902ZHXRR
      const {did, debug} = route.query
      if (did) choosePlanStore.did = did
      else return alert("did is null")

      if (debug) {
        cloudStorageStore.bridgeInfo = {
          userId: "11252314",
          authkey: "K8a1Mlr8i11RN/R835UGlV9m29uq2ORqssok2KWfC9hycsD7scp519eqhosuX3F8",
          language: "zh",
          OEMID: "OKAM",
          NavColor: "46bcd5"
        }
        await getDeviceInfo(did)
        const {isTryout} = await tryoutStatus({did})
        state.showUseReminders = !isTryout
        await initFixedPlan()
      } else if (await initBridge()) {
        await init(did)
      } else {
        await router.push({path: '/error'})
      }

      state.loading = false
      loading.close()
    })

    return {
      ...toRefs(state),
      translateTitle,
      choosePlanStore,
      cloudStorageStore
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin-bottom: 100px;
}

.card-box {
  width: 80%;
  height: 150px;
  margin: 5% 10% 0 10%;
  border-radius: 15px;
  position: relative;
  z-index: 2;

  &-tag {
    background: #FEF08E;
    width: fit-content;
    padding: 2px 10px;
    color: #916D26;
  }
}

.card-box-top, .card-box-top-unOpen {
  height: 46%;
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  flex-direction: row;
  background: #D7B073;
  color: #694507;
  //color: #905F08;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card-box-top-unOpen {
  background: linear-gradient(to right, #AFB5BE, #BEC3D1);
  color: #fff
}

// TODO 修改颜色
.card-box-bottom, .card-box-bottom-unOpen {
  height: calc(60% - 29px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #694507;
  //color: #dcd6ca;
  background: #C7954A;
}

.card-box-bottom-unOpen {
  background: linear-gradient(to right, #A3A8B4, #B5B9CB);
  color: #fff
}

.card-box-bottom-left, .card-box-bottom-right {
  text-align: center;
  width: 50%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around
}

.card-box-bottom-left::before {
  content: '';
  width: 1px;
  height: 28%;
  position: absolute;
  left: 50%;
  top: 66.5%;
  background: #b07100;
}

.card-box-bottom::before, .card-box-bottom-unOpen::before {
  content: '';
  position: absolute;
  width: 110%;
  height: 100px;
  top: 50px;
  left: -5%;
  background: #967D53;
  z-index: -1;
  border-radius: 15px 15px 0 0;
}

.card-box-bottom-unOpen::before {
  background: #626C81
}

.intruduction-box {
  background: #fff;
  border-radius: 20px 20px 0 0;
  height: calc(100% - 5% - 150px - 22px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intruduction-box ::v-deep .van-tabs__wrap {
  border-radius: 20px;
}

.payment-box {
  width: calc(100% - 40px);
  padding: 20px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0 -2px 6px 0 #7c7c7c1f;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.p-i-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #000000;
}

.title-left-box, .title-right-box {
  margin: 0 10px;
  display: inline-block;
  width: 15%;
  height: 3px;
  background: linear-gradient(to right, rgb(255, 255, 255), #DAD2BD)
}

.title-right-box {
  background: linear-gradient(to left, rgb(255, 255, 255), #DAD2BD)
}

.showWarnTipTitle {
  background: rgb(94, 190, 215);
  width: 100%;
  height: 10vh;
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 22px;
  color: #fff;
  letter-spacing: 1px;
  align-items: center;
}

#page {
  padding-top: 1vh;
  height: 100%;
  background-size: contain;
  background-position-y: -56px;

  &:deep(.van-overlay) {
    position: absolute;
  }

  &:deep(.van-tabs--line .van-tabs__wrap) {
    height: auto;
  }

  &:deep(.el-dialog__header) {
    padding: 0;
    margin: 0;
  }

  &:deep(.el-dialog) {
    border-radius: 15px;
  }

  //&:deep(.van-popup--bottom.van-popup--round) {
  //  position: absolute;
  //}
}
</style>